<template>
    <div>
        <nav-bar>
            <div slot="left" class="back">
                <img src="../../assets/img/back.png" alt="" @click="goBack" />
            </div>
        </nav-bar>
        <div class="token_content">
            <img src="../../assets/img/logo2.png" alt="" class="content_logo"/>
            <div class="content_input display-flex-between">
                <input type="number" placeholder="手机号码" v-model="phone" />
            </div>
            <div class="content_input display-flex-between">
                <input type="text"  placeholder="短信验证码" v-model="code"/>
                <div class="code" @click="getCode">{{codeValue}}</div>
            </div>
            <div class="button_token" :class="isclick?'':'opacity'" @click="token">登录</div>
            <router-link class="tip display-flex-center" to="/cardToken">
                <div style="color: #257CFF;">账号密码登录</div>
            </router-link>
        </div>
        <div class="choose_organ" v-if="show_choose_organ">
          <div class="choose_organ_same" @click="show_choose_organ = false"></div>
          <div class="organ_content">
            <div class="organ_title">
              选择机构
            </div>
            <div class="organ_list" v-for="(item,index) in organ_list" :key="index">
              <div class="organ_info" @click="organtoken(item.company.id)">{{item.company.name}}</div>
            </div>
            <div class="organ_close" @click="show_choose_organ = false">
              <img src="../../assets/img/ic-close@2x.png" alt="" class="organ_close_img">
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import '@/assets/style/token.css'
export default {
  name: 'phone',
  data () {
    return {
      phone: '',
      code: '',
      codeValue: '获取验证码',
      number: 300,
      timer: '',
      organ_list: [],
      show_choose_organ: false
    }
  },
  computed: {
    isclick: {
      set: function () {},
      get: function () {
        return this.phone !== '' && this.code !== ''
      }
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    getTime () {
      if (this.codeValue === '重新获取' || this.codeValue === '获取验证码') {
        this.timer = setInterval(() => {
          this.number--
          this.codeValue = this.number + 's后重新获取'
          if (this.number === 0) {
            clearInterval(this.timer)
            this.codeValue = '重新获取'
          }
        }, 1000)
      }
    },
    getCode () {
      var reg = /^1[3|4|5|7|6|8][0-9]{9}$/
      if (this.phone === '') {
        this.$toast('请输入手机号')
      } else if (!reg.test(this.phone)) {
        this.$toast('请输入正确的手机号')
      } else {
        this.getTime()
        this.getphonecode()
      }
    },
    async getphonecode () {
      const that = this
      const params = {
        event: 'mobilelogin',
        mobile: that.phone
      }
      const reslist = await that.api.phonetoken(params)
      if (reslist.code === 1) {
        this.$toast('发送成功')
      } else {
        that.$toast(reslist.msg)
      }
    },
    async token () {
      const that = this
      if (that.number >= 0) {
        const params = {
          mobile: that.phone,
          captcha: that.code
        }
        const reslist = await that.api.phonecodeorgantoken(params)
        console.log(reslist)
        if (reslist.code === 1) {
          if(reslist.data.userinfo) {
            localStorage.setItem('token', reslist.data.userinfo.token)
            localStorage.setItem('postname', userinfo.pos_name)
            localStorage.setItem('pId', userinfo.pos_id)
            localStorage.setItem('userinfo', JSON.stringify(reslist.data.userinfo))
            localStorage.setItem('indexCurrent', 0)
            that.$router.push('/home')
          } else {
            that.organ_list = reslist.data;
            that.show_choose_organ = true;
          }
        } else {
          that.$toast(reslist.msg)
        }
      } else {
        this.$toast('请输入有效的验证码')
      }
    },
    async organtoken (id) {
      const that = this
      const params = {
        mobile: that.phone,
        captcha: that.code,
        mechanismId: id,
      }
      const reslist = await that.api.phonecodeorgantoken(params)
      if (reslist.code === 1) {
        that.show_choose_organ = false;
        localStorage.setItem('token', reslist.data.userinfo.token)
        const userinfo = JSON.stringify(reslist.data.userinfo)
        localStorage.setItem('postname', userinfo.pos_name)
        localStorage.setItem('pId', userinfo.pos_id)
        localStorage.setItem('userinfo', userinfo)
        localStorage.setItem('indexCurrent', 0)
        that.$router.push('/home')
      } else {
        that.$toast(reslist.msg)
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
