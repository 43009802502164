<template>
    <div class="reportbody">
        <nav-bar class="reportnav">
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back3.png" alt="">
            </div>
            <div slot="center" class="reporttitle">
                学习报告
            </div>
            <div slot="right" class="result"></div>
        </nav-bar>
        <div class="report_detail">
            <div class="report_detail_tou">
                <van-image
                        width="100%"
                        height="100%"
                        fit="cover"
                        :src="user.avatar"
                        :round="true"
                    />
                </div>
            <img src="../../assets/img/report.png" alt="" class="report_detail_bg">
            <div class="report_detail_status">{{user.is_end==0?'未结业':'已结业'}}</div>
            <div class="report_detail_ones">
                <div class="report_detail_one display-flex-between">
                    <span>班级名称</span>
                    <span style="color: black;">{{user.class_name}}</span>
                </div>
                <div class="report_detail_one display-flex-between">
                    <span>培训类型</span>
                    <span style="color: black;">{{user.train_sort_name}}</span>
                </div>
                <div class="report_detail_one display-flex-between">
                    <span>时间</span>
                    <span style="color: black;">{{user.createtime}}</span>
                </div>
                <div class="report_detail_one display-flex-between">
                    <span>培训单位</span>
                    <span style="color: black;">{{user.train_name}}</span>
                </div>
                <div class="report_detail_one display-flex-between">
                    <span>培训学时</span>
                    <span style="color: black;">{{user.class_hours_count}}学时</span>
                </div>
            </div>
        </div>
        <div class="total" v-if="exam.length!=0">考试汇总</div>
        <div class="exams">
            <div v-for="(item,index) in exam" :key="index" class="exam display-flex-between">
                <div class="exam_status" :class="{ exam_error: item.is_pass==0 }">{{item.is_pass==0?'未通过':'已通过'}}</div>
                <div>
                    <div class="exam_title" v-if="!!item.trainexam">{{item.trainexam.name}}</div>
                    <div class="exam_time">{{item.create_time_text}}</div>
                </div>
                <div class="exam_num">{{item.num}}分</div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
  data () {
    return {
      exam: [{}, {}, {}],
      user: ''
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    async getDetail () {
      const res1 = await this.api.report()
      if (res1.code === 1) {
        this.user = res1.data.report.user
        this.exam = res1.data.report.exam_list
      }
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
    .reportbody{
        min-height: 100vh;
        padding-top: 0.8rem;
        background: linear-gradient(180.25deg, rgba(70.12,144.07,255,1) 0%, rgba(37.19,124.31,255,1) 100%);
    }
    .reportnav{
        position: fixed;
        top: 0;
        background: rgba(70.12,144.07,255,1) ;
        z-index: 9;
    }
    .back,.result{
        width: 1rem;
    }
    .reporttitle{
        color: rgba(255,255,255,1);
        font-size: 0.34rem;
        text-align: center;
    }
    .report_detail{
        width: 6.88rem;
        height: 6.86rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        margin: 0.84rem auto 0;
        text-align: center;
        padding:0 0.4rem;
        box-sizing: border-box;
    }
    .report_detail_tou{
        width: 1.28rem;
        height: 1.28rem;
        border-radius: 50%;
        margin:-0.74rem auto 0;
    }
    .report_detail_bg{
        width: 100%;
        height: 2rem;
    }
    .report_detail_status{
        color: rgba(0,231.63,162.14,1);
        font-size: 0.48rem;
        font-weight: 600;
        margin-top: -2rem;
    }
    .report_detail_one{
        width: 100%;
        height: auto;
        padding: 0.16rem 0;
        box-sizing: border-box;
        color: rgba(150,151,153,1);
        font-size: 0.24rem;
    }
    .report_detail_ones{
        width: 100%;
        height: auto;
        padding: 0.16rem 0;
        box-sizing: border-box;
        border-top: 0.01rem solid #eee;
        margin-top: 1.2rem;
    }
    .total{
        color: rgba(255,255,255,1);
        font-size: 0.36rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: center;
        margin: 0.48rem 0;
        position: relative;
    }
    .total:after{
        content: '';
        width: 0.4rem;
        height: 0.01rem;
        background: #fff;
        position: absolute;
        top: 50%;
        right: 30%;
        transform: translate(-50%,-50%);
    }
    .total:before{
        content: '';
        width: 0.4rem;
        height: 0.01rem;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 35%;
        transform: translate(-50%,-50%);
    }
    .exams{
        padding: 0 0 0.6rem;
        box-sizing: border-box;
    }
    .exam{
        width: 6.88rem;
        height: 1.54rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        margin: 0 auto 0.32rem;
        padding: 0.32rem;
        box-sizing: border-box;
        position: relative;
    }
    .exam_status{
        position: absolute;
        top: 0;
        right: 0;
        width: 1.12rem;
        height: 0.4rem;
        border-radius: 0 0.02rem 0 1rem;
        opacity: 1;
        background: rgba(0,231.63,162.14,1);
        color: rgba(255,255,255,1);
        font-size: 0.24rem;
        font-weight: 500;
        text-align: center;
        line-height: 0.4rem;
    }
    .exam_error{
      background: rgb(233, 41, 7) !important;
    }
    .exam_title{
        color: rgba(50,50,51,1);
        font-size: 0.28rem;
        margin-bottom: 0.16rem;
    }
    .exam_time{
        color: rgba(150,151,153,1);
        font-size: 0.24rem;
    }
    .exam_num{
        color: rgba(50,50,51,1);
        font-size: 0.36rem;
        font-weight: 600;
    }
</style>
