<template>
  <div>
    <nav-bar class="detailnav">
      <div slot="left" class="back" @click="goBack">
        <img src="../../assets/img/back.png" alt="" />
      </div>
      <div slot="center" class="classDetail_title">课程详情</div>
      <div slot="right" class="result"></div>
    </nav-bar>
    <div class="classDetail_img">
      <van-image
        width="100%"
        height="100%"
        fit="cover"
        :src="images"
      />
    </div>
    <tabs
      :tabs="tabs"
      @choose="choose"
      class="onclasstabs"
      :active="current"
    ></tabs>
    <swiper-list @slideChange="slideChange" :active="current">
      <div slot="first" class="tabcontent">
        <div>
          <div class="class_detail_title">{{ name }}</div>
          <div class="class_detail_content" v-html="string"></div>
        </div>
      </div>

      <div
        slot="second"
        class="tabcontent"
        v-for="(item, index) in courseList"
        :key="index"
      >
        <div @click="showlistClick(item, index)">
          <img src="../../assets/img/listbg.png" alt="" class="classlist_bg" />
          <div class="classlist_biaoti display-flex">
            <img style="width: 0.48rem !important;"
              src="../../assets/img/video.png"
              alt=""
              class="classlist_img"
            />
            <div class="classlist_title">{{ item.name }}</div>
          </div>
        </div>
        <div v-if="showlist === index">
          <div
            v-for="(itm, ind) in item.lessonclassdetail"
            :key="ind"
            class="classlist_one"
          >
            <router-link :to="{ path: '/video', query: { id: itm.id, train_id: train_id,train_class_id: train_class_id,ifclass:ifclass } }">
              <div class="display-flex-between" style="align-items: flex-start">
                <div style="color: #c8c9cc">1.1</div>
                <div class="display-flex-between" style="flex: 1">
                  <div class="classlist_one_title">{{ itm.name }}</div>
                  <div v-if="userclassstatus">
                    <div class="classlist_one_statue" v-if="Number(itm.is_complete)==1">已完成</div>
                    <div class="classlist_one_statue" v-else>未完成</div>
                  </div>
                  <div style="color: #969799">{{ gettime(itm.video_length) }}</div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </swiper-list>
    <model
      :ifShow="showmodel"
      confirmText="确定"
      cancelText="取消"
      title="当前未在开班时间内，学习将不会记录课时 ，是否继续学习？ "
      @confirmFun="confirmFun3"
      @cancelFun="goBack"
      ></model>
      <model
      :ifShow="showhavepic"
      confirmText="确定"
      cancelText="取消"
      title="您当前还未设置人脸识别的照片，请前去设置"
      @confirmFun="confirmFungo"
      @cancelFun="goBack"
      ></model>
      <model
      :scroll="false"
      :ifphoto="true"
      :ifShow="showyan"
      confirmText="确定"
      cancelText="取消"
      title="身份信息验证"
      content="设置人脸识别照片为本人学习"
      @yanpeoplepic="sureyan"
      @cancelFun="cancelyan"
      ></model>
  </div>
</template>
<script>
import model from '@/components/model'
import tabs from '@/components/tabs.vue'
import swiperList from '@/components/swiper.vue'
import '@/assets/style/list5.css'
export default {
  name: 'onClassDetail',
  components: {
    tabs,
    swiperList,
    model
  },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     deep: true,
  //     handler (newValue, oldValue) {
  //       this.id = newValue.query.id
  //       if (newValue.query.id) {
  //         this.train_id = newValue.query.train_id
  //         this.train_class_id = newValue.query.train_class_id
  //         this.videoDetails()
  //       }
  //     }
  //   }
  // },
  data () {
    return {
      showhavepic: false,
      showyan: false,
      showlist: true, // 控制课节是否展开
      current: 1,
      classlist: [],
      courseList: [], // 课程列表
      tabs: ['课程详情', '课程列表'],
      string: '',
      id: '',
      is_finish: 0,
      images: '',
      name: '',
      train_id: '',
      train_class_id: '',
      showmodel: false,
      ifclass: '',
      userclassstatus: false
    }
  },
  mounted () {
    if (this.$route.query.train_class_id) {
      this.userclassstatus = true
    } else {
      this.userclassstatus = false
    }
    this.id = this.$route.query.id
    this.is_finish = this.$route.query.is_finish
    this.ifclass = this.$route.query.ifclass
    this.train_class_id = this.$route.query.train_class_id
    this.videoDetails()
    this.gettime()
  },
  methods: {
    async getinfo () {
      const result = await this.api.userinfo()
      if (result.code === 1) {
        const info = result.data.info
        if (Number(info.company_check_face) === 0) {
          this.showyan = false
          this.showhavepic = false
        } else if (Number(info.company_check_face) === 1 && Number(info.is_check_face) === 0) {
          this.showhavepic = true
          this.showyan = false
        } else if (Number(info.company_check_face) === 1 && Number(info.is_check_face) === 1) {
          this.showhavepic = false
          this.showyan = true
        }
      }
    },
    confirmFungo () {
      this.$router.push('userinfo')
    },
    gettime (oldtime) {
      const hours = Math.floor(oldtime / 3600)
      oldtime = oldtime % 3600
      const minutes = Math.floor(oldtime / 60)
      oldtime = Math.floor(oldtime % 60)
      return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (oldtime < 10 ? '0' + oldtime : oldtime)
    },
    confirmFun3 () {
      this.showmodel = false
    },
    slideChange (e) {
      this.current = e
    },
    goBack () {
      this.$router.back(-1)
    },
    choose (e) {
      this.current = e
    },

    // 控制课节是否展开
    showlistClick (itm, ind) {
      if (this.showlist === ind) {
        // 当 showlist 等于点击课节的下标
        this.showlist = false
      } else {
        // 不等于时, 赋值当前点击课节的下标, 让它成立
        this.showlist = ind
      }
    },

    // 视频详情
    async videoDetails () {
      const params = {
        id: this.id,
        train_class_id: this.train_class_id
      }
      const reslist = await this.api.videoDetails(params)
      if (reslist.code === 1) {
        const result = reslist.data.detail.class
        result.forEach(item => {
          const classlist = item.lessonclassdetail
          classlist.forEach(one => {

          })
        })
        this.courseList = reslist.data.class_list
        this.name = reslist.data.detail.name
        this.string = reslist.data.detail.content
        this.images = reslist.data.detail.image
        if (Number(reslist.data.detail.is_settle) === 0) {
          this.showmodel = true
        }
        if (!localStorage.getItem('yansuccess') && this.is_finish == 0) {
          this.getinfo()
        }
      } else {
        this.$toast(reslist.msg)
      }
    },
    sureyan (res) {
      console.log(res)
      if (res.code == 1) {
        this.$toast('验证成功')
        this.showyan = false
        localStorage.setItem('yansuccess', true)
      } else {
        this.$toast('验证失败')
        this.goBack()
      }
    },
    cancelyan () {
      this.goBack()
    }
  }
}
</script>
<style scoped lang="scss">
.detailnav {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 8;
}
.back,
.result {
  width: 1rem;
}
.back {
  font-size: 0.48rem;
}
.back img {
  width: 0.48rem !important;
  height: 0.48rem;
}
.classDetail_title {
  text-align: center;
  font-weight: 600;
  font-size: 0.34rem;
}
.classDetail_img {
  width: 100% !important;
  height: 4.2rem;
  margin-top: 0.88rem;
}
.classDetail_img img {
  width: 100% !important;
  height: 4.2rem;
}
.onclasstabs {
  height: 0.88rem;
  padding: 0rem 0 !important;
  /* border: 1px solid red; */
  margin-top: rem;
  box-shadow: 0px 5px 12px 0px rgba(113, 121, 129, 0.2);
}
.tabcontent {
  width: 100%;
  height: auto;
  padding: 0.32rem;
  box-sizing: border-box;
}
.classbg {
  width: 100%;
  height: 100%;
}
.class_tip {
  margin-top: -1.64rem;
  padding: 0.16rem;
  box-sizing: border-box;
  /* border: 0.01rem solid red; */
  height: 1.44rem;
}
.train img {
  width: 0.4rem !important;
  height: 0.4rem;
  margin-right: 0.1rem;
}
.train_title {
  color: rgba(15.85, 190.19, 137.89, 1);
  font-size: 0.28rem;
  font-weight: 500;
}
.train_tip {
  color: rgba(75.49, 208.25, 168.57, 1);
  font-size: 0.24rem;
  font-weight: 400;
  margin: 0.16rem 0 0 0.5rem;
}
.class_detail_title {
  color: rgba(50, 50, 51, 1);
  font-size: 0.34rem;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.52rem;
}
.class_detail_content {
  width: 100%;
  color: rgba(50, 50, 51, 1);
  font-size: 0.28rem;
  font-weight: 400;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.48rem;
  margin-top: 0.32rem;
}
::v-deep img {
      width: 100% !important;
    }
.classlist_one_title {
  color: #000000;
  flex: 1;
  text-align: left;
}
.classlist_one_statue{
  margin-right: 0.16rem;
  color: #000;
}
</style>
