<template>
    <div class="body">
        <nav-bar class="navbar">
            <div slot="left" class="certificateback" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">
                我的证书
            </div>
            <div slot="right" class="certificateresult"></div>
        </nav-bar>
        <div class="certificate">
            <img v-if="detail.is_valid!=1" src="../../assets/img/over.png" alt="" class="overtime">
            <div class="certificate_img">
              <van-image
                  width="100%"
                  height="100%"
                  fit="cover"
                  :src="detail.image_text"
                />
            </div>
            <div class="certificate_detail">
                <div class="certificate_title display-flex">
                    <img src="../../assets/img/quan.png" alt="" class="quan">
                    <div>{{name}}</div>
                </div>
                <div class="display-flex-between certificate_time">
                    <span>有效截止时间</span>
                    <span style="color:rgba(150,151,153,1);">{{detail.end_time}}</span>
                </div>
                <div class="display-flex-between certificate_time">
                    <span>更新时间</span>
                    <span style="color:rgba(150,151,153,1);">{{detail.update_time}}</span>
                </div>
                <div class="display-flex-between certificate_time">
                    <span>初始取证时间</span>
                    <span style="color:rgba(150,151,153,1);">{{detail.start_time}}</span>
                </div>
            </div>
                <div class="footer" @click="again">
                    <div class="footer_button">重新上传</div>
                </div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      id: '',
      detail: '',
      name: ''
    }
  },
  created () {

  },
  mounted () {
    this.id = this.$route.query.id
    this.getcertificateDetail()
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async getcertificateDetail () {
      const params = {
        id: this.id
      }
      const res = await this.api.certificateDetail(params)
      if (res.code === 1) {
        res.data.detail.end_time = res.data.detail.end_time.split(' ')[0]
        res.data.detail.start_time = res.data.detail.start_time.split(' ')[0]
        this.detail = res.data.detail
        this.name = res.data.detail.cert.name
      } else {
        this.$toast(res.msg)
      }
    },
    again () {
      this.$router.push({
        path: '/uploadcertificate',
        query: {
          id: this.detail.id
        }
      })
    }
  }
}
</script>
<style lang="scss" >
    .body{
        height: 100vh;
        overflow: hidden;
        background: #f7f8fa;
    }
    .navbar{
        background: #fff;
    }
    .certificate{
        text-align: center;
        position: relative;
    }
    .certificate_img{
        width: 6.22rem;
        height: 4.16rem;
        margin:0.48rem auto;
    }
    .certificate_detail{
        width: 6.22rem;
        height: auto;
        border-radius: 0.16rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        padding:0 0.32rem 0.16rem;
        box-sizing: border-box;
        margin: 0 auto;
    }
    .certificate_title{
        color: rgba(50,50,51,1);
        font-size: 0.32rem;
        font-weight: 600;
        padding: 0.32rem 0 0.16rem 0;
        box-sizing: border-box;
    }
    .certificate_time{
        color: rgba(50,50,51,1);
        font-size: 0.24rem;
        font-weight: 400;
        padding: 0.1rem 0;
        box-sizing: border-box;
    }
    .quan{
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.16rem;
    }
    .overtime{
      width:1rem;
      height: 1rem;
      position: absolute;
      right: 0.6rem;
      top: 0.6rem;
      z-index: 8;
    }
</style>
