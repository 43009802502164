<template>
  <div class="wrap display-flex-between">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="center">
      <slot name="center"></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav'

}
</script>
<style scoped lang="scss">
.wrap {
  width: 100%;
  height: 0.88rem;
  padding: 0.19rem 0.32rem;
  box-sizing: border-box;
}
.center{flex: 1;}
.left,.right{
  width: 1.6rem;
  height: auto;
}
.right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
