import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import createRouter from './router'
import api from '../src/api'
import navBar from './components/navbar'
import Vant from 'vant'
import ElementUI from 'element-ui'
import VueAMap from 'vue-amap'
import store from '@/store'
// import VideoPlayer from 'vue-videojs7'
import '../src/assets/js/base.js'
import '../src/assets/style/common.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/css/swiper.min.css'
import 'vant/lib/index.css'
import vueWechatTitle from 'vue-wechat-title'

import VideoPlayer from 'vue-video-player'

import { judgeBrand } from '../src/utils/judge'
// import Vconsole from 'vconsole';
// const vConsole = new Vconsole(); 修改一下
// Vue.use(vConsole);

Vue.use(vueWechatTitle)
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.prototype.judgeBrand = judgeBrand
// 这个是为了兼容 m3u8
// const hls = require('videojs-contrib-hls')
// Vue.use(hls)

// Vue.use(VideoPlayer)
Vue.prototype.api = api

Vue.component('navBar', navBar)

Vue.use(ElementUI)
Vue.use(Vant)
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: 'd41b7e2efef339e41a98b211b81db2db',
  plugin: ['AMap.Geolocation', 'AMap.Geocoder', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4'
})
Vue.config.productionTip = false

// const store = creatStore()
const router = createRouter()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
