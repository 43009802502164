<template>
    <div>
        <nav-bar>
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">
                投诉与建议
            </div>
            <div slot="right" class="result"></div>
        </nav-bar>
        <div class="suggest">
            <div class="suggest_title">投诉类型</div>
            <div class="suggest_one">
                <select name="" id="" v-model="type" >
                    <option value="" disabled selected style="color: lightgrey !important;">选择投诉类型</option>
                    <option value="1">功能异常</option>
                    <option value="2">体验问题</option>
                    <option value="3">功能建议</option>
                    <option value="4">服务投诉</option>
                    <option value="5">其他问题</option>
                </select>
            </div>
        </div>
        <div class="suggest">
            <div class="suggest_title">具体问题</div>
            <div class="suggest_one">
                <textarea type="text" v-model="question" maxlength="400" style="resize:none;" placeholder="请填写具体意见"></textarea>
            </div>
        </div>
        <div class="suggest">
            <div class="suggest_title">手机号</div>
            <div class="suggest_one">
                <input type="number" v-model="phone" placeholder="请填写手机号"  class="inputphone">
            </div>
        </div>
        <div class="display-flex radio">
            <div class="radioinput">
                <input type="radio" id="one" value="1" v-model="picked" >
                <label for="one">平台建议</label>
            </div>
            <div class="radioinput">
                <input type="radio" id="two" value="2" v-model="picked">
                <label for="two">企业建议</label>
            </div>
        </div>
        <div class="suggest">
            <div class="suggest_one display-flex-between">
                <div>是否匿名提交</div>
                <van-switch v-model="checked" />
            </div>
        </div>
        <div class="footer" @click="submit">
            <div class="footer_button">提交信息</div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      checked: true,
      picked: '',
      type: '',
      question: '',
      phone: ''
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async submit () {
      const that = this
      if (that.type === '') {
        that.$toast('请选择投诉类型')
      } else if (that.question === '') {
        that.$toast('请填写具体问题')
      } else if (that.picked === '') {
        that.$toast('请选择是平台建议还是企业建议')
      } else {
        const params = {
          platform: 2,
          mobile: that.phone,
          type: that.type,
          sub_type: that.picked,
          des: that.question,
          is_hide: that.checked ? 1 : 0
        }
        const reslist = await that.api.suggest(params)
        if (reslist.code === 1) {
          that.$toast('投诉成功')
          that.$router.go(-1)
        } else {
          that.$toast(reslist.msg)
        }
      }
    }
  }
}
</script>
<style>
    .suggest_title{
        color: rgba(50,50,51,1);
        font-size: 0.36rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: left
    }
    .suggest{
        width: 100%;
        height: auto;
        padding: 0.32rem;
        box-sizing: border-box;
    }
    .suggest_one{
        width: 100%;
        height: auto;
        border-radius: 0.16rem;
        opacity: 1;
        background: rgba(239,242,246,1);
        margin-top: 0.24rem;
        color: rgba(50,50,51,1);
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.44rem;
        padding: 0.26rem 0.32rem;
        box-sizing: border-box;
    }
    .suggest_one .inputphone{
        width: 100%;
        height: 100%;
        text-align: left;
    }
    .suggest_one textarea{
        width: 100%;
        height: 2rem;
        border: none;
        background: none;
    }
    .radio{
        font-size: 0.28rem;
        width: 100%;
        padding: 0 0.32rem;
        box-sizing: border-box;
    }
    .radioinput{
        margin-right: 0.48rem;
        display: flex;
        align-items: center;
    }
    .radioinput input{
        margin-right: 0.2rem;
    }

</style>
