<template>
    <div>
        <nav-bar class="navbar">
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="center">
                模拟考试
            </div>
            <div slot="right" class="picker display-flex-around" >
                <div>{{postname}}</div>
                <!-- <img src="../../assets/img/xia.png" alt="" :class="isShow?'rotate':''"> @click="pos"-->
            </div>
        </nav-bar>
        <!-- <tabs :tabs="tabs" :active="current" @choose="choose" class="exercisetabs"></tabs> -->
        <!-- <fil-ter :show="isShow" @infoType="infoType"></fil-ter> -->
        <!-- <div class="line"></div> -->
        <!-- <swiper-list
        @slideChange="choose"
        :active="current" slot="first"
        > -->
            <div class="Specialexercise">
                  <div class="noresult" v-if="Specialexercises.length==0">暂无模拟考试</div>
                  <div v-else>
                    <van-list
                      @load="scrollEnd">
                      <div v-for="(item,index) in Specialexercises" :key="index" class="Specialexercises_item">
                          <div @click="todetail(item.id,item.is_complete,item.count)">
                              <div class="display-flex-between">
                                  <img src="../../assets/img/wenben 1.png" alt="" class="Specialexercises_img">
                                  <div class="Specialexercises_title">{{item.name}}</div>
                              </div>
                              <div class="display-flex-between">
                                  <div class="Specialexercises_number">{{item.answer_count}}人做过</div>
                                  <div class="Specialexercises_time">{{item.create_time}}</div>
                              </div>
                          </div>
                      </div>
                    </van-list>
                  </div>
            </div>
          <!--   <div slot="second" class="Specialexercise">
              <div class="noresult" v-if="Specialexercises.length==0">暂无模拟考试</div>
              <div v-else>
                <div v-for="(item,index) in Specialexercises" :key="index" class="Specialexercises_item">
                    <div @click="todetail(item.id,item.is_complete)">
                        <div class="display-flex-between">
                            <img src="../../assets/img/wenben 1.png" alt="" class="Specialexercises_img">
                            <div class="Specialexercises_title">{{item.name}}({{item.posnames}})</div>
                        </div>
                        <div class="display-flex-between">
                            <div class="Specialexercises_number">{{item.answer_count}}人做过</div>
                            <div class="Specialexercises_time">{{item.create_time}}</div>
                        </div>
                    </div>
                </div>
              </div>
        </div>
        </swiper-list> -->
    </div>
</template>
<script>
import '@/assets/style/list4.css'
// import filTer from '@/components/filter'
// import tabs from '@/components/tabs.vue'
// import swiperList from '@/components/swiper.vue'
export default {
  // components: {
  //   swiperList
  // },
  data () {
    return {
      // tabs: ['未答试卷', '已做试卷'],
      isShow: false, // 工种弹窗的显示
      Specialexercises: [],
      doSpecialexercises: [{}, {}, {}],
      current: 0,
      postname: '',
      pId: '',
      ifmore: false,
      pagenum: 10,
      page: 1
    }
  },
  mounted () {
    this.getuserinfo();
    this.getlistkao()
    console.log(1111111111111)
  },
  methods: {
    async getuserinfo () {
      const result = await this.api.userinfo()
      if (result.code === 1) {
        this.postname = result.data.info.pos_name
        this.pId = result.data.info.pos_id
      }
    },
    scrollEnd(e) {
      console.log(e);
      if (this.ifmore) {
        ++this.page;
        this.getlistkao();
      }
      // else {
      //   this.$toast({ message: "没有更多了", duration: 1000 });
      // }
    },
    todetail (id, com, count) {
      localStorage.removeItem('alltest')
      localStorage.removeItem('testindex')
      localStorage.removeItem('answerId')
      localStorage.removeItem('timelo')
      console.log(1)
      console.log(com, com === '1')
      if (com === '1') {
        this.$toast('该试题已完成')
      } else {
        if(count === 0) {
          this.$toast('该模拟考试暂无题目')
        } else {
          this.$router.push({
            path: '/examtype', query: { id: id, posid: this.pId }
          })
        }
      }
    },
    async getlistkao () {
      const params = {
        // is_complete:this.current,
        pos_id: this.pId,
        type: 1,
        page: this.page,
        page_num: this.pagenum
      }
      const res = await this.api.monilist(params)
      if (res.code === 1) {
        const result = res.data.list
        result.forEach(item => {
          console.log(item)
          item.posnames = []
          item.pos.forEach(a => {
            item.posnames.push(a.name)
          })
          item.posnames = item.posnames.join(',')
        })
        if (result.length === 0) {
          this.ifmore = false;
          this.Specialexercises = this.Specialexercises.concat(result);
        } else {
          this.ifmore = true;
          this.Specialexercises =
            this.page === 1 ? result : this.Specialexercises.concat(result);
        }
      }
    },
    // 选择工种
    pos () {
      this.isShow = !this.isShow
    },
    infoType (infoType, itemId) {
      console.log(infoType, itemId)
      this.isShow = false
      this.postname = infoType
      this.pId = itemId
      localStorage.setItem('postname', this.postname)
      localStorage.setItem('pId', this.pId)
      this.page = 1
      this.getlistkao()
    },
    choose (e) {
      this.current = e
      this.page = 1
      this.getlistkao()
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="scss">
    .picker{
        justify-content: flex-end;
    }
    .center{
        font-size: 0.34rem;
        font-weight: 700;
        text-align: center;
    }
    .exercisetabs{
        width: 100%;
        height:0.88rem;
        margin-top: 0.8rem;
        padding: 0;
    }
    .grade{
        color: rgba(37.19,124.31,255,1);
        font-size: 0.28rem;
        font-weight: 600;
    }
    .Specialexercise{
      padding:0.32rem;
      margin-top: 1rem;
      box-sizing: border-box;
    }
</style>
