<template>
    <div>
        <nav-bar class="navbar">
            <div slot="left" class="back">
                <div  @click="goBack">
                    <img src="../../assets/img/back.png" alt="" class="backimg"/>
                </div>
            </div>
            <div slot="center" class="display-flex-around navtitle">
                <div @click="choosebig(1)" :class="current==1?'active':''">共享资料库</div>
                <div @click="choosebig(2)" :class="current==2?'active':''">企业资料库</div>
            </div>
            <div slot="right" class="back"></div>
        </nav-bar>
        <div class="catetwo">
          <van-tabs
          title-inactive-color="#646566"
          title-active-color="#257CFF" type="card" @click="gibchoose">
            <van-tab  :title="item.name" v-for="(item, index) in smallcatelist"  :key="index"></van-tab>
          </van-tabs>

        </div>
        <div class="line">
          <div class="show_sort_name" @click="change_show_material_sort()">
            <div class="sort_name">{{sort_name}}</div>
            <div class="icon_show"><img src="../../assets/img/iconly-Bold-Arrow-Right2@2x.png" alt=""></div>
          </div>
            <!-- <select @change="choosemin" v-model="minid" v-if="children.length!=0" style="background: none;color:rgba(37.19,124.31,255,1);min-width: 30%;">
                <option :value="item.id" v-for="(item,index) in children" :key="index">{{item.name}}</option>
            </select> -->
        </div>
        <div ref="show_sort" class="material_data_list datascroll">
          <div class="noresult" v-if="testclass.length == 0">暂无资料库</div>
          <van-list
            v-else
            @load="scrollEnd">
            <div>
                <router-link :to="{path:'/dataDetail', query:{id:item.id}}" class="class display-flex-between" v-for="(item,index) in testclass" :key="index">
                  <div>
                    <van-image
                      width="100%"
                      height="100%"
                      fit="cover"
                      :src="item.image"
                    />
                  </div>
                  <!-- <img :src="item.image" mode="widthFix" > -->
                    <div class="class_detail">
                        <div style="color: #000;" class="p-1">{{ item.name }}</div>
                        <div class="class_info p-2" v-html="item.des"></div>
                    </div>
                </router-link>
            </div>
          </van-list>
        </div>
        <!--选择资料库分类-->
        <div class="material_sort" :style="{'top':material_sort_top+'px'}" v-if="show_material_sort">
          <div class="material_list">
            <div class="material_children" v-for="(item,index) in children" :key="index">
              <div v-if="item.child.length > 0">
                <div class="material_box" @click="change_show_child(index)">
                    <div class="material_name">{{item.name}}</div>
                    <img src="../../assets/img/ic_arrow@2x2.png" alt="" class="icon_show_child">
                </div>
              </div>
              <div v-else>
                <div class="material_box">
                  <div class="material_name " :class="choosed_item_id==item.id?'active2':''" @click.stop="get_datalist(index,0,item.id,0)">{{item.name}}</div>
                </div>
              </div>
              <div v-if="item.show_child && item.child.length > 0">
                <div class="material_children_box" v-for="(item2,index2) in item.child" :key="index2">
                  <div class="material_children_name " :class="choosed_item2_id==item2.id?'active2':''" @click.stop="get_datalist(index,index2,item.id,item2.id)">{{item2.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import '@/assets/style/list3.css'
import '@/assets/style/classlist.css'
export default {
  name: 'data',
  data () {
    return {
      scrollX2: true,
      scrollX: false,
      isShow: false,
      current: 1,
      current2: 0,
      smallcate: true,
      smallcatelist: [],
      cateid: '',
      testclass: [],
      children: [],
      minid: '',
      page: 1, // 视频课程页码
      pageNum: 20, // 视频课程每页条数
      ifmore: false, // 页码数据(第二页是否有数据)
      height: '',
      titleclass: {
        background: 'rgba(242,243,245,1)'
      },
      sort_name: '全部',
      show_material_sort: false,
      choosed_item_id: 0,
      choosed_item2_id: 0,
      material_sort_top: 0
    }
  },
  created () {
    this.height = document.documentElement.clientHeight - 150
    this.getcate()
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    scrollEnd () {
      if (this.ifmore) {
        this.page++
        this.poslist(this.cateid)
      } 
      // else {
      //   this.$toast({ message: '没有更多了', duration: 1000 })
      // }
    },
    choosebig (e) {
      if (e !== this.current) {
        this.page = 1;
        this.current = e
        this.current2 = 0
        this.getcate()
      }
    },
    gibchoose (index) {
      if (index !== this.current2) {
        this.page = 1;
        this.cateid = this.smallcatelist[index].id
        this.children = this.smallcatelist[index].child
        this.children.forEach(element => {
          element.text = element.name
          element.value = element.id
        })
        this.current2 = index
        if (this.children.length !== 0) {
          if (this.children[0].child.length > 0) {
            this.minid = this.children[0].child[0].id
            this.cateid = this.children[0].child[0].id
            this.choosed_item_id = this.children[0].id
            this.choosed_item2_id = this.minid
            this.sort_name = this.children[0].child[0].name
          } else {
            this.minid = this.children[0].id
            this.cateid = this.children[0].id
            this.choosed_item_id = this.minid
            this.choosed_item2_id = 0
            this.sort_name = this.children[0].name
          }
        } else {
          this.minid = ''
          this.sort_name = '全部'
          this.cateid = this.smallcatelist[index].id
        }
        this.poslist(this.cateid)
      }
    },
    choosemin () {
      this.cateid = this.minid
      this.poslist(this.cateid)
    },
    change_show_child (index) {
      console.log(index, '232323232323')
      this.children.forEach((item, indexx) => {
        if (indexx == index) {
          item.show_child = item.show_child !== true
        } else {
          item.show_child = false
        }
      })
      this.$forceUpdate()
    },
    change_show_material_sort () {
      this.show_material_sort = this.sort_name === '全部' ? false : !this.show_material_sort
    },
    get_datalist (index, index2, sort_id, sort2_id) {
      if (sort2_id === 0) { // 无子分类
        this.children.forEach((item, indexx) => {
          item.show_child = false
          if (indexx == index) {
            if(this.choosed_item_id != item.id) { // 点击的是其他分类，从第一页获取数据
              this.page = 1;
            }
            item.show_child = true
            this.choosed_item_id = item.id
            this.choosed_item2_id = 0
            this.sort_name = item.name
          }
        })
      } else { // 有子分类
        this.children.forEach((item, indexx) => {
          item.show_child = false
          if (indexx == index) {
            item.show_child = true
            this.choosed_item_id = item.id
            item.child.forEach((item2, indexxx) => {
              if (index2 == indexxx) {
                if(this.choosed_item2_id != item2.id) { // 点击的是其他分类，从第一页获取数据
                  this.page = 1;
                }
                this.choosed_item2_id = item2.id
                this.sort_name = item2.name
              }
            })
          }
        })
      }
      this.show_material_sort = false
      this.poslist(sort2_id || sort_id)
    },
    async getcate () {
      const params = {
        type: this.current
      }
      const reslist = await this.api.datacate(params)
      if (reslist.code === 1) {
        this.smallcatelist = reslist.data.list
        if (reslist.data.list.length !== 0) {
          console.log(reslist.data.list, 1213)
          this.children = reslist.data.list[0].child
          this.children.forEach((item, index) => {
            item.show_child = false
            if (item.child.length > 0 && index == 0) {
              item.show_child = true
            }
          })

          if (this.children.length === 0) { // 只有一级分类
            this.sort_name = '全部'
            this.choosed_item_id = reslist.data.list[0].id
            this.poslist(reslist.data.list[0].id)
          } else {
            if (this.children[0].child.length === 0) { // 二级分类
              this.cateid = this.children[0].id
              this.sort_name = this.children[0].name
              this.choosed_item_id = reslist.data.list[0].id
              this.choosed_item2_id = this.children[0].id
              this.minid = this.cateid
              this.poslist(this.children[0].id)
            } else { // 三级分类
              this.cateid = this.children[0].child[0].id
              this.sort_name = this.children[0].child[0].name
              this.choosed_item_id = reslist.data.list[0].child[0].id
              this.choosed_item2_id = this.children[0].child[0].id
              this.minid = this.cateid
              this.poslist(this.children[0].child[0].id)
            }
          }
        } else {
          this.testclass = []
          this.children = []
        }
      } else {
        this.$toast(reslist.msg)
      }
    },

    async poslist (id) {
      const params = {
        type: this.current,
        sort_id: id,
        keyword: '',
        page: this.page,
        page_num: this.pageNum
      }
      const reslist = await this.api.datalist(params)
      if (reslist.code === 1) {
        const result = reslist.data.list.data;
        if (result.length === 0 && this.page !== 1) {
          this.ifmore = false
          this.testclass = this.testclass.concat(result)
        } else {
          this.ifmore = true
          this.testclass = this.page === 1 ? result : this.testclass.concat(result)
        }
        // this.testclass = reslist.data.list.data
      } else {
        this.$toast(reslist.msg)
      }
    }
  },
  mounted () {
    if (localStorage.getItem('postname', this.postname)) {
      this.postname = localStorage.getItem('postname', this.postname)
      this.pId = localStorage.getItem('pId', this.pId)
    }
    this.material_sort_top = this.$refs.show_sort.getBoundingClientRect().top
    console.log(this.material_sort_top)
  }
}
</script>
<style scoped lang="scss">
    .navbar{
        background: #fff;
    }
    .back,.picker{
        width: 0.5rem;
    }
    .navtitle{
        color: rgba(150,151,153,1);
        font-size: 16rpx;
        font-weight: 400
    }
    .active{
        color: rgba(36.72,40.06,61.2,1);
        font-size: 18rpx;
        font-weight: 600;
        position: relative;
    }
    .active:after{
        position: absolute;
        content: '';
        width: 20%;
        height: 0.05rem;
        background:#257CFF ;
        border-radius: 0.26rem;
        top: 130%;
        left: 50%;
        transform: translate(-50%,0);
    }
    .catetwo{
      position: fixed;
      top: 0.88rem;
      left: 0;
      width: 100%;
      height: auto;
      z-index: 9;
      padding: 0.16rem 0 0;
      box-sizing: border-box;
      background: #fff;
      margin-bottom: 0.32rem;
    }
    .smallcate{
        width:430%;
        height: 0.8rem;
        display: flex;
    }

    .smallcateone{
        width: auto;
        height: 0.56rem;
        border-radius: 0.08rem;
        opacity: 1;

        margin-right: 0.16rem;
        color: rgba(100,101,102,1);
        font-size: 0.24rem;
        padding:0.1rem 0.2rem;
        box-sizing: border-box;
        text-align: center;
        /* line-height: 0.56rem; */
    }
    /deep/.van-tabs__nav--card{
      border: none;
    }
    /deep/.van-tabs__nav--card .van-tab{
      border-right: none;
      background: rgba(242,243,245,1);
      border-radius: 0.12rem;
      margin-right: 0.21rem;
    }
  /deep/.van-tabs__nav--card .van-tab.van-tab--active{
        border-right: none;
        background-color: rgba(61, 103, 167, 0.1) !important;
        color: rgba(37.19,124.31,255,1) !important;
        border: 0.01rem solid rgba(37.19,124.31,255,1) !important;
    }
    .line{
        width: 100%;
        height: 0.64rem;
        background-color: rgb(248, 248, 248);
        color: rgba(37.19,124.31,255,1);
        font-size: 0.24rem;
        font-weight: 400;
        padding: 0 0.32rem;
        box-sizing: border-box;
        position: fixed;
        top: 1.8rem;
        z-index: 9;
    }
    .line img{
        width: 0.32rem;
        height: 0.32rem;
        margin-left: 0.12rem;
    }
    .show_sort_name {
      display: flex;
      height: 0.64rem;
      align-items: center;
    }
    .icon_show {
      display: flex;
    }

    .list{
        width: 100%;
        height: auto;
    }
    .material_data_list {
      width: 100%;
      padding: 0 .32rem;
      box-sizing: border-box;
      margin-top: 1rem;
    }
    .datascroll{
      position: relative;
      top: 1.7rem;
    }
    .material_sort {
      width: 100%;
      min-height: 100%;
      background: rgba(0,0,0,0.5);
      position: absolute;
    }
    .material_list {
      width: 100%;
    }
    .material_children {
      width: 100%;
      background: #ffffff;
    }
    .material_box {
      line-height: .8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 .4rem;
      border-bottom: .01rem solid #EBEDF0;
    }
    .material_name {
      color: #323233;
      font-size: .28rem;
      font-weight: 400;
    }
    .material_children_name {
      color: #646566;
      font-size: .28rem;
      font-weight: 400;
    }
    .icon_show_child,.icon_show_child_child {
      width: .4rem;
      height: .4rem;
      display: flex;
    }
    .material_children_box {
      line-height: .8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 .4rem;
      padding-left: .4rem;
      border-bottom: .01rem solid #EBEDF0;
    }
    .material_box .active2,.material_children_box .active2 {
      color: #257CFF;
    }
</style>
