<template>
  <div>
      <nav-bar class="navbartest">
          <div slot="left" class="searchs display-flex-between" @click="isShow=!isShow">
              <img src="../../assets/img/iconly-线性描边Light-Search@2x.png" alt="">
              <input  type="text" placeholder="搜索" placholder-class="font"/>
          </div>
          <div slot="right" class="pickertest display-flex-between" >
              <div>{{postname}}</div>
              <!-- <img src="../../assets/img/xia.png" alt="" :class="isShow?'rotate':''"> -->
          </div>
      </nav-bar>
      <!-- <fil-ter :show="isShow" @infoType="infoType"></fil-ter> -->
      <div class="scroll" style="height: auto;">
          <div class="test display-flex-between">
              <div v-for="(item,index) in category" class="test_item" :key="index" @click="switchList(item,index)">
                <img :src="item.img" alt="" class="test_item_img"/>
                <div class="test_item_p">{{item.name}}</div>
              </div>
          </div>
          <router-link to="/rank">
              <div class="rank">
                  <img src="../../assets/img/rank.png" alt="" />
                  <div class="rank_num display-flex-around">
                      <div>{{count}}人参加</div>
                      <img src="../../assets/img/you.png" alt="">
                  </div>
              </div>
          </router-link>
          <div class="practice">
              <div class="practice_title">专项练习</div>
              <div class="practice_items noresult" v-if="practice.length==0">暂无专项练习</div>
              <div class="practice_items" v-else>
                <div v-for="(item,index) in practice" :key="index"  class="practice_item display-flex-between">
                  <img class="practice_item_pic" src="../../assets/img/wenben 1.png" alt=""/>
                  <div style="flex: 1;">{{ item.name }}</div>
                  <div class="practice_item_num display-flex-center" style="margin-right: 10px;" @click="continueExamHandler(item)" v-if="item.status == 1">
                    <p>继续</p>
                    <img src="../../assets/img/Arrow - Right 2.png" alt="" class="practice_item_numpic">
                  </div>
                  <div class="practice_item_num display-flex-center" @click="special(item.id,item.count,item.pos_id)">
                    <p v-if="item.status == 1">重做</p>
                    <p v-else>{{item.count}}题</p>
                    <img src="../../assets/img/Arrow - Right 2.png" alt="" class="practice_item_numpic">
                  </div>
                </div>
              </div>
          </div>
          <div id="next" class="nexter display-flex-center" @click="changeone" >
              <div>换一批</div>
              <img src="../../assets/img/change.png" id="next_pic">
          </div>
      </div>
      <model
          :icon="overicon"
          :ifShow="ifenter"
          ifchallenge="true"
          :content="rule"
          confirmText="进入练习"
          @showClick="showClick"
          @confirmFun="confirmFun"
      ></model>

  </div>
</template>
<script>
import '@/assets/style/list2.css'
// import filTer from '@/components/filter'
import model from '@/components/model'
export default {
  components: {

    model
  },
  data () {
    return {
      overicon: require('@/assets/img/challenge.png'),
      ifenter: false,
      count: '',
      category: [
        {
          img: require('@/assets/img/test1.png'),
          name: '模拟考试',
          url: '/mockexamination'
        },
        {
          img: require('@/assets/img/test2.png'),
          name: '每日一练',
          url: '/examDetail',
          type: 1
        },
        {
          img: require('@/assets/img/test3.png'),
          name: '挑战答题',
          url: '/examDetail',
          type: 2
        }
      ],
      practice: [], // 专项练习列表
      isShow: false, // 工种弹窗的显示
      postname: '',
      pId: '', // 工种id
      type: 3, // 专项练习的类型
      page: 1,
      pageSize: 6,
      nums: 0,
      rule: '',
      ifday: '',
      is_complete: ''
    }
  },
  created () {
  // console.log(1111)
  },
  mounted () {
  // console.log(22222222222222)
    this.getuserinfo()
    this.practiceList()
    this.getrule()
  },
  methods: {
    async getuserinfo () {
      const result = await this.api.userinfo()
      if (result.code === 1) {
        this.postname = result.data.info.pos_name
        this.pId = result.data.info.pos_id
      }
    },
    showClick () {
      this.ifenter = false
    },

    async getrule () {
      const that = this
      const res = await that.api.rule()
      if (res.code === 1) {
        console.log(res)
        that.ifday = res.data.everyday
        var htmlTagReg = /<(\/)?[^>].*?>/g
        that.rule = res.data.challenge.replace(htmlTagReg, '')
        that.count = res.data.count
      }
    },
    changeone () {
      if (this.practice.length < 6) {
        this.page = 1
      } else {
        ++this.page
      }
      this.practice = []
      this.practiceList()
    },
    // 点击-通过type判断跳转
    switchList (item, index) {
      localStorage.removeItem('alltest')
      localStorage.removeItem('testindex')
      localStorage.removeItem('answerId')
      localStorage.removeItem('timelo')
      // if (this.pId === '') {
      //   this.$toast('请选择工种')
      // } else {
      if (item.type === 2) {
        this.ifenter = true
      } else if (item.type === 1) {
        this.$router.push('daylist')
      } else {
        this.$router.push({ path: item.url, query: { id: this.pId, type: item.type } })
      }
    // }
    },

    // 开始答题(挑战答题)
    confirmFun () {
      this.$router.push({ path: this.category[2].url, query: { id: this.pId, type: this.category[2].type } })
    },
    // 选择工种
    pos () {
      this.isShow = !this.isShow
    },
    infoType (infoType, itemId) {
      console.log(infoType, itemId)
      this.isShow = false
      this.postname = infoType
      this.pId = itemId
      this.page = 1
      this.practiceList()
    },

    // 专项练习列表接口
    async practiceList () {
      const params = {
        pos_id: this.pId,
        type: 2,
        page: this.page,
        page_num: this.pageSize
      }
      const reslist = await this.api.monilist(params)
      if (reslist.code === 1) {
        this.practice = reslist.data.list
      } else {
        this.$toast(reslist.msg)
      }
    },

    removeHandler () {
      localStorage.removeItem('alltest')
      localStorage.removeItem('testindex')
      localStorage.removeItem('answerId')
      localStorage.removeItem('timelo')
    },

    // 继续答题
    async continueExamHandler (item) {
      const { count, id, status, pos_id } = item
      if (count === 0) return this.$toast('该题卷暂无题目')
      // isShowAnswer 是否显示正确答案 的唯一标识
      this.$router.push({
        path: '/examDetail',
        query: { id: this.pId, type: this.type, specialId: id,isShowAnswer: 'success', islianxi: 'lianxi', resetTestData: JSON.stringify({ paper_id: id, pos_id }) }
      })
    },

    async special (id, count, pos_id) {
      // console.log(this.type, this.pId, 'idid')
      // if (this.pId !== '') {
      await this.api.getresetItem({ paper_id: id, pos_id:this.pId })
      this.removeHandler()
      if (count === 0) {
        this.$toast('该题卷暂无题目')
      } else {
        this.$router.push({ path: '/examDetail', query: { id: this.pId, type: this.type, specialId: id,isShowAnswer: 'success', islianxi: 'lianxi', resetTestData: JSON.stringify({ paper_id: id, pos_id }) } })
      }
    // } else {
    //   this.$toast('请选择工种')
    // }
    }

  }
}
</script>
<style scoped lang="scss">
  .navbartest .searchs{
      min-width: 5.6rem;
      height: 0.64rem;
      border-radius: 0.08rem;
      opacity: 1;
      background: rgba(255,255,255,1);
      border: 0.02rem solid #257cff;
      padding: 0 0.32rem;
      box-sizing: border-box;
      margin: 0;
  }
  .navbartest .searchs img{
      width: 0.32rem;
      height: 0.32rem;
      margin-right: 0.16rem;
  }
  .navbartest .searchs input{
      border: none;
      color: rgba(37.19,124.31,255,1);
      font-size: 0.28rem;
      flex: 1;
      height: 80%;
      box-sizing: border-box;
      text-align: left;
  }
  .pickertest{
      width: auto;
      color: rgba(50,50,51,1);
      font-size: 0.28rem;
      justify-items: end;
  }
  .pickertest img{
      width: 0.32rem;
      height: 0.32rem;
      margin-left: 0.05rem;
      transition: all 0.2s ease;
  }
  .rotate{
      transform: rotate(180deg);
  }
  .scroll{
      margin-top: 0rem;
  }
  .test{
      width: 100%;
      height: auto;
  }
  .test_item{
      width: 32%;
      color: #fff;
      font-size: 0.28rem;
  }
  .test_item .test_item_img{
     width: 100%;
     height: 2.4rem;
  }
  .test_item_p{
      margin-top: -0.6rem;
      margin-left: 0.65rem;
      font-weight: 400;
      color: #fff;
  }
  .rank{
      width: 100%;
      height: 0.96rem;
      margin: 0.6rem 0 0.0rem;
      position: relative;
  }
  .rank img{
      width: 100%;
      height: 100%;
  }
  .rank_num{
      height: auto;
      position: absolute;
      right: 0.32rem;
      top: 50%;
      transform:translate(0,-50%);
      justify-content: right;
      color: rgba(37.19,124.31,255,1);
      font-size: 0.24rem;
  }
  .rank_num img{
      width: 0.32rem;
      height: 0.32rem;
  }
  .nexter{
      width: 90%;
      height: 0.8rem;
      border-radius: 0.08rem;
      opacity: 1;
      color: rgba(37.19,124.31,255,1);
      font-size: 0.24rem;
      background: rgba(239.31,242.19,246.5,1);
      position: fixed;
      bottom: 1.2rem;
      left: 50%;
      transform: translate(-50%,0);
      z-index: 8;
  }
  .nexter img{
      width: 0.32rem;
      height: 0.32rem;
      margin-left: 0.05rem;
  }
  .rorate{
      width: 0.32rem;
      height: 0.32rem;
      margin-left: 0.05rem;
      animation: rotatepic 0.2s ease-in;
  }
  @keyframes rotatepic {
      0%{
          rotate: 0;
      }100%{
          rotate: 180deg;
      }
  }
</style>
