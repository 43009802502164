import axios from '../src/config/index'

export default class api {
  static cardtoken (params) { // 账号密码登录
    return axios('api/user/login', 'post', params)
  }

  static cardorgantoken (params) { // 机构端账号密码登录
    return axios('api/user/loginOfMechanism', 'post', params)
  }

  static phonetoken (params) { // 获取验证码 登录：mobilelogin 找回密码：resetpwd
    return axios('api/sms/send', 'post', params)
  }

  static phonecodetoken (params) { // 手机验证码登录
    return axios('api/user/mobilelogin', 'post', params)
  }

  static phonecodeorgantoken (params) { // 机构端手机验证码登录
    return axios('api/user/mobileloginOfMechanism', 'post', params)
  }

  static findpassword (params) { // 找回密码
    return axios('api/user/resetpwd', 'post', params)
  }

  static findorganpassword (params) { // 机构端找回密码
    return axios('api/user/resetpwdOfMechanism', 'post', params)
  }

  static sever () { // 客服
    return axios('api/user/contact', 'post')
  }

  static suggest (params) { // 投诉与建议
    return axios('api/user/feedback_m', 'post', params)
  }

  static edituserinfo (params) { // 修改个人信息
    return axios('api/user/profile', 'post', params)
  }

  static edituserphone (params) { // 修改手机号
    return axios('api/user/changemobile', 'post', params)
  }

  static edituserpass (params) { // 修改密码
    return axios('api/user/changepwd', 'post', params)
  }

  static userinfo () { // 获取用户信息
    return axios('api/user/index', 'get')
  }

  static banner () { // 轮播图接口
    return axios('api/index/banner', 'post')
  }

  static notice () { // 公告
    return axios('api/index/notice', 'post')
  }

  static pos () { // 工种列表
    return axios('api/item/pos', 'post')
  }

  static practice (params) { // 专项练习
    return axios('api/item/paper_list', 'post', params)
  }

  // static practiceItem (params) { // 专项练习题目
  //   return axios('api/item/paper_item_list', 'post', params)
  // }

  static rule () { // 规则
    return axios('api/item/item_about', 'post')
  }

  static startAnswer (params) { // 开始答题
    return axios('api/item/start_item', 'post', params)
  }

  static subject (params) { // 专项训练题目列表
    return axios('api/item/item_list', 'post', params)
  }

  static submitTopic (params) { // 提交答题(整套试卷)
    return axios('api/item/item_sub', 'post', params)
  }

  static submitTopicone (params) { // 提交答题(挑战答题单题)
    return axios('api/item/item_sub_single', 'post', params)
  }

  static challengetop (params) { // 交卷(挑战答题)
    return axios('api/item/item_sub_paper', 'post', params)
  }

  static getchallinfo (params) { // 弹板信息(挑战答题)
    return axios('api/item/get_challenge_info', 'post', params)
  }

  static practiceList () { // 推荐资料
    return axios('api/index/material_rec', 'post')
  }

  static textList (params) { // 文本课程列表
    return axios('api/lesson/lesson_word', 'post', params)
  }

  static details (params) { // 文本课程详情
    return axios('api/lesson/lesson_word_detail', 'post', params)
  }

  static videoCourse (params) { // 视频课程列表
    return axios('api/lesson/lesson', 'post', params)
  }

  static videoDetails (params) { // 视频课程详情
    return axios('api/lesson/lesson_detail', 'post', params)
  }

  static videodetail (params) { // 视频详情
    return axios('api/lesson/lesson_class_detail', 'post', params)
  }

  static liveBroadcast (params) { // 直播课程列表
    return axios('api/lesson/lesson_live', 'post', params)
  }

  static lineclassdetail (params) { // 直播课程详情
    return axios('api/lesson/lesson_live_detail', 'post', params)
  }

  static checkplayer (params) { // 直播推流验证
    return axios('api/lesson/check_lesson_live_player_signtoken', 'post', params)
  }

  static searchHistory () { // 历史搜索记录
    return axios('api/common/search_log', 'post')
  }

  static clearSearch () { // 清除历史记录
    return axios('api/common/search_log_clear', 'post')
  }

  static getwrong (params) { // 错题列表
    return axios('api/item/user_error_list', 'post', params)
  }

  static wrongdetail (params) { // 错题详情
    return axios('api/item/user_error_detail', 'post', params)
  }

  static wrongtop (params) { // 错题详情
    return axios('api/item/item_sub_error', 'post', params)
  }

  static delwrong (params) { // 删除错题
    return axios('api/item/item_del_error', 'post', params)
  }

  static getrecord (params) { // 答题记录
    return axios('api/item/user_item_list', 'post', params)
  }

  static getrecorddetail (params) { // 答题记录
    return axios('api/item/user_item_list_detail', 'post', params)
  }

  static certificate (params) { // 我的证件
    return axios('api/user/my_cert', 'post', params)
  }

  static certificateDetail (params) { // 我的证件
    return axios('api/user/my_cert_detail', 'post', params)
  }

  static certificatetype () { // 证件类型
    return axios('api/user/cert_list', 'post')
  }

  static uploadcertificatetype (params) { // 上传证件
    return axios('api/user/my_cert_add', 'post', params)
  }

  static collection (params) { // 我的收藏
    return axios('api/user/my_favorite_lesson_word', 'post', params)
  }

  static train (params) { // 线下培训列表
    return axios('api/lesson/lesson_offline', 'post', params)
  }

  static deletelist (params) { // 删除收藏
    return axios('api/user/favorite', 'post', params)
  }

  static myclass (params) { // 班级任务 我的班级
    return axios('api/train/train_class_list', 'post', params)
  }

  static myorgan (params) { // 我的机构
    return axios('api/user/switchoverOfMechanism', 'post', params)
  }

  static renclass (params) { // 班级任务 课程
    return axios('api/train/train_lesson_list', 'post', params)
  }

  static pracclass (params) { // 班级任务 练习
    return axios('api/train/train_practise_list', 'post', params)
  }

  static monilist (params) { // 专项练习 模拟考试列表
    return axios('api/item/paper_list', 'post', params)
  }

  static kaoclass (params) { // 班级任务 考试
    return axios('api/train/train_exam_list', 'post', params)
  }

  static daylist (params) { // 每日一练列表
    return axios('api/item/test_daily_list', 'post', params)
  }

  static rank (params) { // 排行榜
    return axios('api/item/rank_list', 'post', params)
  }

  static chatlist (params) { // 聊天列表
    return axios('api/teachermessage/msg_list', 'post', params)
  }

  static chatdetail (params) { // 聊天详情
    return axios('api/teachermessage/msg_detail', 'post', params)
  }

  static chatsend (params) { // 聊天回复
    return axios('api/teachermessage/return_msg', 'post', params)
  }

  static getnotice (params) { // 消息列表
    return axios('api/message/msg_list', 'post', params)
  }

  static getnoticedetail (params) { // 消息详情
    return axios('api/message/msg_detail', 'post', params)
  }

  static sign (params) { // 签到
    return axios('api/lesson/lesson_offline_signup', 'post', params)
  }

  static renpractice (params) { // 班级任务练习
    return axios('api/train/start_practise', 'post', params)
  }

  static kaopractice (params) { // 班级任务考试
    return axios('api/train/start_exam', 'post', params)
  }

  static kaoexppractice (params) { // 模拟考试答题
    return axios('api/train/start_exam_exp', 'post', params)
  }

  static feedback (params) { // 学情反馈
    return axios('api/train/train_chat_list', 'post', params)
  }

  static evaluate (params) { // 观看学情反馈
    return axios('api/train/train_chat_read', 'post', params)
  }

  static scorereport (params) { // 积分记录
    return axios('api/user/score_log', 'post', params)
  }

  static report () { // 学习报告
    return axios('api/train/train_report', 'post')
  }

  static monidetail (params) { // 模拟考试详情
    return axios('api/item/paper_list_detail', 'post', params)
  }

  static datacate (params) { // 资料分类
    return axios('api/material/material_sort_list', 'post', params)
  }

  static datalist (params) { // 资料列表
    return axios('api/material/material_list', 'post', params)
  }

  static datadetail (params) { // 资料详情
    return axios('api/material/material_detail', 'post', params)
  }

  static datahome () { // 资料首页
    return axios('api/index/material_rec', 'post')
  }

  static datafaved (params) { // 资料收藏
    return axios('api/user/my_favorite_material', 'post', params)
  }

  static recordvideo (params) { // 班级任务课程记录积分
    return axios('api/lesson/user_class_log', 'post', params)
  }

  static recordvideo2 (params) { // 非班级任务课程记录积分
    return axios('api/lesson/lesson_log_record', 'post', params)
  }

  static unreadCount (params) { // 未读消息数量
    return axios('api/message/msg_unread_count', 'post', params)
  }

  static logout (params) { // 退出登录
    return axios('api/user/logout', 'post', params)
  }

  static unloadfile (params) { // 上传文件
    return axios('api/common/upload', 'post', params)
  }

  static unloadfacefile (params) { // 上传人脸文件
    return axios('api/common/uploadToThumbImage', 'post', params)
  }

  static unloadpic (params) { // 上传人脸
    return axios('api/check_face/createPerson', 'post', params)
  }

  static delpeoplepic (params) { // 删除人脸
    return axios('api/check_face/deletePerson', 'post', params)
  }

  static yanpeoplepic (params) { // 验证人脸
    return axios('api/check_face/checkFace', 'post', params)
  }

  static getresetItem (params) { // 专项练习重置答题 /api/item/reset_item
    return axios('api/item/reset_item', 'post', params)
  }
}
