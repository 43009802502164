<template>
  <div>
    <nav-bar>
      <div slot="left" class="back" @click="goBack">
        <img src="../../assets/img/back.png" alt="" />
      </div>
      <div slot="center" class="userinfotitle">我的收藏</div>
      <div slot="right" class="result"></div>
    </nav-bar>
    <div class="recordtabs">
      <tabs :tabs="tabs" @choose="choose" :active="active"></tabs>
    </div>
    <div class="line"></div>
    <swiper-list @slideChange="choose" :active="active" :ifthird="false">
      <div
        slot="first"
        class="fav_list"
        style="margin-top: 0.2rem"
      >
        <van-list @load="scrollEnd">
          <div>
            <div class="noresult" v-if="classes.length == 0">暂无收藏</div>
            <div v-else>
              <div
                v-for="(item, index) in classes"
                :key="index"
                @click="todatadetail(item.object_id)"
              >
                <van-swipe-cell>
                  <div class="item display-flex-between">
                    <div class="img">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="cover"
                        :src="item.image"
                      />
                    </div>
                    <!-- <img :src="item.image" alt=""> -->
                    <div style="flex: 1">
                      <div class="item_title p-1">{{ item.title }}</div>
                      <div class="item_detail p-2">{{ item.des }}</div>
                    </div>
                  </div>
                  <template #right>
                    <van-button
                      square
                      text="删除"
                      type="danger"
                      class="delete-button"
                      @click="deleteList(item)"
                    />
                  </template>
                </van-swipe-cell>
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <div
        slot="second"
        class="fav_list"
        style="margin-top: 0.2rem"
      >
        <van-list @load="scrollEnd">
          <div>
            <div class="noresult" v-if="data.length == 0">暂无收藏</div>
            <div v-else>
              <div
                v-for="(item, index) in data"
                :key="index"
                @click="todatadetail(item.object_id)"
              >
                <van-swipe-cell>
                  <div class="item display-flex-between">
                    <div class="img">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="cover"
                        :src="item.image"
                      />
                    </div>
                    <div style="flex: 1">
                      <div class="item_title p-1">{{ item.title }}</div>
                      <div class="item_detail p-2" v-html="item.content"></div>
                    </div>
                  </div>
                  <template #right>
                    <van-button
                      square
                      text="删除"
                      type="danger"
                      class="delete-button"
                      @click="deleteList(item)"
                    />
                  </template>
                </van-swipe-cell>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </swiper-list>
  </div>
</template>
<script>
import swiperList from "@/components/swiper.vue";
import tabs from "@/components/tabs.vue";
export default {
  components: {
    swiperList,
    tabs
  },
  data() {
    return {
      tabs: ["课程", "资料"],
      active: 0,
      scrollX: false,
      classes: [],
      page: 1,
      pageNum: 10,
      ifmore: false, // 页码数据(第二页是否有数据)
      data: [],
      height: "",
    };
  },
  created() {
    this.height = document.documentElement.clientHeight - 100;
    this.collection();
  },
  watch: {
    active() {
      if (this.active === 0) {
        this.classes = [];
        this.collection();
      } else {
        this.data = [];
        this.collection2();
      }
    },
  },
  methods: {
    todatadetail(id) {
      if (this.active === 0) {
        this.$router.push({
          path: "/TextClassDetail",
          query: { id: id },
        });
      } else {
        this.$router.push({
          path: "/dataDetail",
          query: { id: id },
        });
      }
    },
    choose(e) {
      this.page = 1;
      this.active = e;
    },
    goBack() {
      this.$router.go(-1);
    },
    scrollEnd() {
      console.log(1);
      if (this.ifmore) {
        ++this.page;
        this.collection();
      }
      // else {
      //   this.$toast({ message: "没有更多了", duration: 1000 });
      // }
    },

    // 我的收藏
    async collection() {
      const params = {
        page: this.page,
        page_num: this.pageNum,
      };
      const reslist = await this.api.collection(params);
      if (reslist.code === 1) {
        const res = reslist.data.list.data;
        if (res.length === 0) {
          this.ifmore = false;
          this.classes = this.classes.concat(res);
        } else {
          this.ifmore = true;
          this.classes = this.page === 1 ? res : this.classes.concat(res);
        }
      } else {
        this.$toast(reslist.msg);
      }
    },
    // 我的收藏
    async collection2() {
      const params = {
        page: this.page,
        page_num: this.pageNum,
      };
      const reslist = await this.api.datafaved(params);
      if (reslist.code === 1) {
        const res = reslist.data.list.data;
        if (res.length === 0) {
          this.ifmore = false;
          this.data = this.data.concat(res);
        } else {
          this.ifmore = true;
          this.data = this.page === 1 ? res : this.data.concat(res);
        }
      } else {
        this.$toast(reslist.msg);
      }
    },

    // 取消收藏
    async deleteList(item) {
      const params = {
        type: item.type,
        favorite_id: item.object_id,
      };
      const reslist = await this.api.deletelist(params);
      if (reslist.code === 1) {
        this.choose(this.active);
      } else {
        this.$toast(reslist.msg);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.item {
  width: 100%;
  height: auto;
  padding: 0.32rem;
  box-sizing: border-box;
  border-bottom: 0.01rem solid #eee;
}
.item .img {
  width: 1.93rem;
  height: 1.44rem;
  opacity: 1;
  margin-right: 0.32rem;
}
.item_title {
  color: rgba(50, 50, 51, 1);
  font-size: 0.28rem;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.44rem;
  margin-bottom: 0.16rem;
}
.item_detail {
  color: rgba(150, 151, 153, 1);
  font-size: 0.24rem;
  font-weight: 400;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.4rem;
}
.delete-button {
  width: 1.04rem;
  height: 100%;
  border-radius: 0.08rem;
  opacity: 1;
  background: rgba(255, 47, 47, 1);
}
.fav_list {
  width: 100%;
  padding: 0 .32rem;
  box-sizing: border-box;
}
</style>
