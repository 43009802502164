<template>
  <div>
    <nav-bar class="noticebar">
      <div slot="left" class="back" @click="goBack">
        <img src="../../assets/img/back.png" alt="" />
      </div>
      <div slot="center" class="userinfotitle">聊天记录</div>
      <div slot="right" class="result"></div>
    </nav-bar>
    <div class="notices">
      <van-list @load="scrollEnd">
        <div style="padding-bottom: 0.52rem; box-sizing: border-box">
          <router-link
            :to="{ path: '/chatrecord', query: { id: item.teacher_id } }"
            v-for="(item, index) in notices"
            :key="index"
            class="notice_items display-flex-between"
          >
            <div style="position: relative">
              <div class="dian" v-if="item.is_read == 0"></div>
              <img src="../../assets/img/notice.png" alt="" />
            </div>
            <div style="flex: 1">
              <div class="notice_title display-flex-between">
                <div>{{ item.teacher.name }}</div>
                <div style="color: rgba(150, 151, 153, 1)">
                  {{ item.update_time }}
                </div>
              </div>
              <div class="notice_detail p-2">
                {{ item.last_content }}
              </div>
            </div>
          </router-link>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scrollX: false,
      notices: [],
      page: 1,
      ifmore: false,
      height: "",
      time: "",
    };
  },
  mounted() {
    this.height = document.documentElement.clientHeight - 20;
    this.getlist();
  },
  methods: {
    scrollEnd() {
      if (this.ifmore) {
        ++this.page;
        this.getlist();
      }
      // else {
      //   this.$toast({ message: "没有更多了", duration: 1000 });
      // }
    },
    goBack() {
      this.$router.go(-1);
    },

    async getlist() {
      const params = {
        page: this.page,
        page_num: 20,
      };
      const res = await this.api.chatlist(params);
      if (res.code === 1) {
        const list = res.data.list.data;
        if (list.length === 0) {
          this.ifmore = false;
          this.notices = this.notices.concat(list);
        } else {
          this.ifmore = true;
          this.notices = this.page === 1 ? list : this.notices.concat(list);
        }
      }
    },
  },
};
</script>
<style lang="scss">
body {
  background: #fff;
}
.noticebar {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 2;
}
.notices {
  width: 100%;
  padding: 0 0.32rem;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-top: 0.88rem;
}
.notice_items {
  width: 100%;
  height: auto;
  padding: 0.16rem 0;
  align-items: flex-start;
  border-bottom: 0.01rem solid #eee;
}
.notice_items img {
  width: 0.88rem;
  height: 0.88rem;
  border-radius: 50%;
  margin-right: 0.32rem;
}
.notice_title {
  color: rgba(50, 50, 51, 1);
  font-size: 0.28rem;
}
.notice_detail {
  color: rgba(50, 50, 51, 1);
  font-size: 0.24rem;
  font-weight: 400;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.44rem;
  margin-top: 0.16rem;
  word-break: break-all;
}
.dian {
  position: absolute;
  width: 0.15rem;
  height: 0.15rem;
  background: red;
  border-radius: 50%;
  top: 0.05rem;
  left: 0.05rem;
}
</style>
