<template>
    <div>
        <nav-bar class="navbar">
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="" class="backimg"/>
            </div>
            <div slot="center" class="navtitle">
                <div>直播列表</div>
            </div>
            <div slot="right" class="picker display-flex-around" @click="poslist" >
                <div>{{infoData?infoData:postname}}</div>
                <img src="../../assets/img/xia.png" alt="" :class="isShow?'rotate':''">
            </div>
        </nav-bar>
        <fil-ter :show="isShow" :posts="posts" @infoType="infoType"></fil-ter>
        <tabs
        :tabs="tabs"
        @choose="choose"
        class="lineclasstabs"
        :active="current"></tabs>
        <div class="line"></div>
        <swiper-list
        class="linelistceont"
        @slideChange="choose"
        :active="current"
        ifthird="true"
        >

        <div slot="first" class="live_list">
          <van-list
            @load="scrollEnd">
              <div class="noresult" v-if="videoclass.length == 0">暂无直播课程</div>
                <div class="list display-flex-between" v-else>
                  <div class="list_item" @click="todetail(item.playerUrl,item.is_finish)" v-for="(item,index) in videoclass" :key="index">
                      <div class="list_item_time blue">直播中</div>
                      <img :src="item.lessonlive.image" mode="widthFix" class="list_item_pic">
                      <div class="list_item_title p-1">{{item.name}}</div>
                      <div class="list_item_user display-flex">
                          <img :src="item.teacherpic" alt="" class="list_item_userpic">
                          <div style="flex: 1;">
                            <span class="p-1">{{item.trainclass.teachers.name}}-{{item.trainclass.name}}</span>
                          </div>
                      </div>
                  </div>
                </div>
          </van-list>
        </div>

        <div slot="second" class="live_list">
          <van-list
            @load="scrollEnd">
                <div class="noresult" v-if="videoclass.length == 0">暂无直播课程</div>
                <div class="list display-flex-between" v-else>
                  <div class="list_item" @click="todetail(item.lessonlive.id,item.is_finish)" v-for="(item,index) in videoclass" :key="index">
                      <div class="list_item_time orange">回放</div>
                      <img :src="item.lessonlive.image" mode="widthFix" class="list_item_pic">
                      <div class="list_item_title p-1">{{item.name}}</div>
                      <div class="list_item_user display-flex">
                        <img :src="item.teacherpic" alt="" class="list_item_userpic">
                        <div style="flex: 1;">
                          <span class="p-1">{{item.trainclass.teachers.name}}-{{item.trainclass.name}}</span>
                        </div>
                    </div>
                  </div>
                </div>
          </van-list>
        </div>

        <div slot="third" class="live_list">
          <van-list
            @load="scrollEnd">
              <div class="noresult" v-if="videoclass.length == 0">暂无直播课程</div>
                <div class="list display-flex-between" v-else>
                  <div class="list_item" @click="todetail(item.id,item.is_finish)" v-for="(item,index) in videoclass" :key="index">
                      <div class="list_item_time grey">即将开播</div>
                      <img :src="item.lessonlive.image" mode="widthFix" class="list_item_pic">
                      <div class="list_item_title p-1">{{item.name}}</div>
                      <div class="list_item_user display-flex">
                        <img :src="item.teacherpic" alt="" class="list_item_userpic">
                        <div style="flex: 1;">
                          <span class="p-1">{{item.trainclass.teachers.name}}-{{item.trainclass.name}}</span>
                        </div>
                    </div>
                  </div>
                </div>
          </van-list>
        </div>
        </swiper-list>

        <model
        :ifShow="ifshowmodel"
        title="参与打卡后才能记录学习哦"
        content="观看直播后，需要确认返回，才会记录学时哦"
        cancelText="取消"
        confirmText="确定"
        @cancelFun="ifshowmodel=false"
        ></model>
        <model
        :ifShow="ifshowmodel2"
        title="请上传本人头像"
        cancelText="取消"
        confirmText="拍摄"
        @cancelFun="ifshowmodel2=false"
        @confirmFun="confirmFun"
        ></model>
        <model
      :scroll="false"
      :ifphoto="true"
      :ifShow="showyan"
      confirmText="确定"
      cancelText="取消"
      title="身份信息验证"
      content="设置人脸识别照片为本人学习"
      @yanpeoplepic="sureyan"
      @cancelFun="cancelyan"
      ></model>
      <model
      :ifShow="showhavepic"
      confirmText="确定"
      cancelText="取消"
      title="您当前还未设置人脸识别的照片，请前去设置"
      @confirmFun="confirmFungo"
      @cancelFun="goBack"
      ></model>
    </div>
</template>
<script>
import filTer from '@/components/filter.vue'
import tabs from '@/components/tabs.vue'
import model from '@/components/model.vue'
import swiperList from '@/components/swiper.vue'
import config from '@/assets/js/config.js'
export default {
  name: 'lineClass',
  components: {
    filTer,
    tabs,
    model,
    swiperList
  },
  data () {
    return {
      showhavepic: false,
      showyan: false,
      height: '',
      scrollX: false,
      pulldown: false,
      ifshowmodel2: false,
      ifshowmodel: false,
      isShow: false,
      posts: [],
      videoclass: [],
      tabs: ['正在直播', '回放直播', '即将开播'],
      current: 0,
      page: 1,
      pageNum: 20,
      infoData: '',
      pId: '',
      postname: '',
      type: 1,
      ifmore: false, // 页码数据(第二页是否有数据)
      playerurl: config.playerurl,
      playerulr: '',
      userinfo: ''
    }
  },
  watch: {
    current () {
      this.type = this.current + 1
      this.page = 1
      console.log(this.type, this.current, '11111111')
      this.videoclass = []
      if (this.type === 1) {
        this.liveBroadcast()
      } else if (this.type === 2) {
        this.liveBroadcast()
      } else if (this.type === 3) {
        this.liveBroadcast()
      }
    }
  },
  created () {
    this.pId = this.$route.query.id
  },
  methods: {
    confirmFungo () {
      this.$router.push('userinfo')
    },
    sureyan (res) {
      if (res.code == 1) {
        this.$toast('验证成功')
        this.showyan = false
        this.todetails()
      } else {
        this.$toast('验证失败')
        this.showyan = false
      }
    },
    cancelyan () {
      this.showyan = false
    },
    goBack () {
      this.$router.go(-1)
    },
    async goon (is_finish) {
      const result = await this.api.userinfo()
      if (result.code === 1) {
        const info = result.data.info
        if (Number(info.company_check_face) == 0 || is_finish == 1) {
          this.showyan = false
          this.showhavepic = false
          this.todetails()
        } else if (Number(info.company_check_face) == 1 && Number(info.is_check_face) == 0) {
          this.showhavepic = true
          this.showyan = false
        } else if (Number(info.company_check_face) == 1 && Number(info.is_check_face) == 1) {
          this.showhavepic = false
          this.showyan = true
        }
      }
    },
    todetails () {
      if (this.current == 1) {
        this.$router.push({
          path: 'card',
          query: {
            id: this.playerulr
          }
        })
      } else {
        this.checkplayer(this.playerulr)
      }
    },
    todetail (playerUrl,is_finish) {
      this.playerulr = playerUrl
      if (this.current == 2) {
        this.$toast('教师未开播')
      } else {
        this.goon(is_finish)
      }
    },
    async checkplayer (info) {
      const infoarr = ''.split.call(info, '?')
      console.log(infoarr[0], infoarr[1])
      window.open(infoarr[0] + '?' + encodeURIComponent(infoarr[1]), '_self')
    },
    confirmFun () {
      this.$router.push('/card')
    },
    choose (e) {
      console.log(e, '222222')
      this.current = e
      this.page = 1
      this.videoclass = []
    },

    scrollEnd () {
      if (this.ifmore) {
        this.page++
        this.liveBroadcast()
      }
      // else {
      //   this.$toast({ message: '没有更多了', duration: 1000 })
      // }
    },

    infoType (infoType, itemId) {
      this.isShow = false
      this.infoData = infoType
      this.pId = itemId
      this.page = 1
      this.videoclass = []
      this.liveBroadcast()
    },

    // 直播课程
    async liveBroadcast () {
      const params = {
        type: this.type,
        page: this.page,
        page_num: this.pageNum
      }
      const reslist = await this.api.liveBroadcast(params)
      if (reslist.code === 1) {
        const res = reslist.data.list.data
        if (res.length === 0) {
          this.ifmore = false
          this.videoclass = this.page === 1 ? res : this.videoclass.concat(res) // 合并数组
        } else {
          this.ifmore = true
          this.videoclass = this.page === 1 ? res : this.videoclass.concat(res)
        }
      } else {
        this.$toast(reslist.msg)
      }
    },

    // 工种列表接口
    async poslist () {
      this.isShow = !this.isShow
    }
  },
  mounted () {
    this.height = document.documentElement.clientHeight - 110
    if (localStorage.getItem('postname', this.postname)) {
      this.postname = localStorage.getItem('postname', this.postname)
      this.pId = localStorage.getItem('pId', this.pId)
    }
    this.userinfo = localStorage.getItem('userinfo', this.userinfo)
    console.log(this.userinfo)
    this.liveBroadcast()
  }
}
</script>
<style scoped lang="scss">
    .back,.picker{
        width: 1.5rem;
    }
    .navbar{
        background-color: #fff;
    }
   .lineclasstabs{
       width: 100%;
       /* height: 0.88rem; */
       top: 0.88rem;
       box-sizing: border-box;
       position: fixed;
       z-index: 8;
   }
   .choose{
     position: fixed;
     top: 1rem;
   }
   .scroll{
     padding: 0.1rem 0.32rem 0.5rem !important;
     box-sizing: border-box;
     /* border: 0.01rem solid red; */
     margin-top: 0 !important;
   }
    .live_list {
      padding: 0.1rem 0.32rem 0.5rem !important;
      width: 100%;
      box-sizing: border-box;
    }
    .list{
      flex-wrap: wrap;
      box-sizing: border-box;
    }
    .blue{
       background: linear-gradient(180.25deg, rgba(37,202.68,255,1) 0%, rgba(67.52,37,255,1) 100%);
    }
    .orange{
        background: linear-gradient(180.25deg, rgba(255,193.96,37,1) 0%, rgba(255,102.4,37,1) 100%);
    }
    .grey{
        background: rgba(0,0,0,0.5);
    }
    .linelistceont{
      width: 100%;
      position: relative;
      top: 1.88rem;
      left: 0;
    }
</style>
