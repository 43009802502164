<template>
    <div>
        <div class="bg"></div>
        <nav-bar class="homenavbar" >
            <div slot="left" >
                <img src="../../assets/img/logo1.png" class="logo" alt=""/>
            </div>
            <div slot="center" class="search">
              <router-link to="/result" >
                  <div class="search_title">搜索</div>
                  <div class="search_img">
                      <img src="../../assets/img/iconly-线性描边Light-Search@2x.png" alt="">
                  </div>
              </router-link>
            </div>
        </nav-bar>
        <div class="scroll" :style="{height:+height+'px'}">
          <Scroll
          :scrollX="scrollX"
          style="height: 100%;"
          ref="scroll"
          :probeType="3"
          :pullup="true">
          <div style="height:auto;padding-bottom: 0.5rem;">
            <!-- 轮播图 -->
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" height="180">
            <van-swipe-item v-for="(item, index) in list" @click="tourl(item.href)"
            :key="index">
              <van-image
                width="100%"
                height="100%"
                fit="cover"
                :src="item.image"
              />
            </van-swipe-item>
          </van-swipe>
            <!-- 公告 -->
            <div class="notice display-flex-between">
                <img src="../../assets/img/Group 1593@2x.png" alt="" class="notice_img"/>
                <div class="swiper-container swiper-container2">
                    <div class="swiper-wrapper swiper-wrapper2">
                      <div
                        @click="opennotice(item)"
                        class="swiper-slide swiper-slide2 display-flex-between notice_swiper_item"
                        v-for="(item, index) in noticeList"
                        :key="index"
                      >
                        <p class="p-1 n_s_i_c">{{ item.title }}</p>
                        <img src="../../assets/img/ic_arrow@2x.png" alt="" class="you"/>
                   </div>
                    </div>
                </div>
            </div>
            <!-- 课程 -->
            <div  class="display-flex-around category">
              <div @click="todetail(item,index)"  v-for="(item,index) in category" :key="index" class="category_item">
                  <img :src="item.img" alt="" class="category_img">
                  <p class="category_title">{{item.title}}</p>
              </div>
            </div>
            <div class="videoclass">
              <div class="videoclass_title" style="text-align: left;">视频课程</div>
              <div class="noresult" v-if="videoclass.length == 0">暂无推荐视频课程</div>
              <div class="list display-flex-between" v-else>
                <div class="list_item" v-for="(item,index) in videoclass" :key="index">
                  <router-link :to="{path:'/onClassDetail', query:{id:item.id}}">
                    <div class="list_item_time">{{item.class_hours}} 课时</div>
                    <div class="list_item_pic">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="cover"
                        :src="item.image"
                      />
                    </div>
                    <div class="list_item_title p-1">{{item.name}}</div>
                    <div class="list_item_user display-flex" v-if="!!item.teacher">
                      <img :src="item.teacher.avatar" alt="" class="list_item_userpic">
                      <span>{{item.teacher.name}}</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="display-flex more" @click="tomoreclass">
              <div>查看更多视频课程</div>
              <img src="../../assets/img/iconly-线性描边Light-Arrow - Right Circle@2x.png" alt="">
            </div>
            <div class="practice">
              <div class="practice_pos display-flex-between">
                <div class="practice_title">推荐资料</div>
              </div>
              <div class="noresult" v-if="testclass.length == 0">暂无资料</div>
              <div class="practice_items" v-else>
                <router-link :to="{path:'/dataDetail', query:{id:item.id}}" class="class display-flex-between" v-for="(item,index) in testclass" :key="index">
                  <div>
                    <van-image
                        width="100%"
                        height="100%"
                        fit="cover"
                        :src="item.image"
                      />
                  </div>
                  <div class="class_detail">
                      <div style="color: #000;" class="p-1">{{ item.name }}</div>
                      <div class="class_info p-2" v-html="item.des"></div>
                  </div>
              </router-link>
              </div>
            </div>
            <div class="display-flex more" @click="tomoredata">
              <div>查看更多资料</div>
              <img src="../../assets/img/iconly-线性描边Light-Arrow - Right Circle@2x.png" alt="">
            </div>
          </div>
        </Scroll>
        </div>
        <fil-ter :show="isShow"  @infoType="infoType"></fil-ter>
        <model
         :scroll="true"
          :ifShow="shownotice"
          confirmText="确定"
          :title="title"
          :content="content"
          @confirmFun="shownotice=false"
          ></model>
          <model
         :scroll="false"
          :ifShow="showyan"
          confirmText="确定"
          cancelText="取消"
          title="身份信息验证"
          content="设置人脸识别照片为本人学习"
          @confirmFun="sureyan"
          @cancelFun="showyan=false"
          ></model>
    </div>
</template>
<script>
import filTer from '@/components/filter'
import Scroll from '@/components/scroll.vue'
import model from '@/components/model'
import Swiper from 'swiper'
import '@/assets/style/home.css'
import '@/assets/style/list1.css'
import '@/assets/style/list2.css'

let swiperx
export default {
  components: {
    Scroll, filTer, model
  },
  data () {
    return {
      showyan: false,
      height: '',
      title: '',
      content: '',
      shownotice: false,
      infoData: '',
      isShow: false,
      scrollX: false,
      pulldown: false,
      postname: '请选择',
      pId: '', // 工种id
      category: [
        {
          img: require('../../assets/img/Frame@2x(3).png'),
          title: '在线课程',
          path: '/onClass'
        },
        {
          img: require('../../assets/img/Frame@2x(1).png'),
          title: '直播课程',
          path: '/lineClass'
        },
        {
          img: require('../../assets/img/Frame@2x.png'),
          title: '文本课程',
          path: '/textClass'
        },
        // {
        //   img: require('../../assets/img/Frame@2x(2).png'),
        //   title: '资料库',
        //   path: '/data'
        // }
      ],
      videoclass: [], // 视频课程
      pageNum: 10, // 视频课程每页条数
      testclass: [], // 专项练习
      practicePage: 1, // 专项练习的页码
      list: [],
      noticeList: []
    }
  },

  methods: {
    tourl (href) {
      window.open(href)
    },
    todetail (item, index) {
      // if(index==1){
      //   this.$toast('该功能在调试，敬请期待')
      // }else{
      this.$router.push({ path: item.path })
      // }
    },
    opennotice (item) {
      this.content = item.des
      this.shownotice = true
      this.title = item.title
    },
    tomoreclass () {
      this.$router.push('/onClass')
    },
    tomoredata () {
      this.$router.push('/data')
    },
    pos () {
      this.isShow = !this.isShow
    },
    infoType (infoType, itemId) {
      this.isShow = false
      this.postname = infoType
      this.pId = itemId
      this.practicePage = 1
      this.practice = []
      this.practiceList()
    },
    async notice () {
      /* eslint-disable no-new */
      const that = this
      const reslist = await that.api.notice()
      if (reslist.code === 1) {
        this.noticeList = reslist.data.list
        // swiperx && swiperx.destory()
        this.$nextTick(() => {
          swiperx = new Swiper('.swiper-container2', {
            direction: 'vertical',
            loop: false,
            autoplay: {
              delay: 3000
              // disableOnInteraction: false
            }
          })
        })
      } else {
        this.$toast(reslist.msg)
      }
    },
    sureyan () {
      this.$router.push('userinfo')
    },
    async getSwiper () {
      /* eslint-disable no-new */
      const that = this
      const reslist = await that.api.banner()
      if (reslist.code === 1) {
        this.list = reslist.data.list
        this.$nextTick(() => {
          new Swiper('.swiper-container1', {
            pagination: {
              el: '.swiper-pagination'
            },
            loop: true,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false
            },
            tag: {
              click: function (swiper, event) {
                console.log('123')
              }
            }
          })
        })
      } else {
        that.$toast(reslist.msg)
      }
    },
    async special (id) {
      if (this.pId !== '') {
        this.$router.push({ path: '/examDetail', query: { id: this.pId, type: 3, specialId: id } })
      } else {
        this.$toast('请选择工种')
      }
    },

    // 资料
    async practiceList () {
      const reslist = await this.api.datahome()
      if (reslist.code === 1) {
        this.testclass = reslist.data.list
      } else {
        this.$toast(reslist.msg)
      }
    },
    // 视频课程
    async videoCourse () {
      const params = {
        is_rec: 1,
        page: 1,
        page_num: 4
      }
      const reslist = await this.api.videoCourse(params)
      if (reslist.code === 1) {
        this.videoclass = reslist.data.list.data
      } else {
        this.$toast(reslist.msg)
      }
    }

  },
  mounted () {
    this.height = document.documentElement.clientHeight
    console.log(this.$router)
    const userinfo = localStorage.getItem('userinfo')
    if (this.$router.history._startLocation == '/cardToken') {
      if (Number(userinfo.company_check_face) === 1 && Number(userinfo.is_check_face) === 0) {
        this.showyan = true
      }
    }
    this.getSwiper()
    this.notice()
    this.postname = localStorage.getItem('postname')
    this.pId = localStorage.getItem('pId')
    localStorage.removeItem('yansuccess')
    this.practiceList()
    this.videoCourse()
  }
}
</script>
<style scoped lang="scss">
 .practice_pos{
    width: 100%;
    height: auto;
    /* border: 1px solid red; */
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
    .pickertest{
        width: 2rem;
        margin-left: 0.2rem;
        color: rgba(50,50,51,1);
        font-size: 0.28rem;
        justify-items: end;
    }
    .pickertest img{
        width: 0.32rem;
        height: 0.32rem;
        margin-left: 0.05rem;
        transition: all 0.2s ease;
    }
    .rotate{
        transform: rotate(180deg);
    }
</style>
