import { render, staticRenderFns } from "./onClassDetail.vue?vue&type=template&id=eb01e22a&scoped=true&"
import script from "./onClassDetail.vue?vue&type=script&lang=js&"
export * from "./onClassDetail.vue?vue&type=script&lang=js&"
import style0 from "./onClassDetail.vue?vue&type=style&index=0&id=eb01e22a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb01e22a",
  null
  
)

export default component.exports