<template>
    <div class="body">
        <nav-bar class="chatnav">
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">

            </div>
            <div slot="right" class="result"></div>
        </nav-bar>
        <van-pull-refresh class="noticecont" v-model="isLoading" @refresh="onRefresh">
          <div style="overflow: scroll;padding-bottom: 2rem;" ref="wrapper">
              <div v-for="(item,index) in detail" :key="index">
                  <div class="time" v-if="item.is_show_time==1">{{item.create_time}}</div>
                  <div>
                      <div class="noticeone display-flex" v-if="item.from_id_type=='1'">
                          <img :src="item.avatar" alt="" class="noticeimg">
                          <div class="noticetexts">{{item.content}}</div>
                      </div>
                      <div class="noticeone2 display-flex" v-if="item.from_id_type=='2'">
                          <div class="noticetexts">{{item.content}}</div>
                          <img :src="item.avatar" alt="" class="noticeimg">
                      </div>
                  </div>
              </div>
          </div>
          <!-- <p>刷新次数: {{ count }}</p> -->
        </van-pull-refresh>
        <div class="sendnotice display-flex-between">
            <input type="text" v-model="searchvalue" placeholder="输入新消息">
            <div class="send" @click="send">发送</div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      id: '',
      detail: [],
      searchvalue: '',
      time: '',
      isLoading: false,
      page: 1,
      pagenum: 10,
      ifmore: ''
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getchatdetail()
    this.scrollToBottom()
    this.time = new Date().getTime() - 5 * 60 * 1000
  },
  methods: {
    scrollToBottom () {
      var heightCss = window.getComputedStyle(this.$refs.wrapper).height
      console.log(heightCss)
    },
    goBack () {
      this.$router.go(-1)
    },
    strfun (str) {
      const newstr = str.replaceAll('-', '/')
      return newstr
    },
    onRefresh () {
      if (this.ifmore) {
        this.isLoading = true
        this.page++
        this.getchatdetail()
      } else {
        this.$toast('没有更多了')
      }
    },
    async getchatdetail () {
      const params = {
        teacher_id: this.id,
        page: this.page,
        page_num: this.pagenum
      }
      const res = await this.api.chatdetail(params)
      if (res.code = 1) {
        const list = res.data.detail.data
        if (list.length > 0) {
          this.ifmore = true
          this.detail = this.page == 1 ? list.reverse() : (this.detail.reverse().concat(list)).reverse()
          this.isLoading = false
        } else {
          this.ifmore = false
          setTimeout(() => {
            this.isLoading = false
          }, 1000)
          this.detail = this.page == 1 ? list.reverse() : (this.detail.reverse().concat(list)).reverse()
        }
        console.log(list, this.detail)
      }
    },
    async send () {
      if (!this.searchvalue.length || this.searchvalue === '' || this.searchvalue.length === 0) return
      const params = {
        teacher_id: this.id,
        content: this.searchvalue
      }
      const res = await this.api.chatsend(params)
      if (res.code = 1) {
        this.searchvalue = ''
        this.page = 1
        this.getchatdetail()
      }
    }
  }
}
</script>
<style lang="scss">
    .body{
        height: 100vh;
        background-color: #f7f8fa;
    }
    .chatnav{
        background-color: #fff;
    }
    .noticecont{
        width: 100%;
        height: 100%;
        background: #f7f8fa;
        padding: 0.32rem 0.32rem 2rem;
        box-sizing: border-box;
        overflow: scroll;
    }
    .noticeimg{
        width:0.72rem;
        height: 0.72rem;
        border-radius: 50%;
        margin-right: 0.16rem;
    }
    .noticeone{
        align-items: flex-start;
        margin-bottom: 0.32rem;
    }
    .noticeone .noticetexts{
        text-align: left;
    }
    .noticeone2 .noticetexts{
        text-align:right;
    }
    .noticeone2{
        align-items: flex-start;
        margin-bottom: 0.32rem;
        justify-content: flex-end;
    }
    .noticeone2 img{
        margin-left: 0.32rem;
    }
    .noticetexts{
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        font-size: 0.3rem;
        font-weight: 400;
        font-family: "PingFang SC";
        line-height: 0.44rem;
        padding: 0.24rem;
        box-sizing: border-box;
    }
    .time{
        margin: 0.3rem auto;
    }
    .sendnotice{
        width: 100%;
        height: auto;
        background-color: #ffffff;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 0.08rem 0.32rem 0.5rem;
        box-sizing: border-box;
    }
    .sendnotice input{
        width: 5.3rem;
        height: 0.72rem;
        border-radius: 0.08rem;
        opacity: 1;
        border: 0.01rem solid #eee;
        padding:0 0.32rem ;
        box-sizing: border-box;
        line-height: 0.72rem;
        text-align: left;
        font-size: 0.28rem;
    }
    .send{
        width: 1.2rem;
        height: 0.72rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(37.19,124.31,255,1);
        color: rgba(255,255,255,1);
        font-size: 0.28rem;
        font-weight: 400;
        line-height: 0.72rem;
        text-align: center;
        margin-left: 0.32rem;
    }
    .van-pull-refresh {
      overflow: scroll !important;
      padding-bottom: 1.8rem;
    }
</style>
