<template>
    <div>
        <nav-bar class="fecknav">
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">
                学情反馈
            </div>
            <div slot="right" class="result"></div>
        </nav-bar>
        <div class="feckcontent">
          <van-list
            @load="scrollEnd">
            <div >
            <div v-for="(item,index) in feed" :key="index" class="feed_item display-flex-between">
                <div class="img"><van-image
                  width="100%"
                  height="100%"
                  fit="cover"
                  :src="item.image"
                /></div>
                <div style="flex: 1;">
                    <div class="feed_title p-1">{{item.name}}</div>
                    <div class="feed_time">已完成{{item.class_hours}}课时</div>
                    <div class="btns">
                      <div class="feed_but" @click="tochat(item.teacher_id)" >
                        去聊天
                      </div>
                      <div class="feed_but marginleft" @click="evaluateClick(item.content)" >
                          <!-- <div class='red' v-if="item.is_read==='0'&&item.content!==''"></div> -->
                          讲师评析
                      </div>
                    </div>
                </div>
            </div>
            </div>
            </van-list>
        </div>
        <div class="feedmodel" v-if="ifmodel">
            <div class="feedmodel_close" @click="ifmodel=false"></div>
            <div class="feedmodel_cont">
                <img src="../../assets/img/feedtitle.png" alt="">
                <div class="feedmodel_detail" v-html="content"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      feed: [],
      ifmodel: false,
      page: 1,
      pageNum: 20,
      content: '',
      ifmore: false,
      scrollX: false,
      height:'',
    }
  },
  created () {
    this.height = document.documentElement.clientHeight-110
    this.feedback()
  },
  methods: {
    tochat (id) {
      this.$router.push(
        { path: '/chatrecord', query: { id: id } }
      )
    },
    goBack () {
      this.$router.go(-1)
    },
    scrollEnd () {
      if (this.ifmore) {
        ++this.page
        this.feedback()
      }
      // else {
      //   this.$toast({ message: '没有更多了', duration: 1000 })
      // }
    },
    // 学情反馈接口
    async feedback () {
      const params = {
        page: this.page,
        page_num: this.pageNum
      }
      const reslist = await this.api.feedback(params)
      if (reslist.code === 1) {
        const res = reslist.data.list.data
        if (res.length === 0) {
          this.ifmore = false
          this.feed = this.feed.concat(res)
        } else {
          this.ifmore = true
          this.feed = this.page === 1 ? res : this.feed.concat(res)
        }
      } else {
        this.$toast(reslist.msg)
      }
    },

    // 讲师评析点击事件
    async evaluateClick (item) {
      this.content = item
      this.ifmodel = true
      // if (item.is_read === 0) {
      //   const params = {
      //     train_class_id: item.train_class_id
      //   }
      //   const reslist = await this.api.evaluate(params)
      //   if (reslist.code === 1) {
      //     this.feedback()
      //   } else {
      //     this.$toast(reslist.msg)
      //   }
      // }
    }
  }

}
</script>
<style lang="scss" scoped>
  .fecknav{
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 8;
  }
  .feckcontent{
    margin-top: 0.88rem;
  }
    .feed_item{
        width: 100%;
        height: auto;
        padding: 0.32rem;
        box-sizing: border-box;
        border-bottom: 0.01rem solid #eee;
    }
    .feed_item .img{
        width: 2.32rem;
        height: 1.28rem;
        margin-right: 0.24rem;
    }
    .feed_title{
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.44rem;
    }
    .feed_time{
        color: rgba(150,151,153,1);
        font-size: 0.24rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.4rem;
        margin-top: 0.06rem;
    }
    .btns{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .feed_but{
        width: 1.44rem;
        height: 0.56rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(37.19,124.31,255,1);
        color: rgba(255,255,255,1);
        font-size: 0.24rem;
        font-weight: 500;
        line-height: 0.56rem;
        text-align: center;
        float: right;
        position: relative
    }

    .red{
        width: 0.13rem;
        height: 0.13rem;
        background-color: red;
        border-radius:50%;
        position: absolute;
        top: -2px;
        right: -2px;
        }

    .feedmodel{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
    }
    .feedmodel_close{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4;
    }
    .feedmodel_cont img{
        width: 5.9rem;
        height: 1.6rem;
    }
    .feedmodel_cont{
        width: 5.9rem;
        height: 5.36rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 5;
    }
    .feedmodel_detail{
        color: rgba(100,101,102,1);
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.48rem;
        padding: 0.32rem;
        box-sizing: border-box;
    }

    .red{
        width: 0.13rem;
        height: 0.13rem;
        background-color: red;
    }
    .marginleft{
      margin-left: 10px;
    }
</style>
