<template>
  <div id="app">
    <!-- 路由占位符 -->
    <meta name="viewport" content="width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=0">
    <meta http-equiv="cache-control" content="no-cache, no-store, must-revalidate">

    <keep-alive :include="arr">
        <!-- include属性, 只有匹配名称的组件会被缓存 -->
        <router-view v-wechat-title="$route.meta.title"></router-view>
    </keep-alive>
  </div>
</template>
<script>
export default {
  data () {
    return {
      transitionName: '',
      arr: ['result', 'data', 'onClass'] // 要缓存的组件名称, 组件内需要写name
    }
  }
}

</script>
<style lang="scss">
  *{
    padding: 0;
    margin: 0;
  }
  body{
    width: 100%;
    height: 100%;
    background-color: #f7f8fa;
  }
  .noresult{
    text-align: center;
    color: lightgrey;
    font-size: 0.32rem;
    padding: 1rem 0;
  }
</style>
