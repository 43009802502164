<template>
    <div>
        <nav-bar>
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
        </nav-bar>
        <div class="edit">
            <div class="edittitle">修改密码</div>
            <div class="user_line display-flex-between">
                <input type="text" placeholder="请输入手机号" v-model="phone"/>
            </div>
            <div class="user_line display-flex-between" >
                <input type="text" placeholder="输入验证码" v-model="code"/>
                <div class="code_line">|</div>
                <div style="color: #257CFF;" @click="getCode">{{codeValue}}</div>
            </div>
            <div class="line"></div>
            <div class="user_line display-flex-between" >
                <input type="password" placeholder="输入原密码" v-model="oldpass"/>
            </div>
            <div class="user_line display-flex-between">
                <input type="password" placeholder="输入新密码" v-model="newpass"/>
                <div class="img"></div>
            </div>
            <div class="user_line display-flex-between">
                <input type="password" placeholder="再次输入新密码" v-model="newpassagain"/>
                <div class="img"></div>
            </div>
        </div>
        <div class="over" @click="over">完成</div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      codeValue: '获取验证码',
      code: '',
      oldpass: '',
      newpass: '',
      newpassagain: '',
      number: 300,
      phone: ''
    }
  },
  mounted() {
    setTimeout(() => {
      if(this.code.length || this.oldpass.length){

        this.code = ''
      this.oldpass = ''
      }
    }, 800);
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async getcodenum () {
      const that = this
      const params = {
        mobile: that.phone,
        event: 'changepwd'
      }
      const result = await that.api.phonetoken(params)
      if (result.code === 1) {
        this.timer = setInterval(() => {
          this.number--
          this.codeValue = this.number + 's后重新获取'
          if (this.number === 0) {
            clearInterval(this.timer)
            this.codeValue = '重新获取'
          }
        }, 1000)
      } else {
        that.$toast(result.msg)
      }
    },
    getCode () {
      if (this.codeValue === '获取验证码' || this.codeValue === '重新获取') {
        this.getcodenum()
      }
    },
    async over () {
      const that = this
      if (this.code === '' || this.newcode === '' || this.newcodeagain === '') {
        this.$toast('请填写完整')
      } else if (this.newpass !== this.newpassagain) {
        this.$toast('密码不一致，请重新填写')
      } else {
        const params = {
          mobile: that.phone,
          captcha: that.code,
          oldpassword: that.oldpass,
          newpassword: that.newpass,
          confirmpassword: that.newpassagain
        }
        const res = await that.api.edituserpass(params)
        console.log(res)
        if (res.code === 1) {
          that.$router.go(-1)
          that.$toast('修改成功，请重新登')
        } else {
          that.$toast(res.msg)
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
    .edit input{
        text-align: left;
    }
    .user_line{
        border-top: 0.01rem solid #eee;
    }
    .user_line:nth-child(5){
        border-top: none;
    }
    .user_line:nth-child(2){
        border-top: none;
    }
    .edittitle{
        color: rgba(50,50,51,1);
        font-size: 0.36rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 0.48rem;
        text-align: left;
        padding: 0 0.32rem;
        box-sizing: border-box;
        margin: 0.68rem 0;
    }
    .code_line{
        color: #257CFF;
        margin: 0 0.32rem;
    }
    .over{
        width: 6.86rem;
        height: 0.88rem;
        opacity: 1;
        background: rgba(37.19,124.31,255,1);
        color: rgba(255,255,255,1);
        font-size: 0.32rem;
        font-weight: 500;
        text-align: center;
        line-height: 0.88rem;
        border-radius: 0.1rem;
        margin: 0.68rem auto;
    }
</style>
