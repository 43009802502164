<template>
    <div>
      <div class="tabs">
        <div class="display-flex-between">
            <div
            class="tabsitem"
            @click="chooseTab(index)"
            v-bind:style="{width:itemwidth+'px'}"
            :class="active===index?'activeclass':''"
            v-for="(item,index) in tabs"
            :key="index">{{item}}</div>
        </div>
        <div class="activeclassline" v-bind:style="{left:left+'px'}"></div>

      </div>
      </div>
</template>
<script>
export default {
  name: 'tabs',
  props: {
    tabs: {
      type: Array
    },
    active: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      left: 20,
      itemwidth: ''
    }
  },

  created () {
    this.itemwidth = document.documentElement.clientWidth / this.tabs.length
    this.left = (this.active * this.itemwidth) + this.itemwidth / 2
  },
  watch: {
    active: function () {
      this.left = (this.active * this.itemwidth) + this.itemwidth / 2
    }
  },
  methods: {
    chooseTab (index) {
      this.left = (index * this.itemwidth) + this.itemwidth / 2
      this.$emit('choose', index)
    }
  }
}
</script>
<style scoped lang="scss">
    .tabs{
        width: 100%;
        height: auto;
        padding:0.24rem 0;
        box-sizing: border-box;
        font-size: 0.28rem;
        color: rgba(150,151,153,1);
        background-color: #fff;
        position: relative;
    }
    .tabsitem{
      text-align: center;
    }
    .activeclass{
        color: rgba(50,50,51,1);
        font-weight: 600;

    }
    .activeclassline{
        content:'';
        position: absolute;
        top: 95%;
        transform: translate(-50%,0);
        width: 0.24rem;
        height: 0.06rem;
        opacity: 1;
        background: linear-gradient(180.25deg, rgba(37,202.68,255,1) 0%, rgba(67.52,37,255,1) 100%);
        transition: all 0.15s ease-in;
      }

</style>
