<template>
    <div>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <slot name="first"></slot>
                </div>
                <div class="swiper-slide">
                    <slot name="second"></slot>
                </div>
                <div class="swiper-slide" v-if="ifthird">
                    <slot name="third"></slot>
                </div>
                <div class="swiper-slide" v-if="ifthird&&ifforth">
                  <slot name="forth"></slot>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from 'swiper'
export default {
  name: 'swiperlist',
  props: {
    active: {
      type: Number,
      default: 0
    },
    ifthird: {
      type: [Boolean, String],
      default: false
    },
    ifforth: {
      type: [Boolean, String],
      default: false
    }
  },
  data () {
    return {
    }
  },
  watch: {
    active () {
      this.getSwiper()
    }
  },
  methods: {
    getSwiper () {
      const that = this
      /* eslint-disable no-new */
      // this.$nextTick(() => {
      new Swiper('.swiper-container', {
        loop: false,
        initialSlide: that.active,
        on: {
          slideChangeTransitionStart: function () {
            that.$emit('slideChange', this.activeIndex)
          }
        }
      })
    }
  },
  mounted () {
    this.getSwiper()
  }

}
</script>
<style scoped lang="scss">
    .swiper-container{
        height: auto;
    }
    .swiper-slide{height:10px}
    .swiper-slide-active { height:auto}
</style>
