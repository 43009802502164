<template>
    <div>
        <nav-bar>
            <div slot="left" class="myback" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">
                个人信息
            </div>
            <div slot="right" class="myresult"></div>
        </nav-bar>
        <div class="user">
            <div >
                <el-upload v-if="ifupload"
                :action="url+'api/common/uploadToOss'"
                :headers="headers"
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="handleAvatarSuccess">
                <div class="userpic"><van-image
                  width="100%"
                  height="100%"
                  fit="cover"
                  :round="true"
                  :src="userinfo.avatar"
                />
                <img src="../../assets/img/cream.png" alt="" class="cream">
                </div>
                </el-upload>
                <div v-else class="avatar-uploader load">
                  <van-loading  >上传中...</van-loading>
                </div>
            </div>
            <div  class="user_line display-flex-between">
                <div>姓名：</div>
                <input type="text" :value="userinfo.nickname" disabled/>
                <div class="img"></div>
            </div>
            <div  class="user_line display-flex-between">
              <div>人脸识别</div>
              <div class="display-flex-between">
                <van-uploader :max-size="isOverSize" :after-read="afterRead" type="primary"  capture="camera" v-if="Number(userinfo.is_check_face)==0">去设置</van-uploader>
                <div v-else @click="uodadepiv">可重置</div>
                <img src="../../assets/img/ic_arrow@2x.png" class="img">
              </div>
            </div>
            <router-link class="user_line display-flex-between" to="/editphone">
                <div>手机号：</div>
                <input type="text" placeholder="18752365525" v-model="userinfo.mobile"/>
                <img src="../../assets/img/ic_arrow@2x.png" class="img">
            </router-link>
            <router-link class="user_line display-flex-between" to="/editpass">
                <div>密码：</div>
                <input type="text" placeholder="点击修改"/>
                <img src="../../assets/img/ic_arrow@2x.png" class="img">
            </router-link>
            <div class="line"></div>
            <div class="user_line display-flex-between">
                <div>身份证号</div>
                <input type="text" :value="userinfo.identity" disabled/>
                <div class="img"></div>
            </div>
            <div class="user_line display-flex-between">
                <div>部门(车间)</div>
                <input type="text" :value="userinfo.department_name" disabled/>
                <div class="img"></div>
            </div>
            <div class="user_line display-flex-between">
                <div>岗位(班组)</div>
                <input type="text" :value="userinfo.pos_name" disabled/>
                <div class="img"></div>
            </div>
        </div>
        <div class="loadingbg" v-if="loading">
          <div class="loading">
          <van-loading color="#fff" size="70px" :vertical="true" >设置中...</van-loading></div>
        </div>
        <!-- <div>{{shouji}}</div> -->
    </div>
</template>
<script>
import config from '@/assets/js/config.js'
export default {
  data () {
    return {
      url: config.baseURL,
      imageUrl: require('../../assets/img/Group 1593@2x.png'),
      userinfo: '',
      headers: {
        token: localStorage.getItem('token')
      },
      ifupload: false,
      fileList: '',
      loading: false,
      shouji: 0
    }
  },
  methods: {
    isOverSize (file) {
      console.log(file)
      const maxSize = file.type === 'image/jpeg' ? 5 * 1024 * 1024 : 2 * 1024 * 1024
      return file.size >= maxSize
    },
    goBack () {
      this.$router.go(-1)
    },
    handleAvatarSuccess (response) {
      const that = this
      this.ifupload = false
      if (response.code === 1) {
        that.userinfo.avatar = response.data.fullurl
        that.editinfo()
      }
    },
    async getuserinfo () {
      const that = this
      const result = await that.api.userinfo()
      if (result.code === 1) {
        that.ifupload = true
        result.data.info.identity = result.data.info.identity.replace(/^(.{6})(?:\d+)(.{10})$/, '$1**********')
        that.userinfo = result.data.info
        localStorage.removeItem('userinfo')
        localStorage.setItem('userinfo', that.userinfo)
        console.log(localStorage.getItem('userinfo'))
      }
    },
    async editinfo () {
      const that = this
      const params = that.userinfo
      const result = await that.api.edituserinfo(params)
      if (result.code !== 1) {
        that.$toast(result.msg)
      } else {
        that.getuserinfo()
      }
    },
    async uodadepiv () {
      const delres = await this.api.delpeoplepic()
      if (delres.code == 1) {
        this.$toast('删除成功，重新设置')
        this.getuserinfo()
      }
    },
    async afterRead (file) {
      if (Number(this.userinfo.company_check_face) === 0) {
        this.$toast('该公司未开启人脸识别')
      } else {
        this.loading = true
        console.log(file)
        const fromdata = new FormData()
        fromdata.append('file', file.file)
        fromdata.append('category', 'face')
        console.log(fromdata)
        const params = { file: fromdata }
        const res = await this.api.unloadfacefile(fromdata)
        if (res.code == 1) {
          console.log(1)
          this.upload(res.data.fullurl)
          console.log(2)
        }
      }
    },
    async upload (url) {
      const paramss = { url: url }
      console.log(3)
      const re = await this.api.unloadpic(paramss)
      console.log(5)
      if (re.code == 1) {
        console.log(4)
        this.$toast('设置成功')
        this.getuserinfo()
      } else {
        this.$toast(re.msg)
      }
      this.loading = false
      console.log(6)
    }
  },
  mounted () {
    this.getuserinfo()
  }
}
</script>
<style lang="scss">
    body{
        background-color: #fff;
    }
    .myback,.myresult{
        width: 1rem;
    }
    .myback img{
        width: 0.48rem;
        height: 0.48rem;
    }
    .userinfotitle{
        font-size: 0.34rem;
        font-weight: 700;
        text-align: center;
    }
    .user{
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .load{
        width: 1.44rem;
        height: 1.44rem;
        border-radius: 50%;
        margin: 1.24rem auto 0;
    }
    .userpic{
        width: 1.44rem;
        height: 1.44rem;
        border-radius: 50%;
        margin-top: 0.99rem;
    }
    .cream{
        width: 0.32rem;
        height: 0.32rem;
        margin-left:-0.4rem;
    }
    .user_line{
        width: 92%;
        margin: 0 auto;
        height: auto;
        padding: 0.28rem 0;
        box-sizing: border-box;
        border-top: 0.01rem solid #eee;
        font-size: 0.28rem;
        color: black;
    }
    .user_line:nth-child(2){
        border-top: none;
    }
    .user_line:nth-child(6){
        border-top: none;
    }
    .user_line .img{
        width:0.36rem;
        height: 0.36rem;
    }
    .user_line input{
        flex: 1;
        height: auto;
        text-align: right;
        background: none;
        border: none;
    }
    .line{
        width: 100%;
        height: 0.16rem;
        opacity: 1;
        background: rgba(247,248,250,1);
    }
    .van-uploader{
      width: 50px;
    }
    .van-uploader__upload{
      height: 50px;
      margin: 0;
    }
    .loadingbg{
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.7);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
    }
    .loading{
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
</style>
