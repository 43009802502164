// var Length = document.documentElement.clientWidth;
// var baseWidth = Length <= 1024 ? Length : 1024 < Length ? 750 : '';
// document.documentElement.style.fontSize = baseWidth / 750 * 32 + 'px';
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0]
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  if (htmlWidth >= 750) {
    htmlWidth = 750
  }
  if (htmlWidth <= 320) {
    htmlWidth = 320
  }
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`
}
window.onresize = setHtmlFontSize
setHtmlFontSize()
