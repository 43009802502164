<template >
    <div >
        <img src="../../assets/img/bg3.png" class="bg3" alt="">
        <nav-bar class="examnavbar">
            <div slot="left" class="backnav" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="center display-flex" >

            </div>
            <div slot="right" >
                <div @click="tocard" class="picker display-flex">
                    <div>答题卡</div>
                    <img src="../../assets/img/card.png" alt="">
                </div>
            </div>
        </nav-bar>
        <div class="examcont">
            <div class="examone">
                <div class="exam_title" v-if="!!subjectList.item">({{num+1}}/{{alltest.length}})  {{ subjectList.item.title }}
                  <span v-if="subjectList.type==2">(多选)</span>
                  <span v-if="subjectList.type==3">(判断)</span>
                  <span v-if="subjectList.type==1">(单选)</span>
                </div>
                <div v-for="(item,index) in options_arr"
                  :key="index">
                  <div
                  v-if="!!item.value"
                  class="key_one"
                  :class="subjectList.answer.indexOf(item.option_name)==-1?'':subjectList.item.correct.indexOf(item.option_name)==-1?'red':'active'"
                  @click="option(item,index)">
                  <div>{{item.option_name}} . {{item.value}}</div>
                  <div class="img_list" v-if="item.pic">
                    <template v-for="(item_pic,index_pic) in item.pic_arr">
                      <img :src="item_pic" :key="index_pic" alt=""/>
                    </template>
                  </div>
                </div>
                </div>
                <div class="footer display-flex-between" ref="footer">
                    <div v-if="num!=0" class="nexttest display-flex">
                      <div class="common_handle" @click="upClick">
                        <img src="../../assets/img/next.png" alt="">
                        <span>上一题</span>
                      </div>
                    </div>
                    <div v-if="num!=alltest.length-1" class="nexttest display-flex">
                      <div class="common_handle" @click="nextClick">
                        <span>下一题</span>
                        <img src="../../assets/img/last.png" alt="">
                      </div>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="correct" v-if="!!subjectList.item">正确答案：{{ subjectList.item.correct }}</div>
            <div class="analy" :style="{'margin-bottom': content_bottom+'px'}" v-if="!!subjectList.item">解析: {{subjectList.item.remark}}</div>
        </div>
    </div>
</template>
<script>
import model from '@/components/model'
export default {
  name: 'examDetail',

  data () {
    return {
      disabled: false, // 选项是否禁用
      showClose: false,
      showClose1: true,
      overicon: require('@/assets/img/over2.png'),
      ifover: false,
      ifsubmit2: false,
      iftime: false,
      ifsubmit3: false,
      time: 60 * 1000,
      timelo: '',
      active: 0,
      alltest: [], // list里所有题
      subjectList: {}, // 每一题数据
      num: 0, // 题组下标
      answerId: '', // 答题id
      id: '',
      type: '',
      options_arr: '',
      content_bottom: 0
    }
  },
  methods: {
    goBack () {
      this.$router.push('record')
      localStorage.removeItem('alltest')
      localStorage.removeItem('testindex')
      localStorage.removeItem('answerId')
      localStorage.removeItem('timelo')
    },

    // 答题卡 （判断当前题目是否提交）
    async tocard () {
      this.$router.push('/cardmy')
    },

    // 上一题
    upClick () {
      --this.num
      this.subjectList = this.alltest[this.num]
      this.options_arr = this.subjectList.item.options_arr
      this.chooseone = this.subjectList.chooseone
      localStorage.setItem('testindex', this.num)
    },

    // 下一题
    async nextClick () {
      const that = this
      ++this.num
      this.subjectList = this.alltest[this.num]
      this.options_arr = this.subjectList.item.options_arr
      this.chooseone = this.subjectList.chooseone
      localStorage.setItem('testindex', this.num)
    },

    async getlist () {
      const that = this
      const params = {
        id: that.id
      }
      const res = await this.api.getrecorddetail(params)
      if (res.code === 1) {
        that.alltest = res.data.list
        localStorage.setItem('alltest', JSON.stringify(this.alltest)) // 本地存储题组
        localStorage.setItem('testindex', Number(this.num))
        this.subjectList = res.data.list[Number(this.num)]
        this.options_arr = this.subjectList.item.options_arr
        console.log(this.subjectList)
      }
    }
  },

  mounted () {
    if (localStorage.getItem('testindex')) {
      this.num = Number(localStorage.getItem('testindex'))
      this.alltest = JSON.parse(localStorage.getItem('alltest'))
      this.subjectList = this.alltest[this.num]
      if (this.subjectList.item) {
        this.options_arr = this.subjectList.item.options_arr
      }
      localStorage.removeItem('testindex')
    } else {
      this.id = this.$route.query.id
      this.type = this.$route.query.type
      this.num = 0
      this.getlist()
    }
    this.content_bottom = this.$refs.footer.clientHeight;
    console.log(this.$refs.footer.clientHeight,123123132);
  }
}

</script>
<style lang="scss" scoped>
    .bg3{
        width: 100%;
        height: 5.48rem;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    .examnavbar{
        position: fixed;
        top: 0;
        z-index: 9;
    }
    .examcont{
        position: relative;
        top: 0.88rem;
        z-index: 1;
        bottom: 1rem;
    }
    .picker,.backnav{
        width: 1.6rem;
    }
    .backnav{
        font-size: 0.48rem;
        height: 0.48rem;
    }
    .backnav img{
        width: 0.48rem;
        height: 0.48rem;
    }
    .picker{
        justify-content: flex-end;
    }
    .picker img{
        margin-left: 0.2rem;
    }
    .centernav{
        width: 3.6rem;
        margin-left: 0.6rem;
        color: rgba(51,51,51,1);
        font-size: 0.34rem;
        font-weight: 700;
        justify-content: center;
    }
    .center_title{
        margin-right: 0.2rem;
    }
    .examone{
        width: 100%;
        height: auto;
        padding: 0 0.32rem;
        box-sizing: border-box;
    }
    .exam_title{
        color: rgba(37.19,124.31,255,1);
        font-size: 0.36rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.52rem;
        letter-spacing: 0.01rem;
        margin: 0.48rem 0;
    }
    .exam_title span{
      color: grey;
      font-weight: 400;
      font-size: 0.30rem;
    }
    .key_one{
        width: 6.86rem;
        height: auto;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        color: rgba(50,50,51,1);
        border: 0.01rem solid #eee;
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.44rem;
        padding: 0.4rem 0.32rem;
        box-sizing: border-box;
        margin-bottom: 0.24rem;
    }
    .img_list {
      display: flex;
    }
    .img_list img{
      width: 1rem;
      margin-top: .2rem;
      margin-right: .1rem;
      display: flex;
    }
    .active{
        background: rgba(37.19,124.31,255,0.08);
        color:#257CFF ;
        border: 0.01rem solid #257CFF;
    }
    .red{
        background: rgba(233,64,90,0.08);
        color:#E9405A ;
        border: 0.01rem solid #E9405A;
    }
    .nofaved{
        width: 1.28rem;
        height: 1.28rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        color: rgba(150,151,153,1);
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 0.01rem solid #eee;
        margin: 0.8rem 0.8rem 0 0;
        float: right;
    }
    .faved{
        width: 1.28rem;
        height: 1.28rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        color: #257CFF;
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 0.01rem solid #257CFF;
        margin: 0.8rem 0.8rem 0 0;
        float: right;
    }
    .nofaved img,.faved img{
        width: 0.44rem;
        height: 0.44rem;
    }
    .nexttest{
        width: 85%;
        color: rgba(37.19,124.31,255,1);
        font-size: 0.28rem;
    }
    .nexttest img{
        width:0.4rem;
        height: 0.4rem;
        margin: 0 0.1rem;
    }
    .nexttest .common_handle {
      display: flex;
    }
    .correct{
        width: 3.3rem;
        // height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(0,231.63,162.14,0.08);
        color: rgba(0,231.63,161.44,1);
        font-size: 0.36rem;
        margin: 0.32rem;
    }
    .analy{
        color: rgba(150,151,153,1);
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.44rem;
        padding:0 0.32rem 0.96rem;
        box-sizing: border-box;
    }
    .footer{
      border-top: 0.01rem solid #eee;
      padding: 0.32rem 0.32rem;
    }
</style>
