import Vue from 'vue'
import Vuex from 'vuex'
// import mutations from './mutations'
Vue.use(Vuex)
export default () => {
  return new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
    // mutations
  })
}
