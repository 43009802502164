<template>
    <div>
        <div class="bg" v-if="show">
            <div class="content show">
                <div style="height: 3.1rem;">
                    <Scroll
                    class="postscroll"
                    ref="scroll"
                    :listenScroll="true"
                    :probeType="3" >
                        <div class="posts display-flex">
                            <div @click="typeClick(item, index)" :class="current==index?'active':''" v-for="(item,index) in posts" :key="index" class="post_item">{{item.name}}</div>
                        </div>
                    </Scroll>
                </div>
                <div class="over" @click="complete">完成</div>
            </div>
        </div>
    </div>
</template>
<script>
import Scroll from '@/components/scroll.vue'
export default {
  name: 'fil',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Scroll
  },
  data () {
    return {
      posts: [],
      current: 0,
      infoType: '', // 选择工种
      itemId: '' // 工种id
    }
  },
  mounted () {
    this.pos()
  },
  methods: {
    fontNumber (date) {
      const length = date.length
      if (length > 6) {
        var str = ''
        str = date.substring(0, 6) + '...'
        return str
      } else {
        return date
      }
    },
    // 工种列表接口
    async pos () {
      const that = this
      that.isShow = !that.isShow
      const reslist = await that.api.pos()
      if (reslist.code === 1) {
        that.posts = reslist.data.list
        if (localStorage.getItem('pId')) {
          that.posts.forEach((item, index) => {
            item.name = this.fontNumber(item.name)
            if (item.id === localStorage.getItem('pId')) {
              that.current = index
            }
          })
        }
      } else {
        that.$toast(reslist.msg)
      }
    },
    typeClick (item, index) { // 点击选择工种
      this.infoType = item.name
      this.current = index
      this.itemId = item.id
      localStorage.removeItem('postname')
      localStorage.removeItem('pId')
      localStorage.setItem('postname', item.name)
      localStorage.setItem('pId', item.id)
      console.log(localStorage.getItem('postname'))
    },
    complete () { // 点击完成
      if (this.infoType === '') {
        this.infoType = this.posts[this.current].name
        this.itemId = this.posts[this.current].id
      }
      this.$emit('infoType', this.infoType, this.itemId)
    }
  }
}
</script>
<style scoped lang="scss">
    .bg{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9;
        top: 0.8rem;
    }
    .content{
        width: 100%;
        height:5.04rem;
        background-color: #fff;
        padding: 0.32rem;
        box-sizing: border-box;
        overflow: hidden;
    }
    .show{
        animation: show 0.3s ease-in;
    }
    @keyframes show{
        0%{
            opacity: 0;
            height: 0;
        }
        50%{
            opacity: 0.5;
            height: 2.5rem;
        }
        100%{
            opacity: 1;
            height:5.04rem;
        }

    }
    .postscroll{
        height: 100%;
        overflow: hidden;
    }
    .posts{
        height:auto;
        flex-wrap: wrap;
    }
    .post_item{
        width: 2.1rem;
        height: 0.8rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(239,242,246,1);
        color: rgba(7,29,17,1);
        font-size: 0.28rem;
        font-weight: 400;
        text-align: center;
        line-height: 0.8rem;
        margin:0 0.26rem 0.26rem 0;
    }
    .post_item:nth-child(3n){
        margin-right: 0;
    }
    .active{
        color: #fff;
        background: rgba(37.19,124.31,255,1)
    }
    .over{
        width: 100%;
        height: 0.88rem;
        opacity: 1;
        background: rgba(37.19,124.31,255,1);
        text-align: center;
        color: #fff;
        font-size: 0.32rem;
        line-height: 0.88rem;
        margin-top: 0.36rem;
    }
</style>
