<template>
    <div>
        <nav-bar>
            <div slot="left" class="certificateback" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">
                证书上传
            </div>
            <div slot="right" class="certificateresult"></div>
        </nav-bar>
        <div class="upload">
            <el-upload
                :action="url+'api/common/uploadToOss'"
                class="upload_pic"
                :headers="headers"
                :show-file-list="false"
                :on-success="handleAvatarSuccess">
                <img  class="zheng" v-if="!detail.image" src="../../assets/img/zhengjian.png" alt="">
                <img class="zheng" v-else :src="detail.image" alt="">
                <div class="upload_text" v-show="!detail.image">点击上传证件图片</div>
            </el-upload>
            <div class="chooseType">
                <select v-model="detail.cert_id" style="background: none;">
                    <option value="" disabled selected>选择证件类型</option>
                    <option v-for="(item,index) in list" :key="index" :value="item.id">{{item.name}}</option>
                </select>
            </div>
            <div class="chooseType display-flex-between" @click="ifshowtime=!ifshowtime">
                <div>初始取证时间</div>
                <input type="text" disabled placeholder="请选择初始取证时间" v-model="detail.start_time"/>
            </div>
            <!-- <div class="chooseType display-flex-between" @click="ifshowtime1=!ifshowtime1">
              <div>失效时间</div>
              <input type="text" disabled placeholder="请选择失效时间" v-model="detail.end_time"/>
          </div> -->
            <div >
                <div class="footer" @click="toupload">
                    <div class="footer_button">上传</div>
                </div>
            </div>

        </div>
        <div class="showtime" v-show="ifshowtime">
          <van-datetime-picker
            class="timeshow"
            v-model="currentDate"
            type="date"
            title="选择日期"
            @confirm="confirm"
            @cancel="ifshowtime=false"
          />
        </div>
        <div class="showtime" v-show="ifshowtime1">
          <van-datetime-picker
            class="timeshow"
            v-model="currentDate"
            type="date"
            title="选择日期"
            @confirm="confirm1"
            @cancel="ifshowtime1=false"
          />
        </div>
        <model
        :ifShow="ifmodel"
        showModal="false"
        title="上传成功"
        type="success"
        time=1000
        ></model>
        <model
        :ifShow="ifmodel2"
        showModal="false"
        title="上传失败"
        type="fail"
        time=1000
        ></model>
    </div>
</template>
<script>
import config from '@/assets/js/config.js'
import model from '@/components/model.vue'
export default {
  components: {
    model
  },
  data () {
    return {
      url: config.baseURL,
      currentDate: '',
      ifshowtime: false,
      ifmodel: false,
      ifmodel2: false,
      ifshowtime1: false,
      headers: {
        token: localStorage.getItem('token')
      },
      list: [],
      id: '',
      detail: {
        id: '',
        image: '',
        start_time: '',
        cert_id: ''
      }
    }
  },
  methods: {
    confirm (e) {
      this.ifshowtime = false
      const month = (e.getMonth() + 1) < 10 ? '0' + (e.getMonth() + 1) : (e.getMonth() + 1)
      const day = e.getDate() < 10 ? '0' + e.getDate() : e.getDate()
      const time = e.getFullYear() + '-' + month + '-' + day
      this.detail.start_time = time
    },
    confirm1 (e) {
      this.ifshowtime1 = false
      const month = (e.getMonth() + 1) < 10 ? '0' + (e.getMonth() + 1) : (e.getMonth() + 1)
      const day = e.getDate() < 10 ? '0' + e.getDate() : e.getDate()
      const time = e.getFullYear() + '-' + month + '-' + day
      this.detail.end_time = time
    },
    goBack () {
      this.$router.go(-1)
    },
    handleAvatarSuccess (response) {
      const that = this
      if (response.code === 1) {
        that.detail.image = response.data.fullurl
      }
    },
    async toupload () {
      if (this.detail.image === '') {
        this.$toast('请上传证件类型图片')
      } else if (this.detail.cert_id === '') {
        this.$toast('请选择证件类型')
      } else if (this.detail.start_time === '') {
        this.$toast('请选择证件取证时间')
      } else {
        const res = await this.api.uploadcertificatetype(this.detail)
        if (res.code === 1) {
          this.goBack()
          this.$toast(res.msg)
        } else {
          this.$toast(res.msg)
        }
      }
      // else if (this.detail.end_time === '') {
      //   this.$toast('请选择证件失效时间')
      // }
    },
    async gettypelist () {
      const res = await this.api.certificatetype()
      if (res.code === 1) {
        this.list = res.data.list
        console.log(this.list)
      }
    },
    async getcertificateDetail () {
      const params = {
        id: this.detail.id
      }
      const res = await this.api.certificateDetail(params)
      if (res.code === 1) {
        this.detail.image = res.data.detail.image_text
        this.detail.cert_id = res.data.detail.credentials_id
        this.detail.start_time = res.data.detail.start_time
      } else {
        this.$toast(res.msg)
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.detail.id = this.$route.query.id
      this.getcertificateDetail()
    }
    this.gettypelist()
  }
}
</script>
<style lang="scss">
    body{
        background-color: #ffffff;
    }
    .upload{
        text-align: center;
    }
    .zheng{
        width: 6.22rem;
        height: 4.16rem;
        opacity: 1;
        margin: 0.71rem 0 0.48rem;
    }

    .chooseType{
        width: 88%;
        margin: 0.12rem auto;
        height: 0.96rem;
        border-radius: 0.16rem;
        opacity: 1;
        background: rgba(239,242,246,1);
        border: none;
        padding: 0 0.30rem;
        box-sizing: border-box;
        color: rgba(50,50,51,1);
        font-size: 0.28rem;
    }
    input{
        text-align: right;
        background: none;
        border: none;
    }
    select{
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        outline: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    .upload_pic{
        margin-bottom: 1rem;
    }
    .upload_text{
        color: rgba(150,151,153,1);
        font-size: 0.24rem;
        font-weight: 400;
        margin-top: -1.5rem;
    }
    .showtime{
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: fixed;
      bottom: 0;
      left: 0;
    }
    .showtime .timeshow{
      width: 100%;
      /* height: 100%; */
      position: fixed;
      bottom: 0;
      left: 0;
    }
</style>
