<template >
  <div>
    <img src="../../assets/img/bg3.png" class="bg3" alt="" />
    <nav-bar class="examnavbar">
      <div slot="left" class="backnav" @click="goBack">
        <img src="../../assets/img/back.png" alt="" />
      </div>
      <div
        slot="center"
        class="center display-flex"
        v-if="type == 4 || practicetype == 1 || practicetype == 2"
      >
        <div class="center_title">倒计时</div>
        <van-count-down :time="time" @finish="finish" @change="change" />
      </div>
      <div slot="right" v-if="type == '3' || type == '4'" style="display: flex;">
        <!-- <div class="footer_button1" v-if="type =='3'" @click="resetTestHandler"> 重做 </div> -->
        <div @click="tocard" class="picker display-flex">
          <div>答题卡</div>
          <img src="../../assets/img/card.png" alt="" />
        </div>
      </div>
    </nav-bar>
    <div class="examcont">
      <div class="examone">
        <div class="exam_title">
          <span v-if="Number(type) != 2"
            >({{ num + 1 }}/{{ alltest.length }})</span
          >
          {{ subjectList.title }}
          <span v-if="subjectList.type == 2">(多选)</span>
          <span v-if="subjectList.type == 3">(判断)</span>
          <span v-if="subjectList.type == 1">(单选)</span>
        </div>
        <!-- 多选  每日一练 -->
        <!-- <div v-if="subjectList.type==2&&type=='1'">
                  <div
                  :disabled="disabled"
                  v-for="(item,index) in subjectList.options_arr"
                  :key="index"
                  class="key_one"
                  :class="chooseone.indexOf(item.option_name)==-1?'':subjectList.correct.indexOf(item.option_name)==-1?'red':'active'"
                  @click="option(item,index)">
                  <div>{{item.option_name}} . {{item.value}}</div>
                  <div class="img_list" v-if="item.pic">
                    <template v-for="(item_pic,index_pic) in item.pic_arr">
                      <img :src="item_pic" :key="index_pic" alt=""/>
                    </template>
                  </div>
                </div>
                </div> -->
        <!--多选   挑战答题 专项练习-->
        <div
          class="title_bottom"
          v-if="
            Number(subjectList.type) == 2 &&
            (type == '2' || type == '3' || type == '4' || type == '1')
          "
        >
          <div
            :disabled="disabled"
            v-for="(item, index) in this.subjectList.options_arr"
            :key="index"
            class="key_one"
            :class="{
              active: chooseone.indexOf(item.option_name) !== -1,
              red: isDRedHandler(item) && $route.query.isShowAnswer == 'success'
            }"
            @click="option(item, index)"
          >
            <div>{{ item.option_name }} . {{ item.value }}</div>
            <div class="img_list" v-if="item.pic">
              <template v-for="(item_pic, index_pic) in item.pic_arr">
                <img :src="item_pic" :key="index_pic" alt="" />
              </template>
            </div>
          </div>
        </div>
        <!-- 单选  每日一练 -->
        <!-- <div v-if="subjectList.type!=2&&type=='1'">
                  <div v-for="(item,index) in subjectList.options_arr"
                  :key="index">
                  <div
                  :disabled="disabled"
                  v-if="item.value"
                  class="key_one"
                  :class="chooseone!=item.option_name?'':chooseone==subjectList.correct?'active':'red'"
                  @click="option(item,index)">
                  <div >{{item.option_name}} . {{item.value}}</div>
                  <div class="img_list" v-if="item.pic">
                    <template v-for="(item_pic,index_pic) in item.pic_arr">
                      <img :src="item_pic" :key="index_pic" alt=""/>
                    </template>
                  </div></div>
                </div>
                </div> -->
        <!--单选   挑战答题 专项练习-->
        <div
          class="title_bottom"
          v-if="
            subjectList.type != 2 &&
            (type == '2' || type == '3' || type == '4' || type == '1')
          "
        >
          <div v-for="(item, index) in subjectList.options_arr" :key="index">
            <div
              :disabled="disabled"
              v-if="item.value"
              class="key_one"
              :class="{
                  active: chooseone === item.option_name,
                  red: isRedHandler(item) && $route.query.isShowAnswer == 'success'
                  }"
              @click="option(item, index)"
            >
            <!-- 后续更改  如答案选错，所选错的项改为红色或加红色底纹 -->
              <div>{{ item.option_name }} . {{ item.value }}</div>
              <div class="img_list" v-if="item.pic">
                <template v-for="(item_pic, index_pic) in item.pic_arr">
                  <img :src="item_pic" :key="index_pic" alt="" />
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="footer display-flex-between">
          <div
            v-if="num != 0 && type != 2"
            class="nexttest display-flex"
          >
            <div class="common_handle" @click="upClick">
              <img src="../../assets/img/next.png" alt="" />
              <span>上一题</span>
            </div>
          </div>
          <div
            v-if="(num != alltest.length - 1 && type != 2) || type == 2"
            class="nexttest display-flex"
          >
            <div class="common_handle" @click="nextClick">
              <span>下一题</span>
              <img src="../../assets/img/last.png" alt="" />
            </div>
          </div>
          <div
            v-if="
              ((type == 3 || type == 4) && num + 1 == alltest.length) ||
              type == 1
            "
            class="footer_button"
            @click="submitTopic"
          >
            交卷
          </div>
        </div>
        <div class="footer_button2 display-flex-between" v-if="type =='3' && $route.query.isShowAnswer == 'success'" @click="resetTestHandler"> 重做 </div>

      </div>
      <div class="line"></div>
      <!-- :class="{error: alltest[num].chooseone}" 当前的选项数据 -->
      <div class="correct" v-if="answerList.chooseone && type == '3' && $route.query.isShowAnswer == 'success'">正确答案：{{ answerList.correct }}</div>
      <div class="analy" :style="{'margin-bottom': content_bottom+'px'}" v-if="answerList.chooseone && type == '3' && $route.query.isShowAnswer == 'success'">解析: {{answerList.remark}}</div>

      <model
        :ifShow="ifsubmit3"
        confirmText="确定"
        title="您还有题未答完，请答完再提交"
        @confirmFun="confirmFun3"
      ></model>

      <model
        v-if="type == 2"
        :ifShow="iftime"
        confirmText="提交"
        title="答题时间已到，请提交试卷"
        :showClose="showClose"
        @confirmFun="challengetop"
      ></model>

      <model
        :ifShow="ifsubmit2"
        confirmText="继续答题"
        title="继续答题"
        content="剩余时间"
        :showClose="showClose1"
      ></model>

      <model
        ifchallenge="true"
        :ifShow="ifover"
        confirmText="完成"
        :cancelText="cancelText"
        :title="truenumber"
        :content="content"
        :icon="overicon"
        @confirmFun="goBack"
        @cancelFun="tocard1"
      ></model>
    </div>
    <model
      :scroll="false"
      :ifphoto="true"
      :ifShow="showyan"
      confirmText="确定"
      cancelText="取消"
      title="身份信息验证"
      content="设置人脸识别照片为本人学习"
      @yanpeoplepic="sureyan"
      @cancelFun="cancelyan"
    ></model>
    <model
      :ifShow="showhavepic"
      confirmText="确定"
      cancelText="取消"
      title="您当前还未设置人脸识别的照片，请前去设置"
      @confirmFun="confirmFungo"
      @cancelFun="goBack"
    ></model>
  </div>
</template>
<script>
import model from '@/components/model'
export default {
  name: 'examDetail',
  components: {
    model
  },
  data () {
    return {
      showhavepic: false,
      showyan: false,
      cancelText: '',
      disabled: false, // 选项是否禁用
      showClose: false,
      showClose1: true,
      overicon: require('@/assets/img/over2.png'),
      ifover: false,
      ifsubmit2: false,
      iftime: false,
      ifsubmit3: false,
      time: 60,
      timelo: '',
      active: 0,
      alltest: [], // list里所有题
      subjectList: {}, // 每一题数据
      answerList: {},
      num: 0, // 题组下标
      answerId: '', // 答题id
      type: '',
      truenumber: '',
      content: '',
      chooseone: '',
      challenge: '', // 挑战答题答案
      practicetype: '',
      is_exp: 0,
      lastsubmit: false,
      onetesttype: '',
      ifonlysubmitone: false, // 挑战答题卡只提交当前题目不交卷
      onclicknext: true,
      paramschallenge: '',
      islast: '',
      currentAnswerData: {} // 该题数据
    }
  },
  beforeDestroy () {
    // localStorage.removeItem('alltest')
    // localStorage.removeItem('testindex')
    // localStorage.removeItem('answerId')
    // localStorage.removeItem('timelo')
  },
  methods: {
    // 单选 专项练习答错标红
    isRedHandler (item) {
      // console.log(this.subjectList, this.alltest[this.num], 'type')
      if (this.answerList.correct && this.type == '3' && this.$route.query.isShowAnswer == 'success' && this.answerList.correct.indexOf(this.chooseone) === -1 && this.chooseone === item.option_name) return true
    },
    // 多选 专项练习答错标红
    isDRedHandler (item) {
      // console.log(this.chooseone, '选择')
      // console.log(this.subjectList, this.alltest[this.num], 'typeD', item)
      // is_correct 当前的是否是正确的 option_name当前的选项
      if (item.is_correct === 1 && this.chooseone.indexOf(item.option_name) !== -1) return false
      if (item.is_correct === 0 && this.chooseone.indexOf(item.option_name) === -1) return false
      if (item.is_correct === 0 && this.chooseone.indexOf(item.option_name) !== -1) return true
    },
    goBack () {
      this.$router.go(-1)
      // if(this.$route.query.islianxi == 'lianxi') return
      localStorage.removeItem('alltest')
      localStorage.removeItem('testindex')
      localStorage.removeItem('answerId')
      localStorage.removeItem('timelo')
      // window.close()
    },
    tocard1 () {
      localStorage.removeItem('alltest')
      localStorage.removeItem('testindex')
      localStorage.removeItem('answerId')
      localStorage.removeItem('timelo')
      localStorage.removeItem('indexCurrent')
      localStorage.setItem('indexCurrent', 2)
      this.$router.push({
        path: '/recorddetail',
        query: { id: this.answerId, type: this.type }
      })
    },
    confirmFun3 () {
      this.ifsubmit3 = false
    },
    // 倒计时完成
    finish () {
      this.iftime = true
      if (Number(this.type) === 4 || Number(this.practicetype) === 1 || Number(this.practicetype) === 2) {
        this.timesubmit()
      }
    },
    // 模拟考试  班级考试交卷  倒计时
    async timesubmit () {
      const that = this
      if (
        (Number(that.subjectList.type) === 2 && that.challenge === '') ||
        (Number(that.subjectList.type) !== 2 &&
          that.subjectList.chooseone === '')
      ) {
        const params1 = {
          user_item_id: that.answerId
        }
        const res = await that.api.challengetop(params1)
        if (res.code === 1) {
          that.iftime = false
          that.ifover = true
          that.content = '共' + that.alltest.length + '道题'
          that.truenumber = '共答对' + res.data.correct_count + '道题'
          that.cancelText = '查看答题记录'
        }
      } else {
        const params = {
          user_item_id: that.answerId,
          item_id: that.subjectList.id,
          answer:
            that.subjectList.type === '2' ? that.challenge : that.chooseone
        }
        console.log(params)
        console.log(that.subjectList)
        const res = await that.api.submitTopicone(params)
        if (res.code === 1) {
          const params1 = {
            user_item_id: that.answerId
          }
          const res = await that.api.challengetop(params1)
          if (res.code === 1) {
            that.iftime = false
            that.ifover = true
            that.content = '共' + that.alltest.length + '道题'
            that.truenumber = '共答对' + res.data.correct_count + '道题'
            that.cancelText = '查看答题记录'
          }
        }
      }
    },
    change (e) {
      this.timelo = (e.hours * 3600 + e.minutes * 60 + e.seconds) * 1000
      localStorage.setItem('timelo', this.timelo / 1000)
    },
    // 专线练习,挑战答题单题目提交
    async submitone () {
      if (!this.onclicknext) {
        this.$toast('点击的太快了')
      } else {
        const that = this
        const params = {
          user_item_id: that.answerId,
          item_id: that.subjectList.id,
          answer:
            that.subjectList.type === '2' ? that.challenge : that.chooseone
        }
        console.log('提交了', that.subjectList)
        const res = await that.api.submitTopicone(params)
        if (res.code === 1) {
          that.onetesttype = res.data
          if (Number(that.type) === 2) {
            that.ifonecorrect()
          } else {
            if (that.num === that.alltest.length - 1) {
              if (that.ifonlysubmitone) {
                that.lastsubmit = true
              } else {
                that.lastsubmit = true
                that.challengetop()
              }
            } else {
              if (!this.ifonlysubmitone) {
                ++that.num
                that.subjectList = that.alltest[that.num]
                that.chooseone = that.subjectList.chooseone
                console.log(that.chooseone, '是', that.alltest[that.num])

                // that.challenge = that.subjectList.chooseone.split("").join(",");
                const chooseoption = []
                const options_arr = that.subjectList.options_arr
                console.log(options_arr, '有数据么')
                options_arr.forEach((item, index) => {
                  console.log(that.chooseone, '是')
                  if (that.chooseone.indexOf(item.option_name) !== -1) {
                    chooseoption.push(item.option_name)
                  }
                })
                const newchoose = []
                chooseoption.forEach((itemc) => {
                  if (itemc) {
                    newchoose.push(itemc)
                  }
                })
                newchoose.sort()
                that.challenge = newchoose.join(',')
                console.log(that.challenge, '454545454')
                localStorage.setItem('testindex', that.num)
                that.ifonlysubmitone = false
                that.onclicknext = false

                setTimeout(() => {
                  this.onclicknext = true
                }, 200)
              }
            }
          }
        } else {
          this.$toast(res.msg)
        }
      }
    },
    // 挑战答题答对继续答错退出
    ifonecorrect () {
      const that = this
      if (that.onetesttype.is_correct === 1) {
        console.log(Number(that.islast) === 1)
        if (Number(that.islast) === 1) {
          that.getoverinfo()
        } else {
          console.log(that.paramschallenge)
          that.getlistchllen(that.paramschallenge)
        }
      } else {
        that.getoverinfo()
      }
    },
    async getoverinfo () {
      // 获取挑战答题的弹板
      const that = this
      const res = await that.api.getchallinfo({ user_item_id: that.answerId })
      if (res.code === 1) {
        that.iftime = false
        that.ifover = true
        that.cancelText = '查看答题记录'
        that.content = '共' + res.data.total_count + '道题'
        that.truenumber = '今日共答对' + res.data.correct_count + '道题'
        this.ifonlysubmitone = false
      } else {
        this.$toast(res.msg)
      }
    },
    // 答题卡 （判断当前题目是否提交）
    tocard () {
      const that = this
      if (
        (Number(that.subjectList.type) === 2 && that.challenge === '') ||
        (Number(that.subjectList.type) !== 2 &&
          that.subjectList.chooseone === '')
      ) {
      } else {
        this.ifonlysubmitone = true
        this.submitone()
      }
      console.log(that.subjectList, 7878787878)
      setTimeout(() => {
        // practicetype === 2 班级任务练习题新增时间
        console.log(this.type, this.practicetype, '状态')
        if (this.type === '4' || this.practicetype === 1 || this.practicetype === '2') {
          // 班级任务考试 模拟考试有倒计时
          this.$router.push({
            path: '/examcard',
            query: {
              alltest: that.alltest,
              time: that.timelo
            }
          })
        } else {
          // 专项练习没有倒计时
          if (that.challenge) {
            localStorage.setItem('challenge_choose', that.challenge)
          }
          this.$router.push({
            path: '/examcard',
            query: {
              alltest: that.alltest,
              isShowAnswer: 'success'
            }
          })
        }
      }, 1000)
    },
    async getlistchllen (object) {
      // 挑战答题,
      const that = this
      const params = {
        pos_id: object.id,
        type: object.type
      }
      const reslist = await that.api.startAnswer(params)
      if (reslist.code === 1) {
        that.islast = reslist.data.is_last_item
        that.chooseone = ''
        that.challenge = ''
        that.alltest = reslist.data.list
        that.answerId = reslist.data.user_item_id
        that.alltest.forEach((item) => {
          item.iftrue = '' // 对错
          item.chooseone = item.user_answer ? item.user_answer : ''
        })
        // if (Number(that.type) === 4) {
        //   that.time = reslist.data.over_second * 1000
        // }
        this.num = 0
        this.subjectList = that.alltest[this.num]
      } else {
        this.$toast(reslist.msg)
      }
    },
    // 获取题目列表（每日一练，专项练习，模拟考试）
    async getlist (object) {
      const that = this
      const params = {
        pos_id: object.id,
        type: object.type,
        paper_id: object.specialId
      }
      const reslist = await that.api.startAnswer(params)
      if (reslist.code === 1) {
        if (this.$route.query.islianxi == 'lianxi') {
          // this.alltest = JSON.parse(localStorage.getItem('alltest')) || reslist.data.list // 旧
          this.alltest = reslist.data.list // 新
          that.answerId = reslist.data.user_item_id
          that.alltest.forEach((item) => {
            item.answer !== '' ? (item.iftrue = 1) : (item.iftrue = '') // 对错
            // item.chooseone = item.chooseone ? item.chooseone : '' // 旧
            item.chooseone = item.answer ? item.answer : '' // 新
          })
          if (Number(that.type) === 4) {
            that.time = reslist.data.over_second * 1000
          }
          localStorage.setItem('time', that.time / 1000)
          localStorage.setItem('answerId', that.answerId)
          // this.num = Number(localStorage.getItem('testindex')) // 旧
          // console.log(reslist.data.list.map(item => item.answer).indexOf(''), '新的数据') // 新
          this.num = reslist.data.list.map(item => item.answer).indexOf('') // 新
          this.num === -1 && (this.num = reslist.data.list.length - 1)
          localStorage.setItem('testindex', this.num)
          this.subjectList = this.alltest[this.num]
          this.chooseone = this.subjectList.chooseone
          if (this.subjectList.chooseone) {
            this.answerList = this.alltest[this.num]
          }
          localStorage.setItem('alltest', JSON.stringify(this.alltest)) // 本地存储题组 aa
          console.log(this.alltest, 'this.alltest')
          console.log(this.subjectList.chooseone, 'chooseone')
        } else {
          that.alltest = reslist.data.list
          that.answerId = reslist.data.user_item_id
          that.alltest.forEach((item) => {
            item.iftrue = '' // 对错
            item.chooseone = item.user_answer ? item.user_answer : ''
          })
          if (Number(that.type) === 4) {
            that.time = reslist.data.over_second * 1000
          }
          localStorage.setItem('time', that.time / 1000)
          localStorage.setItem('answerId', that.answerId)
          localStorage.setItem('alltest', JSON.stringify(this.alltest)) // 本地存储题组 aa
          this.num = 0
          localStorage.setItem('testindex', this.num)
          this.subjectList = that.alltest[this.num]
        }
      } else {
        this.$toast(reslist.msg)
      }
    },

    // 上一题
    upClick () {
      const that = this
      if (this.num > 0) {
        // if (Number(that.type) === 2 || Number(that.type) === 3 || Number(that.type) === 4) {
        //   that.subjectList.chooseone = ''
        //   that.alltest[that.num] = that.subjectList
        //   localStorage.removeItem('alltest')
        //   localStorage.setItem('alltest', JSON.stringify(that.alltest)) // 本地存储题组
        // }
        --this.num
        this.subjectList = this.alltest[this.num]
        this.chooseone = this.subjectList.chooseone
        // this.challenge = this.subjectList.chooseone.split("").join(",");
        const chooseoption = []
        const options_arr = that.subjectList.options_arr
        options_arr.forEach((item, index) => {
          if (this.chooseone.indexOf(item.option_name) !== -1) { // 判断上一题选择的选项
            chooseoption.push(item.option_name)
          }
        })
        const newchoose = []
        chooseoption.forEach((itemc) => {
          if (itemc) {
            newchoose.push(itemc)
          }
        })
        newchoose.sort()
        this.challenge = newchoose.join(',')
        localStorage.setItem('testindex', this.num)
      } else {
        this.$toast('当前是第一题')
      }
      if (that.alltest[that.num].chooseone) {
        this.answerList = that.alltest[that.num]
      } else {
        this.answerList = {}
      }
      console.log(that.alltest[that.num], 'dijii')
    },

    // 下一题
    async nextClick () {
      const that = this
      console.log(that.type, that.practicetype, that.challenge, that.alltest[that.num], 56565656)

      if (Number(that.type) === 1) {
        // 每日一练
        if (that.num < that.alltest.length - 1) {
          ++that.num
          that.subjectList = that.alltest[that.num]
          that.chooseone = that.subjectList.chooseone
          localStorage.setItem('testindex', that.num)
        } else {
          that.$toast('当前是最后一题')
        }
      } else if (
        Number(that.type) === 3 && that.practicetype == 1
      ) {
        // 考试
        that.subjectList = that.alltest[that.num]
        that.chooseone = that.subjectList.chooseone
        if (that.chooseone === '') {
          that.$toast('请选择题目选项')
        } else {
          this.submitone()
        }
      } else if (
        Number(that.type) === 2 ||
        Number(that.type) === 3 ||
        Number(that.type) === 4
      ) {
        // 挑战答题
        if (!that.challenge && localStorage.getItem('challenge_choose')) {
          that.challenge = localStorage.getItem('challenge_choose')
        }
        localStorage.removeItem('challenge_choose')
        if (
          (Number(that.subjectList.type) === 2 && that.challenge === '') ||
          (Number(that.subjectList.type) !== 2 &&
            that.subjectList.chooseone === '')
        ) {
          console.log(Number(that.subjectList.type), that.challenge, 123123123132)
          that.$toast('请选择题目选项')
        } else {
          this.submitone()
        }
      }
      if (that.alltest[that.num + 1].chooseone) {
        this.answerList = that.alltest[that.num + 1]
      } else {
        this.answerList = {}
      }
    },

    // 点击选项 ^_^
    async option (item, index) {
      const that = this
      console.log(that.type)
      if (that.type === '2') {
        // 挑战答题（选择选项即为答题）
        console.log(that.subjectList.correct.indexOf(item.option_name))
        // if (that.subjectList.correct.indexOf(item.option_name) === -1) {
        //   that.submitone()
        // } else if (that.subjectList.correct.indexOf(item.option_name) !== -1) {
        if (that.subjectList.type !== '2') {
          that.chooseone = item.option_name
          console.log(that.chooseone)
          // that.submitone()
        } else {
          const chooseoption = that.chooseone ? that.chooseone.split(',') : []
          if (that.chooseone.indexOf(item.option_name) === -1) {
            chooseoption.push(item.option_name)
          } else {
            that.chooseone = that.chooseone.replace(item.option_name, '')
            chooseoption.splice(chooseoption.indexOf(item.option_name), 1)
          }
          const newchoose = []
          chooseoption.forEach((itemc) => {
            if (itemc) {
              newchoose.push(itemc)
            }
          })
          newchoose.sort()
          that.chooseone = newchoose.join(',')
          that.challenge = newchoose.join(',')
          // if (that.challenge === that.subjectList.correct) {
          //   that.submitone()
          // }
        }
        // }
      } else {
        if (
          Number(that.subjectList.type) === 2 &&
          (Number(that.type) === 1 ||
            Number(that.type) === 3 ||
            Number(that.type) === 4)
        ) {
          if (that.subjectList.type !== '2') {
            that.chooseone = item.option_name
            console.log(that.chooseone)
            // that.submitone()
          } else {
            // 多选每日一练
            const chooseoption = that.chooseone ? that.chooseone.split(',') : []
            if (that.chooseone.indexOf(item.option_name) === -1) {
              chooseoption.push(item.option_name)
            } else {
              that.chooseone = that.chooseone.replace(item.option_name, '')
              chooseoption.splice(chooseoption.indexOf(item.option_name), 1)
            }
            const newchoose = []
            chooseoption.forEach((itemc) => {
              if (itemc) {
                newchoose.push(itemc)
              }
            })
            newchoose.sort()
            that.chooseone = newchoose.join(',')
            that.challenge = newchoose.join(',')
          }
          if (that.type === '3' || that.type === '4') {
            // 专项练习
            if (that.challenge) {
              that.subjectList.iftrue = 1
            }
          } else {
            // 每日一练
            const res = that.challenge.split(',').find((item) => {
              return that.subjectList.correct.indexOf(item) === -1
            })
            if (res) {
              that.subjectList.iftrue = 2
            } else {
              that.subjectList.iftrue = 1
            }
          }
        } else {
          // 单选 专项练习 每日一练
          that.chooseone = item.option_name
          if (item.option_name === that.subjectList.correct) {
            that.subjectList.iftrue = 1
          } else {
            that.subjectList.iftrue = 2
          }
        }
      }
      that.subjectList.chooseone = that.chooseone
      that.alltest[that.num] = that.subjectList
      console.log(that.subjectList, that.alltest[that.num], that.num, that.type, '点击数据')
      this.answerList = that.subjectList
      // this.answerList.push
      // console.log(that.alltest,334659020);
      localStorage.removeItem('alltest')
      localStorage.setItem('alltest', JSON.stringify(that.alltest)) // 本地存储题组
    },

    // 交卷 专项练习
    async challengetop () {
      const that = this
      console.log(1, that.lastsubmit)
      if (!that.lastsubmit) {
        that.submitone()
      } else {
        const params = {
          user_item_id: that.answerId
        }
        const res = await that.api.challengetop(params)
        console.log(res)
        if (res.code === 1) {
          that.iftime = false
          that.ifover = true

          that.content = '共' + that.alltest.length + '道题'
          that.truenumber = '共答对' + res.data.correct_count + '道题'
          // if (that.practicetype) {
          that.cancelText = '查看答题记录'
          // }
        }
      }
    },
    submitTopic () {
      if (Number(this.type) === 1) {
        // 每日一练
        this.daytop()
      } else if (
        Number(this.type) === 2 ||
        Number(this.type) === 3 ||
        Number(this.type) === 4
      ) {
        // 专项训练 挑战答题
        this.challengetop()
      }
    },

    // 交卷每日一练
    async daytop () {
      const that = this
      const array = []
      array.length = that.alltest.length
      const res = that.alltest.some((item) => {
        return item.iftrue === ''
      })
      if (res) {
        that.ifsubmit3 = true
      } else {
        for (let a = 0; a < that.alltest.length; a++) {
          const id = that.alltest[a].id
          const chooseone = that.alltest[a].chooseone
            .split('')
            .sort()
            .join(',')
          array[a] = {
            id: id,
            answer: chooseone
          }
        }
        const params = {
          item_id: this.answerId, // 答题的id
          answer: JSON.stringify(array)
        }
        console.log(params)
        const reslist = await this.api.submitTopic(params)
        if (reslist.code === 1) {
          that.ifover = true
          that.cancelText = '查看答题记录'
          that.content = '共' + that.alltest.length + '道题'
          that.truenumber = '共答对' + reslist.data.correct_count + '道题'
        } else {
          this.$toast(reslist.msg)
        }
      }
    },

    // 班级任务考试
    async bankao (id) {
      const that = this
      const params = {
        train_exam_id: id
      }
      const res = await that.api.kaopractice(params)
      if (res.code === 1) {
        let first_num = false
        that.alltest = res.data.list
        that.answerId = res.data.user_item_id
        that.num = 0
        that.alltest.forEach((item) => {
          item.iftrue = '' // 对错
          item.chooseone = item.user_answer ? item.user_answer : '' // 答案
          if (!item.user_answer && first_num === false) {
            first_num = true
            that.num = index
          }
        })
        if (that.alltest[that.num].type === '2') {
          const chooseoption = that.alltest[that.num].chooseone.split(',')
          const newoption = []
          chooseoption.forEach((itemc) => {
            if (itemc) {
              newoption.push(itemc)
            }
          })
          that.challenge = newoption.join(',')
        }

        localStorage.setItem('answerId', that.answerId)
        localStorage.setItem('alltest', JSON.stringify(that.alltest)) // 本地存储题组
        localStorage.setItem('testindex', that.num)
        that.subjectList = that.alltest[that.num]
      } else {
        this.$toast(res.msg)
      }
    },
    // 班级任务练习
    async banlian (id) {
      const that = this
      const params = {
        train_practise_id: id
      }
      const res = await that.api.renpractice(params)
      if (res.code === 1) {
        let first_num = false
        that.alltest = res.data.list
        that.answerId = res.data.user_item_id
        that.num = 0
        this.time = res.data.over_second * 1000 // 班级任务练习倒计时
        localStorage.setItem('time', that.time / 1000)
        that.alltest.forEach((item, index) => {
          item.iftrue = '' // 对错
          item.chooseone = item.user_answer ? item.user_answer : '' // 答案
          if (!item.user_answer && first_num === false) {
            first_num = true
            that.num = index
          }
        })
        if (that.alltest[that.num].type === '2') {
          const chooseoption = that.alltest[that.num].chooseone.split(',')
          const newoption = []
          chooseoption.forEach((itemc) => {
            if (itemc) {
              newoption.push(itemc)
            }
          })
          that.challenge = newoption.join(',')
        }
        localStorage.setItem('answerId', that.answerId)
        localStorage.setItem('alltest', JSON.stringify(that.alltest)) // 本地存储题组
        localStorage.setItem('testindex', that.num)
        that.subjectList = that.alltest[that.num]
        console.log(that.subjectList)
        that.chooseone = that.subjectList.chooseone
      }
    },

    async getpractice (id) {
      const that = this
      if (that.practicetype === '2') {
        // 班级任务练习
        that.banlian(id)
      } else {
        // 模拟考试
        const params = {
          train_exam_id: id
        }
        const res = await that.api.kaoexppractice(params)
        if (res.code === 1) {
          let first_num = false
          that.alltest = res.data.list
          that.answerId = res.data.user_item_id
          that.num = 0
          that.alltest.forEach((item) => {
            item.iftrue = '' // 对错
            item.chooseone = item.user_answer ? item.user_answer : '' // 答案
            if (!item.user_answer && first_num === false) {
              first_num = true
              that.num = index
            }
          })
          if (that.alltest[that.num].type === '2') {
            // that.challenge = Array.from(that.alltest[that.num].chooseone).sort().join("");
            // that.challenge = that.challenge.split("").join(",");
            that.subjectList = that.alltest[that.num]
            that.chooseone = that.subjectList.chooseone
            const chooseoption = that.chooseone ? that.chooseone.split(',') : []
            const options_arr = that.subjectList.options_arr
            options_arr.forEach((item, index) => {
              if (that.chooseone.indexOf(item.option_name) === -1) {
                chooseoption.push(item.option_name)
              } else {
                that.chooseone = that.chooseone.replace(item.option_name, '')
                chooseoption.splice(chooseoption.indexOf(item.option_name), 1)
              }
            })
            const newchoose = []
            chooseoption.forEach((itemc) => {
              if (itemc) {
                newchoose.push(itemc)
              }
            })
            newchoose.sort()
            that.challenge = newchoose.join(',')
          }
          localStorage.setItem('answerId', that.answerId)
          localStorage.setItem('alltest', JSON.stringify(that.alltest)) // 本地存储题组
          localStorage.setItem('testindex', that.num)
          that.subjectList = that.alltest[that.num]
        }
      }
    },
    sureyan (res) {
      console.log(res)
      if (res.code == 1) {
        this.$toast('验证成功')
        this.showyan = false
      } else {
        this.$toast('验证失败')
        this.goBack()
      }
    },
    confirmFungo () {
      this.$router.push('userinfo')
    },
    cancelyan () {
      this.goBack()
    },
    async getinfo () {
      const result = await this.api.userinfo()
      if (result.code === 1) {
        const info = result.data.info
        if (Number(info.company_check_face) === 0) {
          this.showyan = false
          this.showhavepic = false
        } else if (
          Number(info.company_check_face) === 1 &&
          Number(info.is_check_face) === 0
        ) {
          this.showhavepic = true
          this.showyan = false
        } else if (
          Number(info.company_check_face) === 1 &&
          Number(info.is_check_face) === 1
        ) {
          this.showhavepic = false
          this.showyan = true
        }
      }
    },
    // 重置专项练习
    async resetTestHandler () {
      console.log(this.currentAnswerData, 'id 和 该题数据')
      const params = JSON.parse(this.$route.query.resetTestData)
      await this.api.getresetItem({ ...params, pos_id: this.currentAnswerData.id })
      this.num = 0
      this.removeHandler()
      console.log(params, '重置参数')
      this.answerList = {}
      setTimeout(async () => {
        this.type = this.$route.query.type
        const data = this.$route.query
        await this.getlist(data)
        this.iftime = false
      }, 1000)
    },
    removeHandler () {
      localStorage.removeItem('testindex')
      localStorage.removeItem('answerId')
      localStorage.removeItem('timelo')
    }
  },
  mounted () {
    console.log(1112233)
    // 模拟考试  type=4
    // 班级任务练习  practice  practicetype=2
    // 班级任务考试  practice practicetype=1
    // 每日一练  type=1
    // 挑战答题  type=2
    // 专项练习  type=3
    // 如果缓存有参数，则表示从答题卡进入的答题，否则获取题目列表开始答题
    if (
      localStorage.getItem('testindex') &&
      localStorage.getItem('card') &&
      !this.$route.query.user_item_id
    ) {
      // 如果缓存有参数，则表示从答题卡进入的答题，否则获取题目列表开始答题
      this.type = this.$route.query.type ? this.$route.query.type : ''
      this.practicetype = this.$route.query.practicetype
        ? this.$route.query.practicetype
        : ''
      if (this.practicetype) {
        this.type = 3
      }
      if (localStorage.getItem('timelo')) {
        this.time = localStorage.getItem('timelo') * 1000
      }
      this.answerId = localStorage.getItem('answerId')
      this.num = Number(localStorage.getItem('testindex'))
      this.alltest = JSON.parse(localStorage.getItem('alltest'))
      this.subjectList = this.alltest[this.num]
      this.chooseone = this.subjectList.chooseone
      this.answerList = this.subjectList
    } else {
      if (this.$route.query.user_item_id) {
        this.practicetype = 1

        this.type = 3
        this.answerId = this.$route.query.user_item_id
        if (localStorage.getItem('testindex')) {
          this.alltest = JSON.parse(localStorage.getItem('alltest'))
          this.num = Number(localStorage.getItem('testindex'))
          this.time = localStorage.getItem('timelo') * 1000
          // localStorage.removeItem('timelo')
          this.subjectList = this.alltest[this.num]
          this.chooseone = this.subjectList.chooseone
        } else {
          // this.getinfo()
          const examlist = JSON.parse(localStorage.getItem('classrextdetail'))
          this.alltest = JSON.parse(examlist.list)
          localStorage.removeItem('classrextdetail')
          localStorage.removeItem('timelo')
          let first_num = false
          this.num = 0
          this.alltest.forEach((item, index) => {
            item.iftrue = ''
            item.chooseone = item.user_answer ? item.user_answer : ''
            if (!item.user_answer && first_num === false) {
              first_num = true
              this.num = index
            }
          })
          this.time = examlist.over_second * 1000
          this.subjectList = this.alltest[this.num]
          this.chooseone = this.subjectList.chooseone
        }
        localStorage.setItem('testindex', this.num)
        localStorage.setItem('timelo', this.time / 1000)
        localStorage.setItem('answerId', this.answerId)
        localStorage.setItem('alltest', JSON.stringify(this.alltest)) // 是这里？
      } else if (this.$route.query.practice) {
        // 班级任务练习
        this.type = 3
        this.practicetype = this.$route.query.practicetype
        this.getpractice(this.$route.query.id)
      } else if (Number(this.$route.query.type) === 2) {
        // 挑战答题
        this.type = this.$route.query.type
        this.paramschallenge = this.$route.query
        this.getlistchllen(this.paramschallenge)
        this.iftime = false
      } else {
        //  专项练习 每日一练 模拟考试
        this.type = this.$route.query.type
        this.currentAnswerData = this.$route.query
        const params = this.$route.query
        this.getlist(params)
        this.iftime = false
      }
    }
    localStorage.removeItem('card')
    // console.log(this.practicetype, '是多少？')
  }
}
</script>
<style lang="scss" scoped>
.bg3 {
  width: 100%;
  height: 5.48rem;
  position: absolute;
  top: 0;
  z-index: 0;
}
.examnavbar {
  position: fixed;
  top: 0;
  z-index: 9;
}
.examcont {
  position: relative;
  top: 0.88rem;
  z-index: 1;
}
.picker,
.backnav {
  width: 1.6rem;
}
.backnav {
  font-size: 0.48rem;
  height: 0.48rem;
}
.backnav img {
  width: 0.48rem;
  height: 0.48rem;
}
.picker {
  justify-content: flex-end;
}
.picker img {
  margin-left: 0.2rem;
}
.centernav {
  width: 3.6rem;
  margin-left: 0.6rem;
  color: rgba(51, 51, 51, 1);
  font-size: 0.34rem;
  font-weight: 700;
  justify-content: center;
}
.center_title {
  margin-right: 0.2rem;
}
.examone {
  width: 100%;
  height: auto;
  padding: 0 0.32rem;
  box-sizing: border-box;
}
.exam_title {
  color: rgba(37.19, 124.31, 255, 1);
  font-size: 0.36rem;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.52rem;
  letter-spacing: 0.01rem;
  margin: 0.48rem 0;
  word-wrap: break-word;
}
.exam_title span {
  color: grey;
  font-weight: 400;
  font-size: 0.3rem;
}
.key_one {
  width: 6.86rem;
  height: auto;
  border-radius: 0.08rem;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  color: rgba(50, 50, 51, 1);
  border: 0.01rem solid #eee;
  font-size: 0.28rem;
  font-weight: 400;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.44rem;
  padding: 0.4rem 0.32rem;
  box-sizing: border-box;
  margin-bottom: 0.24rem;
}
.img_list {
  display: flex;
}
.img_list img {
  width: 1rem;
  margin-top: 0.2rem;
  margin-right: 0.1rem;
  display: flex;
}
.active {
  background: rgba(37.19, 124.31, 255, 0.08);
  color: #257cff;
  border: 0.01rem solid #257cff;
}
.red {
  background: rgba(233, 64, 90, 0.08);
  color: #e9405a;
  border: 0.01rem solid #e9405a;
}
.nofaved {
  width: 1.28rem;
  height: 1.28rem;
  border-radius: 0.08rem;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  color: rgba(150, 151, 153, 1);
  font-size: 0.24rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 0.01rem solid #eee;
  margin: 0.8rem 0.8rem 0 0;
  float: right;
}
.faved {
  width: 1.28rem;
  height: 1.28rem;
  border-radius: 0.08rem;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  color: #257cff;
  font-size: 0.24rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 0.01rem solid #257cff;
  margin: 0.8rem 0.8rem 0 0;
  float: right;
}
.nofaved img,
.faved img {
  width: 0.44rem;
  height: 0.44rem;
}
.nexttest {
  width: 85%;
  color: rgba(37.19, 124.31, 255, 1);
  font-size: 0.28rem;
}
.nexttest img {
  width: 0.4rem;
  height: 0.4rem;
  margin: 0 0.1rem;
}
.nexttest .common_handle {
  display: flex;
}
.correct {
  width: 3.3rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  border-radius: 0.08rem;
  opacity: 1;
  background: rgba(0, 231.63, 162.14, 0.08);
  color: rgba(0, 231.63, 161.44, 1);
  font-size: 0.36rem;
  margin: 0.32rem;
}
.analy {
  color: rgba(150, 151, 153, 1);
  font-size: 0.28rem;
  font-weight: 400;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.44rem;
  padding: 0 0.32rem 0.32rem;
  box-sizing: border-box;
}
.footer {
  border-top: 0.01rem solid #eee;
  padding: 0.32rem 0.32rem;
}
.title_bottom {
  margin-bottom: 1rem;
}
.footer_button1{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #257cff;
  box-sizing: border-box;
  padding: 10px 25px;
  border-radius: 6px;
  width: 84px;
    height: 36px;
    font-size: 12px;
    color: #fff;
    /* margin-left: 66%; */
}
.footer_button2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #257cff;
  box-sizing: border-box;
  padding: 10px 25px;
  border-radius: 6px;
  width: 84px;
    height: 36px;
    font-size: 12px;
    color: #fff;
    /* margin-left: 66%; */
    position: relative;
  bottom: 30px;
  right: 0;
  z-index: 1;
}
</style>
