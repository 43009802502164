<template>
  <div class="severbody" id="captureId">
      <nav-bar class="severnav">
          <div slot="left" class="back" @click="goBack">
              <img src="../../assets/img/back3.png" alt="">
          </div>
          <div slot="center" class="userinfotitle">
              客服
          </div>
          <div slot="right" class="back"></div>
      </nav-bar>
      <div class="cont-center">
          <div class="severbottom"></div>
          <div class="sever" id="capture">
              <img src="../../assets/img/Frame 106@2x.png" alt="" class="tou">
              <div class="sever_title">客服</div>
              <div class="sever_tip">添加客服微信，一对一解决您的问题</div>
              <div class="erweima" id="erweima" ref="erweima">
                <img :src="image" alt="" class="erweima"/>
                <!-- <van-image
                width="100%"
                height="100%"
                src="../../assets/img/cardbg.png"
                /> -->

              </div>
              <div class="phone display-flex" @click="phone">
                  <img src="../../assets/img/phone2.png" alt="">
                  <div>{{severinfo.mobile}}</div>
              </div>
          </div>
          <div class="savebtn" @click="clickGeneratePicture">
              <!-- <img src="../../assets/img/load.png" alt="" class="save"> -->
              <div class="savetext">长按图片保存到本地</div>
          </div>
      </div>
  </div>

</template>
<script>
import html2canvas from 'html2canvas'
export default {
  data () {
    return {
      dataURL: '',
      severinfo: '',
      image: ''
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    phone () {
      window.location.href = 'tel:' + this.severinfo.mobile
    },
    async severfun () {
      const that = this
      const reslist = await that.api.sever()
      if (reslist.code === 1) {
        that.severinfo = reslist.data
        that.image = 'data:image/png;base64,' + reslist.data.qrcode_base64
      // console.log(that.image)
      // that.creatcanvas(that.image)
      } else {
        that.$toast(reslist.msg)
      }
    },
    // 绘制二维码图片
    // creatcanvas(image){
    //   window.onload = function() {
    //     let contents=document.getElementById('erweima')
    //     let context=contents.getContext('2d')

    //     context.drawImage(image,0,0,50,50)
    //   }
    // },
    // 生成图片
    clickGeneratePicture () {
      html2canvas(document.querySelector('#capture')).then(canvas => {
        const link = document.createElement('a') // 创建一个超链接对象实例
        const event = new MouseEvent('click') // 创建一个鼠标事件的实例
        link.download = 'Button.png' // 设置要下载的图片的名称
        link.href = canvas.toDataURL() // 将图片的URL设置到超链接的href中
        // console.log(link.href)
        link.dispatchEvent(event) // 触发超链接的点击事件
      })
    }

  },
  mounted () {
    this.severfun()
  }
}
</script>
<style lang="scss" >
  .severbody{
      background: #257CFF;
      height: 100vh;
      overflow: hidden;
  }
  .severnav .userinfotitle{
      color: #fff;
  }
  .back,.left{
      width: 1.6rem;
  }
  .cont-center{
      position: fixed;
      top: 45%;
      left: 50%;
      transform: translate(-50%,-50%);
  }
  .severbottom{
      width: 5.9rem;
      height: 9rem;
      border-radius: 0.08rem;
      opacity: 1;
      background: rgba(255,255,255,1);
      text-align: center;
      padding-bottom: 0.32rem;
      position: absolute;
      top:35%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;
  }
  .savebtn{
    width: 2.5rem;
    position: fixed;
    top: 5.5rem;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 3;
    text-align: center;
  }
  .sever{
      width: 5.9rem;
      height: 10rem;
      border-radius: 0.08rem;
      opacity: 1;
      text-align: center;
      padding-bottom: 0.32rem;
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 2;
      /* border: 1px solid red; */
  }
  .tou{
      width: 1.28rem;
      height: 1.28rem;
      border-radius: 50%;
      margin-bottom: 0.32rem;
  }
  .sever_title{
      color: rgba(34,39,43,1);
      font-size: 0.4rem;
      font-weight: 600;
      margin-bottom: 0.16rem;
  }
  .sever_tip{
      color: rgba(134.64,144.13,153,1);
      font-size: 0.24rem;
      font-weight: 400;
      margin-bottom: 0.7rem;
  }
  .erweima{
      width: 3.92rem;
      height: 3.92rem;
      margin:0 auto 0.65rem;
  }
  .phone{
      width: 3.92rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      opacity: 1;
      background: rgba(0,231.63,162.14,1);
      padding: 0.18rem 0.64rem;
      box-sizing: border-box;
      color: rgba(255,255,255,1);
      font-size: 0.32rem;
      margin: 0 auto;
  }
  .phone img{
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.32rem;
  }
  .save{
      width: 0.96rem;
      height: 0.96rem;
      /* margin: 0.14rem auto; */
  }
  .savetext{
      color: rgba(255,255,255,1);
      font-size: 0.24rem;
      font-weight: 500;
      padding-bottom: 0rem;
  }
</style>
