import { render, staticRenderFns } from "./dataDetail.vue?vue&type=template&id=356a27b5&scoped=true&"
import script from "./dataDetail.vue?vue&type=script&lang=js&"
export * from "./dataDetail.vue?vue&type=script&lang=js&"
import style0 from "./dataDetail.vue?vue&type=style&index=0&id=356a27b5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356a27b5",
  null
  
)

export default component.exports