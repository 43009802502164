<template >
    <div class="body">
        <img src="../../assets/img/bg3.png" class="bg3" alt="">
        <nav-bar class="examnavbar">
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="center display-flex" v-if="time">
                <div class="cardtitle">倒计时</div>
                <van-count-down :time="time" @change="change"/>
            </div>
            <div slot="right" class="picker"></div>
        </nav-bar>

            <div :style="{height:+height+'px'}" class="scrollcont">
            <Scroll
            style="height: 100%"
            ref="scroll"
            :scrollX="scrollX"
            :probeType=3
            :pullup="true"
            >
            <div class="cardss">
            <div class="title">答题卡</div>
            <div class="display-flex cards">
                <!-- @click="item.active=!item.active" -->
                <div
                @click="startAnswer(item,index)"
                :class="item.active?'activecard':''"
                v-for="(item,index) in card"
                :key="index"
                class="card_one">
                {{ index + 1 }}
                <template v-if="isShowAnswer == 'success' && item.chooseone === item.correct">
                    <img v-if="(item.iftrue!=''||item.user_answer)" src="../../assets/img/yes.png" class="status" alt="">
                  </template>
                  <template v-if="isShowAnswer != 'success'">
                    <img v-if="(item.iftrue!=''||item.user_answer)" src="../../assets/img/yes.png" class="status" alt="">
                  </template>
                    <!-- <img v-if="item.iftrue==2" src="../../assets/img/no.png" class="status" alt=""> -->
                    <img v-if="item.iftrue!='' && isShowAnswer =='success' && item.chooseone != item.correct" src="../../assets/img/no.png" class="status" alt="">
                </div>
            </div>
        </div>
        </Scroll>
        </div>
    </div>
</template>
<script>
import Scroll from '@/components/scroll.vue'
export default {
  components: {
    Scroll
  },
  data () {
    return {
      time: 60 * 1000,
      card: [],
      arr: [],
      number: 0,
      timelo: '',
      scrollX: false,
      height: '',
      isShowAnswer: ''
    }
  },
  created () {
    localStorage.setItem('card',true);
    this.height = document.documentElement.clientHeight
  },
  mounted () {
    if (this.$route.query.time) {
      let timelo = localStorage.getItem('timelo')
      this.time = timelo ? timelo*1000 : this.$route.query.time// 所有题
    } else {
      this.time = false
    }
    let alltest = localStorage.getItem('alltest')
    this.card = alltest ? JSON.parse(alltest) :this.$route.query.alltest // 所有题
    this.isShowAnswer = this.$route.query.isShowAnswer && this.$route.query.isShowAnswer
  },
  methods: {
    goBack () {
      this.$router.go(-1)
      localStorage.removeItem('timelo')
      localStorage.setItem('timelo', this.timelo/1000)
    },
    change (e) {
      this.timelo = (e.hours * 3600 + e.minutes * 60 + e.seconds) * 1000;
      localStorage.setItem('timelo',this.timelo/1000);
    },
    startAnswer (item, index) {
      localStorage.removeItem('testindex')
      localStorage.setItem('testindex', index)
      if (this.time) {
        localStorage.setItem('timelo', this.timelo/1000)
      }
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
    .body{
        height: 100vh;
        overflow: hidden;
        background: #f7f8fa;
    }
    .scrollcont{
        width: 100%;
        position: fixed;
        left: 50%;
        transform: translate(-50%,0);
        top: 1rem;
        overflow: hidden;
        padding-bottom: 0.32rem;
    }
    .bg3{
        width: 100%;
        height: 5.48rem;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    .examnavbar{
        position: fixed;
        top: 0;
        z-index: 9;
    }
    .picker,.back{
        width: 1.6rem;
    }
    .back img{
        width: 0.48rem;
        height: 0.48rem;
    }
    .center{
        color: rgba(51,51,51,1);
        font-size: 0.34rem;
        font-weight: 700;
        justify-content: center;
    }
    .cardtitle{
        margin-right: 0.2rem;
    }
    .cardss{
        width: 100%;
        height: auto;
        padding:0.0 0.32rem 0.48rem;
        box-sizing: border-box;
    }
    .title{
        color: rgba(50,50,51,1);
        font-size: 0.48rem;
        font-weight: 600;
    }
    .cards{
        flex-wrap: wrap;
        margin-top: 0.4rem;
        padding-bottom: 0.32rem;
    }
    .card_one{
        width: 1.52rem;
        height: 1.52rem;
        border-radius: 0.08rem;
        opacity: 1;
        text-align: center;
        line-height: 1.52rem;
        margin:0 0.2rem 0.26rem 0;
        font-size: 0.72rem;
        font-weight: 600;
        background: rgba(255,255,255,1);
        color: #000;
        border: 0.01rem solid #fff;
        position: relative;
    }
    .status{
        position: absolute;
        top: 0.01rem;
        left: 0.01rem;
        width:0.4rem;
        height: 0.4rem;
    }
    .card_one:nth-child(4n){
        margin-right: 0;
    }
    .activecard{
        color: rgba(37.19,124.31,255,1);
        border: 0.01rem solid #257CFF;
        background: rgba(37.19,124.31,255,0.08);
    }

</style>
