<template>
    <div>
        <nav-bar class="noticebar">
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">
                消息通知
            </div>
            <div slot="right" class="result"></div>
        </nav-bar>
        <div class="notices"  :style="{height:+height+'px'}">
          <Scroll
            style="height: 100%;"
            ref="scroll"
            :probeType="3"
            :scrollX="scrollX"
            :pullup="true"
            @scrollEnd="scrollEnd"
            >
            <div style="padding-bottom: 0.52rem; box-sizing: border-box;">
            <router-link :to="{path:'/noticeDetail',query:{id:item.id}}" v-for="(item,index) in notices" :key="index" class="notice_item display-flex-between">
                <img src="../../assets/img/notice.png" alt="">
                <div style="flex: 1;">
                    <div class="notice_title display-flex-between">
                        <div>平台通知</div>
                        <div style="color:rgba(150,151,153,1); ">{{item.create_time}}</div>
                    </div>
                    <div class="notice_detail p-2">
                        {{item.content}}
                    </div>
                </div>
            </router-link>
            </div>
            </Scroll>
        </div>
    </div>
</template>
<script>
import Scroll from '@/components/scroll.vue'
export default {
  components: {
    Scroll
  },
  data () {
    return {
      scrollX: false,
      notices: [],
      page: 1,
      ifmore: false,
      height: ''
    }
  },
  mounted () {
    this.height = document.documentElement.clientHeight - 20
    console.log(document.documentElement.clientHeight)
    this.getlist()
  },
  methods: {
    scrollEnd () {
      if (this.ifmore) {
        ++this.page
        this.getlist()
      } else {
        this.$toast({ message: '没有更多了', duration: 1000 })
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    async getlist () {
      const params = {
        page: this.page,
        page_num: 20
      }
      const res = await this.api.getnotice(params)
      if (res.code === 1) {
        const list = res.data.list.data
        if (list.length === 0) {
          this.ifmore = false
          this.notices = this.reports.concat(list)
        } else {
          this.ifmore = true
          this.notices = this.page === 1 ? list : this.notices.concat(list)
        }
      }
    }
  }
}
</script>
<style lang="scss">
    body{
        background: #fff;
    }
    .noticebar{
      position: fixed;
      top: 0;
      background: #fff;
      z-index: 2;
    }
    .notices{
        width: 100%;
        /* height: 15rem; */
        padding: 0 0.32rem;
        box-sizing: border-box;
        background-color: #ffffff;
        margin-top: 0.88rem;
    }
    .notice_item{
        width: 100%;
        height: auto;
        padding: 0.32rem 0;
        box-sizing: border-box;
        align-items: flex-start;
        border-bottom: 0.01rem solid #eee;
    }
    .notice_item img{
        width: 0.88rem;
        height: 0.88rem;
        border-radius: 50%;
        margin-right: 0.32rem;
    }
    .notice_title{
        color: rgba(50,50,51,1);
        font-size: 0.28rem;
    }
    .notice_detail{
        color: rgba(50,50,51,1);
        font-size: 0.24rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.44rem;
        margin-top: 0.16rem;
        word-break: break-all;
    }
</style>
