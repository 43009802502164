<template >
    <div >
        <img src="../../assets/img/bg3.png" class="bg3" alt="">
        <nav-bar class="examnavbar">
            <div slot="left" class="backnav" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="center display-flex" ></div>
            <div slot="right" ></div>
        </nav-bar>
        <div class="examcont">
            <div class="examone">
                <div class="exam_title" >{{ subjectList.title }}
                  <span v-if="subjectList.type==2">(多选)</span>
                  <span v-if="subjectList.type==3">(判断)</span>
                  <span v-if="subjectList.type==1">(单选)</span>

                </div>

                <!--多选  -->
                <div v-if="subjectList.type==2&&!ifsubmit">
                  <div
                  v-for="(item,index) in subjectList.options_arr"
                  :key="index">
                <div v-if="item.value"
                  class="key_one"
                  :class="chooseone.indexOf(item.option_name)==-1?'':'active'"
                  @click="option(item,index)">
                  <div>{{item.option_name}} . {{item.value}}</div>
                  <div class="img_list" v-if="item.pic">
                    <template v-for="(item_pic,index_pic) in item.pic_arr">
                      <img :src="item_pic" :key="index_pic" alt=""/>
                    </template>
                  </div>
                </div></div>
                </div>
                <!--多选  -->
                <div v-if="subjectList.type==2&&ifsubmit">
                  <div
                  v-for="(item,index) in subjectList.options_arr"
                  :key="index">
                <div v-if="item.value"
                  class="key_one"
                  :class="challenge.indexOf(item.option_name)==-1?'':subjectList.correct.indexOf(item.option_name)==-1?'red':'active'"
                  @click="option(item,index)">
                  <div>{{item.option_name}} . {{item.value}}</div>
                  <div class="img_list" v-if="item.pic">
                    <template v-for="(item_pic,index_pic) in item.pic_arr">
                      <img :src="item_pic" :key="index_pic" alt=""/>
                    </template>
                  </div>
                </div></div>
                </div>
                <!--单选  -->
                <div v-if="subjectList.type!=2&&!ifsubmit">
                  <div
                  v-for="(item,index) in subjectList.options_arr"
                  :key="index">
                  <div v-if="item.value"
                  class="key_one"
                  :class="chooseone===item.option_name?'active':''"
                  @click="option(item,index)">
                    <div>{{item.option_name}} . {{item.value}}</div>
                    <div class="img_list" v-if="item.pic">
                      <template v-for="(item_pic,index_pic) in item.pic_arr">
                        <img :src="item_pic" :key="index_pic" alt=""/>
                      </template>
                    </div></div>
                  </div>
                </div>
                <!--单选  -->
                <div v-if="subjectList.type!=2&&ifsubmit">
                  <div
                  v-for="(item,index) in subjectList.options_arr"
                  :key="index">
                  <div v-if="item.value"
                  class="key_one"
                  :class="subjectList.correct.indexOf(item.option_name)!==-1?'active':chooseone.indexOf(item.option_name)==-1?'':'red'"
                  @click="option(item,index)">
                    <div>{{item.option_name}} . {{item.value}}</div>
                    <div class="img_list" v-if="item.pic">
                      <template v-for="(item_pic,index_pic) in item.pic_arr">
                        <img :src="item_pic" :key="index_pic" alt=""/>
                      </template>
                    </div></div>
                  </div>
                </div>
                <div class="line"></div>
                <div class="correct" v-if="ifsubmit">正确答案：{{ subjectList.correct }}</div>
                <div class="analy" v-if="ifsubmit">解析: {{subjectList.remark}}</div>
                <div class="footer display-flex-between" >
                    <div  class="footer_button" @click="submitTopic">交卷</div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
import model from '@/components/model'
export default {
  name: 'examDetail',

  data () {
    return {
      overicon: require('@/assets/img/over2.png'),
      active: 0,
      subjectList: {}, // 每一题数据
      chooseone: '',
      challenge: '', // 挑战答题答案
      wrongparams: '',
      ifsubmit: false,
      error_id: ''
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },

    // 点击选项
    async option (item, index) {
      const that = this
      if (Number(that.subjectList.type) === 2) { // 多选
        if (that.subjectList.correct.indexOf(item.option_name) === -1) {
          this.subjectList.iftrue = true
        }
        if (that.chooseone.indexOf(item.option_name) === -1) {
          that.chooseone = that.chooseone + item.option_name
        } else {
          that.chooseone = that.chooseone.replace(item.option_name, '')
        }
        console.log(that.chooseone)
        that.challenge = Array.from(that.chooseone).sort().join('')
        that.challenge = that.challenge.split('').join(',')
      } else { // 单选
        that.chooseone = item.option_name
      }
    },

    // 错题提交
    async submitTopic () {
      const that = this
      if ((Number(that.subjectList.type) === 2 && that.challenge === '') || (Number(that.subjectList.type) !== 2 && this.chooseone === '')) {
        this.$toast('请选择题目选项')
      } else {
        console.log(that.challenge)
        const params = {
          error_id: that.error_id,
          answer: that.subjectList.type === '2' ? that.challenge : that.chooseone
        }
        const res = await this.api.wrongtop(params)
        if (res.code === 1) {
          this.$toast(res.msg)
          this.ifsubmit = true
        } else {
          // this.$toast(res.msg)
        }
      }
    },

    async getwrongdetail (params) {
      const res = await this.api.wrongdetail(params)
      if (res.code === 1) {
        this.subjectList = res.data.detail
        this.error_id = res.data.error_id
      } else {
        this.$toast(res.msg)
      }
    }

  },

  mounted () {
    this.wrongparams = this.$route.query
    this.getwrongdetail(this.wrongparams)
  }
}

</script>
<style lang="scss" scoped>
    .bg3{
        width: 100%;
        height: 5.48rem;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    .examnavbar{
        position: fixed;
        top: 0;
        z-index: 9;
    }
    .examcont{
        position: relative;
        top: 0.88rem;
        z-index: 1;
    }
    .picker,.backnav{
        width: 1.6rem;
    }
    .backnav{
        font-size: 0.48rem;
        height: 0.48rem;
    }
    .backnav img{
        width: 0.48rem;
        height: 0.48rem;
    }
    .picker{
        justify-content: flex-end;
    }
    .picker img{
        margin-left: 0.2rem;
    }
    .centernav{
        width: 3.6rem;
        margin-left: 0.6rem;
        color: rgba(51,51,51,1);
        font-size: 0.34rem;
        font-weight: 700;
        justify-content: center;
    }
    .center_title{
        margin-right: 0.2rem;
    }
    .examone{
        width: 100%;
        height: auto;
        padding: 0 0.32rem;
        box-sizing: border-box;
    }
    .exam_title{
        color: rgba(37.19,124.31,255,1);
        font-size: 0.36rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.52rem;
        letter-spacing: 0.01rem;
        margin: 0.48rem 0;
    }
    .exam_title span{
      color: grey;
      font-weight: 400;
      font-size: 0.30rem;
    }
    .key_one{
        width: 6.86rem;
        height: auto;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        color: rgba(50,50,51,1);
        border: 0.01rem solid #eee;
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.44rem;
        padding: 0.4rem 0.32rem;
        box-sizing: border-box;
        margin-bottom: 0.24rem;
    }
    .img_list {
      display: flex;
    }
    .img_list img{
      width: 1rem;
      margin-top: .2rem;
      margin-right: .1rem;
      display: flex;
    }
    .active{
        background: rgba(37.19,124.31,255,0.08);
        color:#257CFF ;
        border: 0.01rem solid #257CFF;
    }
    .red{
        background: rgba(233,64,90,0.08);
        color:#E9405A ;
        border: 0.01rem solid #E9405A;
    }
    .nofaved{
        width: 1.28rem;
        height: 1.28rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        color: rgba(150,151,153,1);
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 0.01rem solid #eee;
        margin: 0.8rem 0.8rem 0 0;
        float: right;
    }
    .faved{
        width: 1.28rem;
        height: 1.28rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        color: #257CFF;
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 0.01rem solid #257CFF;
        margin: 0.8rem 0.8rem 0 0;
        float: right;
    }
    .nofaved img,.faved img{
        width: 0.44rem;
        height: 0.44rem;
    }
    .nexttest{
        width: 85%;
        color: rgba(37.19,124.31,255,1);
        font-size: 0.28rem;
    }
    .nexttest img{
        width:0.4rem;
        height: 0.4rem;
        margin: 0 0.1rem;
    }
    .correct{
        width: 3.3rem;
        height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(0,231.63,162.14,0.08);
        color: rgba(0,231.63,161.44,1);
        font-size: 0.36rem;
        margin: 0.32rem;
    }
    .analy{
        color: rgba(150,151,153,1);
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.44rem;
        padding:0 0.32rem 0.32rem;
        box-sizing: border-box;
    }
    .footer{
      border-top: 0.01rem solid #eee;
      padding: 0.32rem 0.32rem;
    }
</style>
