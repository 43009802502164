<template>
  <div class="wrap">
      <iframe :src="src" width="100%" height="100%"></iframe>
  </div>
</template>
<script>
export default {
  name: 'pdf',
  data () {
    return {
      src: ''
    }
  },
  mounted () {
    this.src = this.$route.query.src
    this.loadpdf()
  },
  methods: {
    loadpdf () {
      const baseurl = this.src
      const psrc = baseurl + '?r=' + new Date()
      this.psrc = '././plugin/pdf/web/viewer.html?file=' + encodeURIComponent(psrc) + '.pdf'
    }
  }
}
</script>
<style>
  .wrap{
      width: 100%;
      height: 100vh;
}
</style>
