<template>
    <div>
        <nav-bar class="navbar">
            <div slot="left" class="back">
                <div  @click="goBack">
                    <img src="../../assets/img/back.png" alt="" class="backimg"/>
                </div>
            </div>
            <div slot="center" class="navtitle">
                <div>文本课程</div>
            </div>
            <div slot="right"  class="picker display-flex-around" @click="poslist">
                <div>{{infoData?infoData:postname}}</div>
                <img src="../../assets/img/xia.png" alt="" :class="isShow?'rotate':''">
            </div>
        </nav-bar>
        <fil-ter :show="isShow" :posts="posts" @infoType="infoType"></fil-ter>
        <div class="video_list">
          <div class="noresult" v-if="testclass.length == 0">暂无文本课程</div>
          <van-list
            v-else
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="scrollEnd">
            <router-link :to="{path:'/TextClassDetail', query:{id:item.id}}" class="class display-flex-between" v-for="(item,index) in testclass" :key="index">

              <div><van-image
              width="100%"
              height="100%"
              fit="cover"
              :src="item.image"
            /></div>
              <!-- <img :src="item.image" mode="widthFix" > -->
                <div class="class_detail">
                    <div style="color: #000;" class="p-1">{{ item.name }}</div>
                    <div class="class_info p-2">{{item.intro}}</div>

                </div>
            </router-link>
          </van-list>
        </div>
    </div>

</template>
<script>
import '@/assets/style/list3.css'
import '@/assets/style/classlist.css'
import '@/assets/style/common.css'
import filTer from '@/components/filter.vue'
export default {
  components: {
    filTer
  },
  data () {
    return {
      scrollX: false,
      pulldown: true,
      pullup: false,
      testclass: [],
      posts: [],
      isShow: false,
      infoData: '',
      postname: '',
      pId: '',
      page: 1, // 页码
      pageNum: 20, // 每页条数
      ifmore: false, // 页码数据(第二页是否有数据)
      loading: false,
      finished: false
    }
  },
  created () {
    this.pId = this.$route.query.id
    this.textList()
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    scrollEnd () {
      if (this.ifmore) {
        this.loading = true;
        ++this.page
        this.textList()
      }
    },
    // 文本课程列表
    async textList () {
      const params = {
        pos_id: this.pId,
        page: this.page,
        page_num: this.pageNum
      }
      const reslist = await this.api.textList(params)
      if (reslist.code === 1) {
        // console.log(reslist,'文本课程');
        const res = reslist.data.list.data // 文本课程的列表
        if (res.length === 0) {
          this.ifmore = false
          this.finished = true;
          this.testclass = this.testclass.concat(res) // 合并数组
        } else {
          this.ifmore = true
          this.testclass = this.page === 1 ? res : this.testclass.concat(res)
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$toast(reslist.msg)
      }
    },

    // 工种列表接口
    async poslist () {
      this.isShow = !this.isShow
      const reslist = await this.api.pos()
      if (reslist.code === 1) {
        // console.log(reslist);
        this.posts = reslist.data.list
      } else {
        this.$toast(reslist.msg)
      }
    },

    infoType (infoType, itemId) {
      this.isShow = false
      this.infoData = infoType
      this.pId = itemId
      this.page = 1
      this.testclass = []
      this.textList()
    }
  },
  mounted () {
    if (localStorage.getItem('postname', this.postname)) {
      this.postname = localStorage.getItem('postname', this.postname)
      this.pId = localStorage.getItem('pId', this.pId)
    }
  }
}
</script>
<style scoped lang="scss">
    .navbar{
        background-color: #fff;
    }
    .back{
        width: 1.5rem;
    }
    .back{
        font-size: 0.32rem;
    }
    .picker{
        width: 1.5rem;
        justify-content: flex-end;
        // font-size: 0.32rem;
    }
    .video_list {
      width: 100%;
      padding: 0 .32rem;
      box-sizing: border-box;
      margin-top: 1rem;
    }
</style>
