<template >
    <div class="body">
        <nav-bar>
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back3.png" alt="">
            </div>
            <div slot="center" class="center">
                模拟考试
            </div>
            <div slot="right" class="result"></div>
        </nav-bar>
        <div class="examDetail">
            <img src="../../assets/img/examdetail.png" alt="" class="detailbg">
            <div class="detail">
                <div class="detail_title p-2">{{detail.name}}</div>
                <div class="detail_one display-flex">
                    <span>考试时长：</span>
                    <span style="color: #257CFF;font-weight: 600;">{{detail.spend_time}}分</span>
                </div>
                <div class="detail_one display-flex">
                    <span>考试题数：</span>
                    <span style="color: #257CFF;font-weight: 600;">{{detail.quesion_num}}题</span>
                </div>
                <div class="detail_one display-flex">
                    <span>合格标准：</span>
                    <span style="color: #257CFF;font-weight: 600;">{{detail.score_pass}}分</span>
                </div>
                <!-- <div class="detail_rule">
                    <div>问卷规则</div>
                    <div class="detail_rule_cont" v-html="detail.content"></div>
                </div> -->
            </div>
            <router-link :to="{path:'/examDetail',query: {specialId:this.id,type:4,id:this.posid}}" tag="div">
                <div class="beginexam">开始考试</div>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      id: '',
      detail: '',
      trainid: '',
      is_exp: 3,
      posid: ''
    }
  },
  mounted () {
    this.id = Number(this.$route.query.id)
    this.posid = Number(this.$route.query.posid)
    // this.trainid = this.$route.query.trainid
    // this.is_exp = this.$route.query.is_exp
    this.getDetail()
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async getDetail () {
      const params = {
        paper_id: this.id
      }
      const res = await this.api.monidetail(params)
      if (res.code === 1) {
        this.detail = res.data.list
      }
    }
  }
}
</script>
<style lang="scss" scoped>
    .body{
        overflow: hidden;
        height:100vh ;
        background: linear-gradient(180.25deg, rgba(37.19,176.59,255,1) 0%, rgba(37.19,124.31,255,1) 70%);
    }
    .center{
        color: rgba(255,255,255,1);
        font-size: 0.34rem;
        font-weight: 700;
        text-align: center;
    }
    .examDetail{
        width: 100%;
        height: 100%;
        padding: 0.8rem;
        box-sizing: border-box;
    }
    .detailbg{
        width: 5.9rem;
        height: 7.86rem;
        position: fixed;
        top: 1.8rem;
        left: 50%;
        transform: translate(-50%,0%);
        z-index: 1;
    }
    .detail{
        width: 5.9rem;
        height: 7.86rem;
        position: fixed;
        z-index: 2;
        top: 1.8rem;
        left: 50%;
        transform: translate(-50%,0%);
        padding: 0.48rem 0.4rem;
        box-sizing: border-box;
    }
    .detail_title{
        color: rgba(50,50,51,1);
        font-size: 0.36rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.52rem;
        margin-bottom: 0.24rem;
    }
    .detail_one{
        width: 100%;
        height: auto;
        padding: 0.24rem 0;
        box-sizing: border-box;
        border-bottom: 0.01rem solid #f0eeee;
        font-size: 0.28rem;
    }
    .detail_rule{
        width: 5.1rem;
        height: 1.86rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: #EFF2F6;
        margin-top: 0.3rem;
        padding: 0.16rem;
        box-sizing: border-box;
        font-size: 0.24rem;
    }
    .detail_rule_cont{
        font-size: 0.22rem;
        color: rgba(100,101,102,1);
        line-height: 0.36rem;
        margin-top: 0.16rem;
    }
    .beginexam{
        width: 6.22rem;
        height: 1.12rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        border-radius: 0.2rem;
        line-height: 1.12rem;
        text-align: center;
        color: rgba(37.19,124.31,255,1);
        font-size: 0.32rem;
        font-weight: 600;
        position: fixed;
        bottom: 15%;
        left: 50%;
        transform: translate(-50%,0);
    }
</style>
