<template>
  <div>
    <nav-bar class="reportnav">
      <div slot="left" class="back" @click="goBack">
        <img src="../../assets/img/back.png" alt="" />
      </div>
      <div slot="center" class="userinfotitle">学习报告</div>
      <div
        slot="right"
        class="picker display-flex-around"
        @click="isShow = !isShow"
      >
        <div>{{ postname }}</div>
      </div>
    </nav-bar>
    <fil-ter :show="isShow"></fil-ter>
    <img src="../../assets/img/recordbg.png" alt="" class="bg" />
    <div class="reportnumber">
      <div class="userpic">
        <van-image
          width="100%"
          height="100%"
          fit="cover"
          :src="user.avatar"
          :round="true"
        />
      </div>
      <div class="reportnumber_number">积分{{ user.score }}</div>
    </div>
    <div class="notBox"></div>

    <div class="data_bottom">
      <van-list @load="scrollEnd">
        <div class="reports">
          <div
            v-for="(item, index) in reports"
            :key="index"
            class="report_item display-flex-between"
          >
            <div>
              <div class="report_item_info p-2">
                <!-- 类型:1=每日一练,2=挑战答题,3=专项练习,4=培训计划考试.5=培训计划练习,6=模拟考试[未完待续……] -->
                <span style="color: rgba(37.19, 124.31, 255, 1)"
                  >({{
                    item.type == 1
                      ? "每日一练"
                      : item.type == 2
                      ? "挑战答题"
                      : item.type == 3
                      ? "专项练习"
                      : item.type == 4
                      ? "培训计划考试"
                      : item.type == 5
                      ? "培训计划练习"
                      : item.type == 6
                      ? "模拟考试"
                      : "课程"
                  }})</span
                >
                {{ item.memo }}
              </div>
              <div class="report_item_time">{{ item.createtime }}</div>
            </div>
            <div class="report_item_num">+{{ item.score }}</div>
          </div>
        </div>
      </van-list>
    </div>
    <router-link to="/studyreportDetail">
      <div class="footer">
        <div class="footer_button">生成报告</div>
      </div>
    </router-link>
  </div>
</template>
<script>
import filTer from "@/components/filter";
export default {
  components: {
    filTer
  },
  data() {
    return {
      postname: "",
      scrollX: false,
      reports: [],
      isShow: false,
      user: "",
      studyposts: ["全部", "视频课", "考试", "视频课", "考试"],
      page: 1,
      pagenum: 20,
      ifmore: false,
      height: "",
      top: "",
    };
  },
  mounted() {
    /* eslint-disable-line no-unused-vars */
    var ranksheight = document.documentElement.querySelector(".bg").height;
    /* eslint-disable-line no-unused-vars */
    var footerheight = document.documentElement.querySelector(".footer").height;
    this.height = document.documentElement.clientHeight - ranksheight - 140;
    this.top = ranksheight + 30;
    console.log(ranksheight, this.height);
    this.getreport();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    scrollEnd() {
      if (this.ifmore) {
        ++this.page;
        this.getreport();
      }
      // else {
      //   this.$toast({ message: "没有更多了", duration: 1000 });
      // }
    },
    async getreport() {
      const params = {
        page: this.page,
        page_num: this.pagenum,
      };
      const res = await this.api.scorereport(params);
      if (res.code === 1) {
        const list = res.data.list.data;
        if (list.length === 0) {
          this.ifmore = false;
          this.reports = this.page === 1 ? list : this.reports.concat(list);
        } else {
          this.ifmore = true;
          this.reports = this.page === 1 ? list : this.reports.concat(list);
        }
      }
      const res1 = await this.api.report(params);
      this.user = res1.data.report.user;
    },
  },
};
</script>
<style scoped lang="scss">
.reportnav {
  background: #fff;
  z-index: 2;
  position: fixed;
  top: 0;
  /* border: 1px solid red; */
}
.reportnav .picker,
.reportnav .back {
  width: 1rem;
  margin: 0;
}
.reportnav .userinfotitle {
  text-align: center;
  flex: 1;
}
.bg {
  width: 100%;
  height: 4.16rem;
  top: 0.88rem;
  z-index: 2;
  position: fixed;
}
.userpic {
  width: 1.28rem;
  height: 1.28rem;
  opacity: 1;
  border-radius: 50%;
  border: 0.05rem solid #fff;
  margin: 0.87rem auto 0;
}
.reportnumber_number {
  width: 2.3rem;
  height: 0.64rem;
  border-radius: 0.32rem;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  color: rgba(37.19, 124.31, 255, 1);
  font-size: 0.28rem;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: center;
  line-height: 0.64rem;
  margin: 0 auto;
}
.reports {
  margin-top: 0.48rem;
}
.reportnumber {
  position: fixed;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0);
  top: 1.4rem;
  z-index: 2;
}
.report_item {
  width: 100%;
  height: 100%;
  padding: 0.32rem;
  box-sizing: border-box;
  border-bottom: 0.01rem solid #eee;
}
.report_item_info {
  font-size: 0.28rem;
  font-weight: 600;
}
.report_item_time {
  color: rgba(150, 151, 153, 1);
  font-size: 0.24rem;
  margin-top: 0.24rem;
}
.report_item_num {
  color: rgba(37.19, 124.31, 255, 1);
  font-size: 0.4rem;
  font-weight: 600;
  margin-left: 0.12rem;
}

.picker {
  width: 1.2rem;
  margin-left: 0.6rem;
  color: rgba(50, 50, 51, 1);
  font-size: 0.28rem;
  justify-items: end;
  /* border: 0.01px solid red; */
}
.picker img {
  width: 0.32rem;
  height: 0.32rem;
  margin-left: 0.05rem;
  transition: all 0.2s ease;
}
.rotate {
  transform: rotate(180deg);
}
.notBox {
  margin-top: 4.46rem;
  height: 0.01rem;
}
.data_bottom {
  margin-bottom: 1.35rem;
}
</style>
