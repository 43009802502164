<template >
    <div style="background-color: #f7f8fa;">
        <nav-bar>
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">
                消息通知
            </div>
            <div slot="right" class="result"></div>
        </nav-bar>
        <div class="containernotice">
             <div class="noticeone display-flex">
                 <img src="../../assets/img/notice.png" alt="" class="noticeimg">
                 <div class="noticetext">
                    {{detail.content}}
                 </div>
             </div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      id: '',
      detail: ''
    }
  },
  mounted () {
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.getdetail()
  },
  methods: {
    async getdetail () {
      const params = {
        id: this.id
      }
      const res = await this.api.getnoticedetail(params)
      if (res.code === 1) {
        this.detail = res.data.detail
      }
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style>
    .containernotice{
        width: 100%;
        height: 100vh;
        background: #f7f8fa;
        padding:1rem 0.32rem;
        box-sizing: border-box;
    }
    .noticeimg{
        width:0.72rem;
        height: 0.72rem;
        margin-right: 0.16rem;
    }
    .noticeone{
        align-items: flex-start;
    }
    .noticetext{
        width: 5.42rem;
        height: auto;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        font-size: 0.3rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.44rem;
        padding: 0.24rem;
        box-sizing: border-box;
        word-break: break-all;

    }
</style>
