<template >
    <div class="body">
        <img src="../../assets/img/bg3.png" class="bg3" alt="">
        <nav-bar class="examnavbar">
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="center display-flex" >

            </div>
            <div slot="right" class="picker"></div>
        </nav-bar>
        <div class="card">
            <div :style="{height:+height+'px'}" class="scrollcont">
            <Scroll
            style="height: 100%"
            ref="scroll"
            :scrollX="scrollX"
            :probeType=3
            :pullup="true"
            >
                <div class="cardss">
                    <div class="title">答题卡</div>
                    <div class="display-flex cards">
                        <div
                        @click="startAnswer(item,index)"
                        :class="item.active?'activecard':''"
                        v-for="(item,index) in card"
                        :key="index"
                        class="card_one">
                        {{ index + 1 }}
                            <img v-if="item.is_correct==1" src="../../assets/img/yes.png" class="status" alt="">
                            <img v-else src="../../assets/img/no.png" class="status" alt="">
                        </div>
                    </div>
                </div>
            </Scroll>
            </div>
        </div>
    </div>
</template>
<script>
import Scroll from '@/components/scroll.vue'
export default {
  components: {
    Scroll
  },
  data () {
    return {
      time: 60 * 1000,
      card: [],
      arr: [],
      number: 0,
      scrollX: false,
      timelo: '',
      height: ''
    }
  },
  created () {
    this.height = document.documentElement.clientHeight
  },
  mounted () {
    this.card = JSON.parse(localStorage.getItem('alltest')) // 所有题
    console.log(this.card)
  },
  methods: {
    goBack () {
      this.$router.push('/recorddetail')
    },
    startAnswer (item, index) {
      localStorage.removeItem('testindex')
      localStorage.setItem('testindex', index)
      console.log(index + 1, localStorage.getItem('testindex'))
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
    .body{
        height: 100vh;
        overflow: hidden;
        background: #f7f8fa;
    }
    .scrollcont{
        width: 100%;
        position: fixed;
        left: 50%;
        transform: translate(-50%,0);
        top: 1rem;
        overflow: hidden;
        padding-bottom: 0.32rem;
    }
    .bg3{
        width: 100%;
        height: 5.48rem;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    .examnavbar{
        position: fixed;
        top: 0;
        z-index: 9;
    }
    .cardss{
        width: 100%;
        height: auto;
        padding:0.0 0.32rem 0.48rem;
        box-sizing: border-box;
    }
    .card{
        position: relative;
        top: 0.88rem;
        z-index: 1;
    }
    .picker,.back{
        width: 1.6rem;
    }
    .back img{
        width: 0.48rem;
        height: 0.48rem;
    }
    .center{
        color: rgba(51,51,51,1);
        font-size: 0.34rem;
        font-weight: 700;
        justify-content: center;
    }
    .cardtitle{
        margin-right: 0.2rem;
    }
    .card{
        width: 100%;
        height: auto;
        padding:0.48rem 0.32rem;
        box-sizing: border-box;
    }
    .title{
        color: rgba(50,50,51,1);
        font-size: 0.48rem;
        font-weight: 600;
    }
    .cards{
        flex-wrap: wrap;
        margin-top: 0.4rem;
    }
    .card_one{
        width: 1.52rem;
        height: 1.52rem;
        border-radius: 0.08rem;
        opacity: 1;
        text-align: center;
        line-height: 1.52rem;
        margin:0 0.2rem 0.26rem 0;
        font-size: 0.72rem;
        font-weight: 600;
        background: rgba(255,255,255,1);
        color: #000;
        border: 0.01rem solid #fff;
        position: relative;
    }
    .status{
        position: absolute;
        top: 0.01rem;
        left: 0.01rem;
        width:0.4rem;
        height: 0.4rem;
    }
    .card_one:nth-child(4n){
        margin-right: 0;
    }
    .activecard{
        color: rgba(37.19,124.31,255,1);
        border: 0.01rem solid #257CFF;
        background: rgba(37.19,124.31,255,0.08);
    }

</style>
