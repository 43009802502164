<template>
  <div class="">
    <nav-bar class="wrongnavbar">
      <div slot="left" class="back" @click="goBack">
        <img src="../../assets/img/back.png" alt="" />
      </div>
      <div slot="center" class="userinfotitle">我的错题</div>
      <div slot="right" class="result"></div>
    </nav-bar>
    <div class="tabs">
      <tabs :tabs="tabs" @choose="slideChange" :active="active"></tabs>
    </div>
    <div class="line"></div>
    <div>
      <swiper-list @slideChange="slideChange" :active="active" ifthird="true">
        <div slot="first" class="error_list" style="margin-top: 0.2rem">
          <van-list @load="scrollEnd">
            <div class="noresult" v-if="wrongs.length == 0">暂无错题</div>
            <div v-else class="wrongs">
              <div
                v-for="(item, index) in wrongs"
                :key="index"
                class="wrongitem"
                @click="toDetail(item)"
              >
                <img
                  src="../../assets/img/wrongbg.png"
                  alt=""
                  class="wrongbg"
                />
                <div class="wrong_detail display-flex-between">
                  <div class="wrong_title_box">{{ item.title }}</div>
                  <img
                    src="../../assets/img/laji.png"
                    alt=""
                    class="laji"
                    @click.stop="del(item)"
                  />
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div slot="second" class="error_list" style="margin-top: 0.2rem">
          <van-list @load="scrollEnd">
            <div class="noresult" v-if="wrongs.length == 0">暂无错题</div>
            <div v-else class="wrongs">
              <div
                v-for="(item, index) in wrongs"
                :key="index"
                class="wrongitem"
                @click="toDetail(item)"
              >
                <img
                  src="../../assets/img/wrongbg.png"
                  alt=""
                  class="wrongbg"
                />
                <div class="wrong_detail display-flex-between">
                  <div class="wrong_title_box">{{ item.title }}</div>
                  <img
                    src="../../assets/img/laji.png"
                    alt=""
                    class="laji"
                    @click.stop="del(item)"
                  />
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div slot="third" class="error_list" style="margin-top: 0.2rem">
          <van-list @load="scrollEnd">
            <div class="noresult" v-if="wrongs.length == 0">暂无错题</div>
            <div class="wrongs" v-else>
              <div
                v-for="(item, index) in wrongs"
                :key="index"
                class="wrongitem"
                @click="toDetail(item)"
              >
                <img
                  src="../../assets/img/wrongbg.png"
                  alt=""
                  class="wrongbg"
                />
                <div class="wrong_detail display-flex-between">
                  <div class="wrong_title_box">{{ item.title }}</div>
                  <img
                    src="../../assets/img/laji.png"
                    alt=""
                    class="laji"
                    @click.stop="del(item)"
                  />
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </swiper-list>
    </div>
  </div>
</template>
<script>
import swiperList from "@/components/swiper.vue";
import tabs from "@/components/tabs.vue";
export default {
  name: "wrong",
  components: {
    tabs,
    swiperList
  },
  data() {
    return {
      scrollX: false,
      tabs: ["单选题", "多选题", "判断题"],
      wrongs: [],
      active: 0,
      page: 1,
      pagenum: 8,
      ifmore: false,
      type: 1,
    };
  },
  watch: {
    active() {
      this.type = Number(this.active) + 1;
      this.page = 1;
      this.wrongs = [];
      this.getlist();
    },
  },
  created() {
    this.getlist();
  },
  methods: {
    async del(item) {
      const params = {
        item_id: item.item_id,
        type: item.type,
        item_type: item.item_type,
      };
      const res = await this.api.delwrong(params);
      if (res.code === 1) {
        this.$toast("删除成功");
        this.getlist();
      } else {
        this.$toast(res.msg);
      }
    },
    scrollEnd(e) {
      if (this.ifmore) {
        ++this.page;
        this.getlist();
      }
      // else {
      //   this.$toast({ message: "没有更多了", duration: 1000 });
      // }
    },
    goBack() {
      this.$router.go(-1);
    },
    slideChange(e) {
      this.active = e;
    },
    async getlist() {
      const params = {
        type: this.type,
        page: this.page,
        page_num: this.pagenum,
      };
      const res = await this.api.getwrong(params);
      if (res.code === 1) {
        const result = res.data.list.data;
        if (result.length === 0) {
          this.ifmore = false;
          this.wrongs = this.wrongs.concat(result);
        } else {
          this.ifmore = true;
          this.wrongs = this.page === 1 ? result : this.wrongs.concat(result);
        }
        console.log(this.wrongs);
      }
    },
    toDetail(item) {
      const params = {
        id: item.id,
      };
      this.$router.push({
        path: "/wrongdetail",
        query: params,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrongnavbar {
  background: #fff !important;
}
.back,
.result {
  width: 1rem;
}
.tabs {
  margin-top: 0rem;
}
.wrongs {
  width: 100%;
  height: auto;
}
.wrongbg {
  width: 6.86rem;
  height: 1.64rem;
}
.wrong_detail {
  width: 6.86rem;
  height: 1.64rem;
  margin-top: -1.84rem;
  color: rgba(50, 50, 51, 1);
  font-size: 0.28rem;
  font-weight: 400;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.48rem;
  padding: 0.32rem;
  box-sizing: border-box;
}
.wrong_title_box {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}
.wrongitem {
  margin-bottom: 0.32rem;
}
.laji {
  width: 0.32rem;
  height: 0.32rem;
  margin-left: 0.38rem;
}
.error_list {
  width: 100%;
  padding: 0 .32rem;
  box-sizing: border-box;
  margin-top: 1rem;
}
</style>
