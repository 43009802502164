<template>
    <div>
        <nav-bar>
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back3.png" alt="">
            </div>
        </nav-bar>
        <img src="../../assets/img/rankbg.png" alt="" class="bg">
        <tabs :tabs="tabs" :active="current" @choose="choose" class="tabsrank"></tabs>
        <swiper-list
        class="swiperrank"
        @slideChange="choose"
        :active="current"
        :ifthird="true"
        >
            <div slot="first" class="scroll1" :style="{height:+height+'px'}">
              <Scroll
              style="height: 100%;"
              ref="scroll"
              :probeType="3"
              :scrollX="scrollX"
              :pullup="true"
              @scrollEnd="scrollEnd"
              >
              <div >
                <div class="ranks">
                    <div class="display-flex-between">
                        <div class="rank_one " v-if="!second.nickname">
                            <div class="rank_one_pic"></div>
                            <div class="noresult">暂无人员</div>
                        </div>
                        <div v-else class="rank_one ">
                            <div class="rank_one_pic">
                                <van-image
                                    width="100%"
                                    height="100%"
                                    fit="cover"
                                    :src="second.avatar"
                                    />
                                <!-- <img :src="second.avatar" alt="" > -->
                            </div>
                            <img src="../../assets/img/ranktwo.png" alt="" class="rank_one_number">
                            <div class="rank_one_title">{{second.nickname}}</div>
                            <div class="rank_one_tip">积分</div>
                            <div class="rank_one_count">{{second.all_score}}</div>
                        </div>
                        <div class="rank_one " v-if="!first.nickname">
                            <div class="rank_one_pic"></div>
                            <div class="noresult">暂无人员</div>
                        </div>
                        <div v-else class="rank_one top">
                            <div class="rank_one_pic">
                                <van-image
                                    width="100%"
                                    height="100%"
                                    fit="cover"
                                    :src="first.avatar"
                                    />
                            </div>
                            <img src="../../assets/img/rankone.png" alt="" class="rank_one_number">
                            <div class="rank_one_title">{{first.nickname}}</div>
                            <div class="rank_one_tip">积分</div>
                            <div class="rank_one_count">{{first.all_score}}</div>
                        </div>
                        <div class="rank_one " v-if="!third.nickname">
                            <div class="rank_one_pic"></div>
                            <div class="noresult">暂无人员</div>
                        </div>
                        <div v-else class="rank_one ">
                            <div class="rank_one_pic">
                                <van-image
                                    width="100%"
                                    height="100%"
                                    fit="cover"
                                    :src="third.avatar"
                                    />
                            </div>
                            <img src="../../assets/img/rankthree.png" alt="" class="rank_one_number">
                            <div class="rank_one_title">{{third.nickname}}</div>
                            <div class="rank_one_tip">积分</div>
                            <div class="rank_one_count">{{third.all_score}}</div>
                        </div>
                    </div>
                    <div v-for="(item,index) in ranks" :key="index" class="list_one display-flex-between">
                        <div class="list_one_num">{{index+4}}</div>
                        <div class="rank_one_pic"><van-image
                        width="100%"
                        height="100%"
                        fit="cover"
                        :src="item.avatar"
                        /></div>
                        <div style="flex: 1;">
                            <div class="list_one_name">{{item.nickname}}</div>
                            <div class="list_one_tip">积分</div>
                        </div>
                        <div class="rank_one_count">{{item.all_score}}</div>
                    </div>
                </div>
                </div>
              </Scroll>
            </div>
            <div slot="second" class="scroll1" :style="{height:+height+'px'}">
                <Scroll
                style="height: 100%;"
                ref="scroll"
                :probeType="3"
                :scrollX="scrollX"
                :pullup="true"
                @scrollEnd="scrollEnd"
                >
                <div>
                  <div class="ranks">
                      <div class="display-flex-between">
                          <div class="rank_one " v-if="!second.nickname">
                              <div class="rank_one_pic"></div>
                              <div class="noresult">暂无人员</div>
                          </div>
                          <div v-else class="rank_one ">
                              <div class="rank_one_pic">
                                <van-image
                                width="100%"
                                height="100%"
                                fit="cover"
                                :src="second.avatar"
                                />
                              </div>
                              <img src="../../assets/img/ranktwo.png" alt="" class="rank_one_number">
                              <div class="rank_one_title">{{second.nickname}}</div>
                              <div class="rank_one_tip">积分</div>
                              <div class="rank_one_count">{{second.all_score}}</div>
                          </div>
                          <div class="rank_one " v-if="!first.nickname">
                              <div class="rank_one_pic"></div>
                              <div class="noresult">暂无人员</div>
                          </div>
                          <div v-else class="rank_one top">
                              <div class="rank_one_pic">
                                <van-image
                                width="100%"
                                height="100%"
                                fit="cover"
                                :src="first.avatar"
                                />
                              </div>
                              <img src="../../assets/img/rankone.png" alt="" class="rank_one_number">
                              <div class="rank_one_title">{{first.nickname}}</div>
                              <div class="rank_one_tip">积分</div>
                              <div class="rank_one_count">{{first.all_score}}</div>
                          </div>
                          <div class="rank_one " v-if="!third.nickname">
                              <div class="rank_one_pic"></div>
                              <div class="noresult">暂无人员</div>
                          </div>
                          <div v-else class="rank_one ">
                              <div class="rank_one_pic">
                                <van-image
                                width="100%"
                                height="100%"
                                fit="cover"
                                :src="third.avatar"
                                />
                              </div>
                              <img src="../../assets/img/rankthree.png" alt="" class="rank_one_number">
                              <div class="rank_one_title">{{third.nickname}}</div>
                              <div class="rank_one_tip">积分</div>
                              <div class="rank_one_count">{{third.all_score}}</div>
                          </div>
                      </div>
                      <div v-for="(item,index) in ranks" :key="index" class="list_one display-flex-between">
                          <div class="list_one_num">{{index+4}}</div>
                          <div><van-image
                            width="100%"
                            height="100%"
                            fit="cover"
                            :src="item.avatar"
                            /></div>
                          <div style="flex: 1;">
                              <div class="list_one_name">{{item.nickname}}</div>
                              <div class="list_one_tip">积分</div>
                          </div>
                          <div class="rank_one_count">{{item.all_score}}</div>
                      </div>
                  </div>
                </div>
                </Scroll>
              </div>
            <div slot="third" class="scroll1" :style="{height:+height+'px'}">
                <Scroll
                style="height: 100%;"
                ref="scroll"
                :probeType="3"
                :scrollX="scrollX"
                :pullup="true"
                @scrollEnd="scrollEnd"
                >
                <div>
                <div class="ranks">
                    <div class="display-flex-between">
                        <div class="rank_one " v-if="!second.nickname">
                            <div class="rank_one_pic"></div>
                            <div class="noresult">暂无人员</div>
                        </div>
                        <div v-else class="rank_one ">
                            <div class="rank_one_pic">
                                <van-image
                                width="100%"
                                height="100%"
                                fit="cover"
                                :src="second.avatar"
                                />
                            </div>
                            <img src="../../assets/img/ranktwo.png" alt="" class="rank_one_number">
                            <div class="rank_one_title">{{second.nickname}}</div>
                            <div class="rank_one_tip">积分</div>
                            <div class="rank_one_count">{{second.all_score}}</div>
                        </div>
                        <div class="rank_one " v-if="!first.nickname">
                            <div class="rank_one_pic"></div>
                            <div class="noresult">暂无人员</div>
                        </div>
                        <div v-else class="rank_one top">
                            <div class="rank_one_pic">
                                <van-image
                                    width="100%"
                                    height="100%"
                                    fit="cover"
                                    :src="first.avatar"
                                    />
                            </div>
                            <img src="../../assets/img/rankone.png" alt="" class="rank_one_number">
                            <div class="rank_one_title">{{first.nickname}}</div>
                            <div class="rank_one_tip">积分</div>
                            <div class="rank_one_count">{{first.all_score}}</div>
                        </div>
                        <div class="rank_one " v-if="!third.nickname">
                            <div class="rank_one_pic"></div>
                            <div class="noresult">暂无人员</div>
                        </div>
                        <div v-else class="rank_one ">
                            <div class="rank_one_pic">
                                <van-image
                                    width="100%"
                                    height="100%"
                                    fit="cover"
                                    :src="third.avatar"
                                    />
                            </div>
                            <img src="../../assets/img/rankthree.png" alt="" class="rank_one_number">
                            <div class="rank_one_title">{{third.nickname}}</div>
                            <div class="rank_one_tip">积分</div>
                            <div class="rank_one_count">{{third.all_score}}</div>
                        </div>
                    </div>
                    <div v-for="(item,index) in ranks" :key="index" class="list_one display-flex-between">
                        <div class="list_one_num">{{index+4}}</div>
                        <div>
                            <van-image
                                width="100%"
                                height="100%"
                                fit="cover"
                                :src="item.avatar"
                                />
                        </div>
                        <div style="flex: 1;">
                            <div class="list_one_name">{{item.nickname}}</div>
                            <div class="list_one_tip">积分</div>
                        </div>
                        <div class="rank_one_count">{{item.all_score}}</div>
                    </div>
                </div>
            </div>
                </Scroll>
            </div>
        </swiper-list>
        <div class="footer display-flex-between high">
            <div class="list_one_num">{{myrank.rank}}</div>
            <div>
                <van-image
                width="100%"
                height="100%"
                fit="cover"
                :src="myrank.avatar"
                />
            </div>
            <!-- <img :src="myrank.avatar" alt="" > -->
            <div style="flex: 1;">
                <div class="list_one_name">{{myrank.nickname}}</div>
            </div>
            <div class="rank_one_count" style="color: #257CFF;">{{myrank.all_score}}</div>
        </div>
    </div>
</template>
<script>
import tabs from '@/components/tabs.vue'
import swiperList from '@/components/swiper.vue'
import Scroll from '@/components/scroll.vue'
export default {
  components: {
    tabs,
    swiperList,
    Scroll
  },
  data () {
    return {
      tabs: ['周榜', '月榜', '总榜'],
      ranks: [],
      current: 0,
      scrollX: false,
      first: '',
      second: '',
      third: '',
      list: '',
      myrank: '',
      height: ''
    }
  },
  watch: {
    current () {
      this.getrank()
    }
  },
  mounted () {
    const bgheight = document.documentElement.querySelector('.bg').height
    const ranksheight = document.documentElement.querySelector('.tabsrank').height
    this.height = document.documentElement.clientHeight - bgheight
    console.log(bgheight, ranksheight, this.height)
    this.getrank()
  },
  methods: {
    async getrank () {
      const params = {
        type: Number(this.current) + 1
      }
      const res = await this.api.rank(params)
      if (res.code === 1) {
        // this.second=''
        this.first = res.data.first
        this.second = res.data.second
        this.third = res.data.third
        // this.ranks=[{},{},{},{}]
        this.ranks = res.data.list
        this.myrank = res.data.self
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    choose (e) {
      this.current = e
    },
    scrollEnd () {

    }
  }
}
</script>
<style lang="scss">
    .bg{
        width: 100%;
        height: 4.9rem;
        position: fixed;
        top: -0.88rem;
        z-index: -1;
    }
    .tabsrank{
        width: 100%;
        height: 0.9rem;
        background-color: #fff;
        position: fixed;
        left: 0;
        top: 3.9rem;
        z-index: 1;
    }
    .swiperrank{
        width: 100%;
        height: auto;
        height: 12rem;
        position: fixed;
        top: 5rem;
    }
    .scroll1{
        /* height: 100%; */
        width: 100%;
        padding: 0 0.32rem;
        box-sizing: border-box;
    }
    .ranks{
        height: 100%;
        /* border: 0.01rem solid red; */
        padding: 0.8rem 0 2.7rem 0;
        box-sizing: border-box;
    }
    .top{
        margin-top: -1rem;
    }
    .rank_one{
        width: 2.16rem;
        height: 2.84rem;
        text-align: center;
        border-radius: 0.16rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        box-shadow: 0px 5px 12px 0px rgba(89.53,145.17,200.81,0.2);
        position: relative;
    }
    .rank_one_pic img{
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        border: 0.02rem solid #eee;
        margin: -0.6rem auto 0;
    }
    .rank_one_number{
        width: 0.64rem;
        height: 0.64rem;
        position: absolute;
        top: 0.45rem;
        left: 50%;
        transform: translate(-50%,0);
    }
    .rank_one_title{
        font-size: 0.26rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 0.4rem;
    }
    .rank_one_tip{
        color: rgba(167,166,179,1);
        font-size: 0.22rem;
        font-weight: 400;
        margin: 0.04rem 0 0.16rem;
    }
    .rank_one_count{
        color: rgba(105,108,122,1);
        font-size: 0.4rem;
        font-weight: 700;
    }
    .list_one{
        width: 6.86rem;
        height: 1.76rem;
        border-radius: 0.24rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        box-shadow: 0px 5px 12px 0px rgba(89.53,145.17,200.81,0.2);
        padding: 0.32rem;
        box-sizing: border-box;
        margin-top: 0.32rem;
    }
    .list_one_num{
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 0.08rem;
        opacity: 1;
        background: rgba(206.24,205.63,214.2,1);
        text-align: center;
        line-height: 0.4rem;
        color: rgba(255,255,255,1);
        font-size: 0.28rem;
    }
    .list_one img,.high img{
        width: 0.96rem;
        height: 0.96rem;
        margin: 0 0.24rem 0 0.32rem;
        border-radius: 50%;
    }
    .list_one_name{
        font-size: 0.3rem;
        font-weight: 600;
    }
    .list_one_tip{
        color: rgba(167,166,179,1);
        font-size: 0.22rem;
    }
    .high{
        position: fixed !important;
        bottom: 0rem;
        left: 0;
        background: #f7f8fa;
        padding: 0.19rem 0.62rem 0.4rem !important;
        box-sizing: border-box;
    }
</style>
