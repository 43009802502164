<template>
    <div>
        <nav-bar class="navbar">
            <div slot="left">
                <div class="back" @click="goBack">
                    <img src="../../assets/img/back.png" alt="" class="backimg"/>
                </div>
            </div>
            <div slot="center" class="searchnav">
                <img src="../../assets/img/iconly-线性描边Light-Search@2x.png" alt="">
                <input
                type="text"
                placeholder="搜索"
                placeholder-style="font"
                v-model="searchValue"
                autofocus="autofocus"
                v-on:keyup.enter="toSearch"
                />
            </div>
            <div slot="right" class="result" @click="toSearch">
                <div>搜索</div>
            </div>
        </nav-bar>
        <div v-if="ifSearch" class="tabs">
            <div class="history display-flex-between">
                <div>历史记录</div>
                <img src="../../assets/img/del.png" alt="" v-if="history.length!==0" @click="ifmodel=true">
            </div>
            <div class="noresult" v-if="history.length == 0">暂无搜索记录</div>
            <div class="history_items" v-else>
                <div class="history_item" v-for="(item,index) in history" :key="index" @click="historyClick(item)">{{item.keyword}}</div>
            </div>
            <div>
                <model
                :ifShow="ifmodel"
                title="确认删除？"
                cancelText="取消"
                confirmText="确定"
                @confirmFun="confirmFun"
                @cancelFun="ifmodel=false"></model>
            </div>
        </div>
        <div v-else class="searchResult">
            <tabs :tabs="tabs" :active="current" @choose="slideChange"></tabs>
            <div class="line"></div>
            <swiper-list
                class="resultlist"
                @slideChange="slideChange"
                :active="current"
                ifthird="true"
                ifforth="true"
                >
                <div slot="first" class="scroll3">
                  <van-list
                    @load="scrollEnd">
                    <div v-if="onclass.length == 0" class="noresult">
                      <img src="../../assets/img/nonono.png" alt="">
                      <div class="onBottom" >暂无在线课程</div>
                     </div>
                    <div v-else class="list display-flex-between" >
                      <div class="list_item" v-for="(item,index) in onclass" :key="index" >
                        <router-link :to="{path:'/onClassDetail', query:{id:item.id}}">
                            <div class="list_item_time">{{item.class_hours}} 课时</div>
                            <img :src="item.image" mode="widthFix" class="list_item_pic">
                            <div class="list_item_title p-1">{{item.name}}</div>
                            <div class="list_item_user display-flex">
                                <img :src="item.teacher.avatar" alt="" class="list_item_userpic">
                                <span>{{item.teacher.name}}</span>
                            </div>
                        </router-link>
                      </div>
                    </div>
                  </van-list>
                </div>

                <div  slot="second" class="scroll3">
                  <van-list
                    @load="scrollEnd">
                    <div v-if="liveclass.length == 0" class="noresult">
                      <img src="../../assets/img/nonono.png" alt="">
                      <div class="onBottom" >暂无直播课程</div>
                    </div>
                    <div v-else class="list display-flex-between">
                        <div class="list_item" v-for="(item,index) in liveclass" :key="index" >
                          <router-link :to="{path:'/lineClassDetail', query:{id:item.id}}">
                              <div class="list_item_time blue">直播中</div>
                              <img :src="item.image" mode="widthFix" class="list_item_pic">
                              <div class="list_item_title p-1">{{item.name}}</div>
                              <div class="list_item_user display-flex">
                                  <img :src="item.teacher.avatar" alt="" class="list_item_userpic">
                                  <span>{{item.teacher.name}}</span>
                              </div>
                          </router-link>
                        </div>
                    </div>
                  </van-list>
                </div>

                <div  slot="third" class="scroll3">
                  <van-list
                    @load="scrollEnd">
                    <div v-if="testclass.length == 0" class="noresult">
                      <img src="../../assets/img/nonono.png" alt="">
                      <div class="onBottom" >暂无文本课程</div>
                    </div>
                    <div v-else class="list display-flex-between">
                        <div class="class " v-for="(item,index) in testclass" :key="index" >
                          <router-link :to="{path:'/textClassDetail', query:{id:item.id}}" class="display-flex-between">
                                <img :src="item.image" mode="widthFix" >
                                <div class="class_detail">
                                <div class="p-1">{{item.name}}</div>
                                <div class="class_info p-2">{{item.intro}}</div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                  </van-list>
                </div>
                <div  slot="forth" class="scroll3">
                  <van-list
                    @load="scrollEnd">
                  <div v-if="ziliaoclass.length == 0" class="noresult">
                    <img src="../../assets/img/nonono.png" alt="">
                    <div class="onBottom" >暂无资料库</div>
                  </div>
                  <div v-else class="list display-flex-between">
                      <div class="class " v-for="(item,index) in ziliaoclass" :key="index" >
                        <router-link :to="{path:'/dataDetail', query:{id:item.id}}" class="display-flex-between">
                              <img :src="item.image" mode="widthFix" >
                              <div class="class_detail">
                              <div class="p-1">{{item.name}}</div>
                              <div class="class_info p-2">{{item.intro}}</div>
                              </div>
                          </router-link>
                      </div>
                  </div>
                  </van-list>
              </div>
            </swiper-list >
        </div>
    </div>
</template>
<script>
import tabs from '@/components/tabs.vue'
import model from '@/components/model.vue'
import swiperList from '@/components/swiper.vue'
import '@/assets/style/list1.css'
import '@/assets/style/list3.css'
import '@/assets/style/common.css'
export default {
  name: 'result',
  components: {
    tabs,
    model,
    swiperList
  },

  // beforeRouteEnter (to, from, next) {
  //   // 不能获取组件实例this!
  //   // 因为当守卫执行时，组件实例还没被创建

  //   if (from.path === '/textClassDetail') {
  //     window.from = from
  //   }
  //   console.log('from', from)
  //   next()
  // },
  // activated () {
  //   if (window.from) {
  //     console.log('activated')
  //   }
  // },
  // // 离开此组件对应的路由时调用
  // beforeRouteLeave (to, from, next) {
  //   console.log(to,from,next)
  //   // 可以访问组件实例this
  //   window.from = undefined
  //   next()
  // },

  data () {
    return {
      tabs: ['在线课程', '直播课程', '文本课程', '资料库'],
      ifResult: false,
      onclass: [],
      liveclass: [],
      testclass: [],
      current: 0,
      ifSearch: true,
      history: [],
      ifmodel: false,
      searchValue: '',
      page: 1,
      pageNum: 20,
      type: 1,
      ifmore: false, // 页码数据(第二页是否有数据)
      scrollX: false,
      historyarr: [], // 只有关键字的数组
      itemIndex: '', // 查找后的下标
      ziliaoclass: []// 资料库
    }
  },
  created () {
    this.searchHistory()
  },
  watch: {
    current () {
      this.page = 1
      this.onclass = []
      this.liveclass = []
      this.testclass = []
      this.ziliaoclass = []
      if (this.current === 0) {
        this.videoCourse() // 在线课程
      } else if (this.current === 1) {
        this.liveBroadcast() // 直播课程
      } else if (this.current === 2) {
        this.textList() // 文本课程
      } else {
        this.ziliaoList()
      }
    }
  },
  methods: {
    goBack () {
      if (this.ifSearch === false) {
        this.searchValue = ''
        this.ifSearch = true
        this.searchHistory()
      } else {
        this.$router.go(-1)
      }
    },

    scrollEnd (e) {
      console.log(e)
      if (this.ifmore) {
        ++this.page
        if (this.current === 0) {
          this.videoCourse()
        } else if (this.current === 1) {
          this.liveBroadcast()
        } else if (this.current === 2) {
          this.testList()
        }
      }
      // else {
      //   this.$toast({ message: '没有更多了', duration: 1000 })
      // }
    },

    slideChange (e) {
      this.current = e
    },

    // 点击搜索
    toSearch () {
      if (this.searchValue !== '') {
        this.page = 1
        this.onclass = []
        this.liveclass = []
        this.testclass = []
        this.ziliaoclass = []
        this.ifSearch = false
        if (this.current === 0) {
          this.videoCourse()
        } else if (this.current === 1) {
          this.liveBroadcast()
        } else if (this.current === 2) {
          this.textList() // 文本课程
        } else {
          this.ziliaoList()
        }
      } else {
        this.$toast('请输入要查找的内容')
      }
    },

    // 点击历史记录进行搜索
    historyClick (item) {
      this.searchValue = item.keyword
      this.toSearch()
    },

    // 搜索历史记录
    async searchHistory () {
      const reslist = await this.api.searchHistory()
      if (reslist.code === 1) {
        // this.history = reslist.data.list
        this.history = reslist.data.list
        // this.historyarr = reslist.data.list.map((item) => item.keyword)
      } else {
        this.$toast(reslist.msg)
      }
    },

    // 点击确定删除
    async confirmFun () {
      this.ifmodel = false
      const reslist = await this.api.clearSearch()
      if (reslist.code === 1) {
        this.history = []
        this.$toast(reslist.msg) // 清除成功
      } else {
        this.$toast(reslist.msg)
      }
    },

    // 在线课程
    async videoCourse () {
      const params = {
        keyword: this.searchValue,
        page: this.page,
        page_num: this.pageNum
      }
      const reslist = await this.api.videoCourse(params)
      if (reslist.code === 1) {
        const res = reslist.data.list.data
        this.ifResult = this.page === 1 && res.length === 0
        if (res.length === 0) {
          this.ifmore = false
          this.onclass = this.onclass.concat(res)
        } else {
          this.ifmore = true
          this.onclass = this.page === 1 ? res : this.onclass.concat(res)
        }
        // console.log(this.onclass, this.ifResult)
      } else {
        this.$toast(reslist.msg)
      }
    },

    // 直播课程
    async liveBroadcast () {
      const params = {
        keyword: this.searchValue,
        type: this.type,
        page: this.page,
        page_num: this.pageNum
      }
      const reslist = await this.api.liveBroadcast(params)
      if (reslist.code === 1) {
        const res = reslist.data.list.data
        this.ifResult = this.page === 1 && res.length === 0
        if (res.length === 0) {
          this.ifmore = false
          this.liveclass = this.liveclass.concat(res)
        } else {
          this.ifmore = true
          this.liveclass = this.page === 1 ? res : this.liveclass.concat(res)
        }
      } else {
        this.$toast(reslist.msg)
      }
    },

    // 文本课程
    async textList () {
      const params = {
        keyword: this.searchValue,
        page: this.page,
        page_num: this.pageNum
      }
      const reslist = await this.api.textList(params)
      if (reslist.code === 1) {
        const res = reslist.data.list.data
        this.ifResult = this.page === 1 && res.length === 0
        if (res.length === 0) {
          this.ifmore = false
          this.testclass = this.testclass.concat(res)
        } else {
          this.ifmore = true
          this.testclass = this.page === 1 ? res : this.testclass.concat(res)
        }
      } else {
        this.$toast(reslist.msg)
      }
    },
    // 资料库
    async ziliaoList () {
      const params = {
        keyword: this.searchValue,
        page: this.page,
        page_num: this.pageNum
      }
      const reslist = await this.api.datalist(params)
      if (reslist.code === 1) {
        const res = reslist.data.list.data
        this.ifResult = this.page === 1 && res.length === 0
        if (res.length === 0) {
          this.ifmore = false
          this.ziliaoclass = this.ziliaoclass.concat(res)
        } else {
          this.ifmore = true
          this.ziliaoclass = this.page === 1 ? res : this.ziliaoclass.concat(res)
        }
      } else {
        this.$toast(reslist.msg)
      }
    }
  }
}
</script>
<style scoped lang="scss">
    .blue{
       background: linear-gradient(180.25deg, rgba(37,202.68,255,1) 0%, rgba(67.52,37,255,1) 100%);
    }
    .navbar{
        width: 100%;
        background-color: #fff;
    }
    .back {
        width: 0.48rem;
        height: 0.48rem;
        font-size: 0.48rem;
    }
    .backimg{
        width:0.48rem;
        height: 0.48rem;
    }
    .searchnav{
        width: 5.24rem;
        position: relative;
        height: 0.64rem;
        line-height: 0.64rem;
        border-radius: 0.08rem;
        opacity: 1;
        padding: 0 0.5rem;
        box-sizing: border-box;
        border: 0.01rem solid #257CFF;
    }
    .searchnav img{
        position: absolute;
        width: 0.32rem;
        height: 0.32rem;
        top: 50%;
        left: 0.32rem;
        transform: translate(0,-50%);

    }
    .searchnav input{
        border: none;
        text-align: left;
        padding-left: 0.2rem;
        margin: 0;
        font-weight: 500;
        font-size: 0.3rem;
    }
    input::-webkit-input-placeholder{
        color: rgb(173, 201, 241);
        font-size: 0.28rem;
    }
    .font{
      font-size: 0.20rem;
    }
    .searchResult{
        margin-top: 1rem;
    }
    .result{
        font-size: 0.28rem;
        width: 1rem;
        text-align: right;
        margin-left: 0.2rem;
        font-weight: 600;
    }
    .tabs{
        margin-top: 1.2rem;
        padding: 0 0.32rem;
        box-sizing: border-box;
    }
    .noresult{
        width: 100%;
        height: auto;
        text-align: center;
        margin: 1.2rem auto;
    }
    .noresult img{
        width: 3.2rem;
        height: 3.2rem;
    }
    .scroll3{
      padding: 0.32rem;
      // height: 14.3rem;
      box-sizing: border-box;
    }
    .resultlist{
        width: 100%;
        height: auto;
    }
    .history{
        font-size: 0.32rem;
        font-weight: 600;
        margin-bottom: 0.3rem;
    }
    .history img{
        width: 0.4rem;
        height: 0.4rem;
    }
    .history_item{
        width: 100%;
        height: auto;
        padding: 0.3rem 0;
        box-sizing: border-box;
        color: rgba(100,101,102,1);
        font-size: 0.26rem;
        border-top: 0.01rem solid #eee;
    }
    .history_item:nth-child(1){
        border-top: none;
    }
</style>
