<template>
    <div>
        <nav-bar>
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">
                签到
            </div>
            <div slot="right" class="result"></div>
        </nav-bar>
        <div>

          <div id="container" style="width:100%;height:768px;"></div>
           <!-- <el-amap
            style="width: 100%; height:768px"
            :amap-manager="amapManager"
            vid="amapDemo"
            :plugin="plugin"
            class="amap-demo"
            :center="center"
            :zoom="zoom">
            </el-amap> -->
            <div class="position" @click="tosign">
                <div class="position_title" v-if="sign=='0'">签到</div>
                <div class="position_title" v-else style="opacity: 0.5;">已签到</div>
                <div class="position_time">{{time}}</div>
                <!-- <div>打卡经纬度{{center}}</div> -->
            </div>
            <!-- <div style="position: fixed;top: 0;left: 0;">{{lng}}{{lat}}</div> -->
        </div>
    </div>
</template>
<script>
// import { AMapManager } from 'vue-amap'
// const amapManager = new AMapManager()
export default {
  name: 'demo',
  data () {
    const self = this
    return {
      classid: ';', // 培训计划班级id
      id: '', // 线下培训id
      lessonid: '', // 课节id
      trainid: '', // 培训计划id
      sign: '',
      time: '',
      height: '',
      // center: [117.11994, 39.0665],
      number: '',
      lng: 0,
      lat: 0,
      zoom: 16,
      // amapManager,
      loaded: false
      // plugin: [{
      //   enableHighAccuracy: true, // 是否使用高精度定位，默认:true
      //   maximumAge: 0, // 定位结果缓存0毫秒，默认：0
      //   convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
      //   showButton: true, // 显示定位按钮，默认：true
      //   buttonPosition: 'RB', // 定位按钮停靠位置，默认：'LB'，左下角
      //   showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
      //   showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
      //   panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
      //   zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
      //   extensions: 'all',
      //   pName: 'Geolocation',
      //   events: {
      //     init (o) {
      //       // o 是高德地图定位插件实例
      //       console.log(o.getCurrentPosition)
      //       o.getCurrentPosition((status, result) => {
      //         console.log(result)
      //         // self.number=result.message
      //         if (result && result.position) {
      //           self.lng = result.position.lng
      //           self.lat = result.position.lat
      //           self.center = [self.lng, self.lat]
      //           self.loaded = true
      //           self.$nextTick()
      //         }
      //       })
      //     }
      //   }
      // }]
    }
  },
  methods: {
    async getMyLocation () {
      /* eslint-disable no-new */
      var geolocation = new qq.maps.Geolocation('5SCBZ-UBTWP-HAJDZ-LMZ5S-222Q2-LYFU2', 'yourkey')
      // geolocation.getIpLocation(this.showPosition, this.showErr);
      geolocation.getLocation(this.showPosition, this.showErr)// 或者用getLocation精确度比较高
    },
    showPosition (position) {
      console.log(position)
      this.lat = position.lat
      this.lng = position.lng
      this.city = position.addr
      this.setMap()
    },
    showErr () {
      console.log('定位失败')
      // this.$toast({ message: '定位失败', duration: 1000 })
      this.getMyLocation() // 定位失败再请求定位，测试使用
    },
    // 第二部分
    // 位置信息在地图上展示
    setMap () {
      // 步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
      // 设置地图中心点
      /* eslint-disable no-new */
      var myLatlng = new qq.maps.LatLng(this.lat, this.lng)
      // 定义工厂模式函数
      var myOptions = {
        zoom: 13, // 设置地图缩放级别
        center: myLatlng // 设置中心点样式
        // mapTypeId: qq.maps.MapTypeId.ROADMAP //设置地图样式详情参见MapType
      }
      // //获取dom元素添加地图信息
      /* eslint-disable no-new */
      var map = new qq.maps.Map(document.getElementById('container'), myOptions)
      // 第三部分
      // 给定位的位置添加图片标注
      /* eslint-disable no-new */
      var marker = new qq.maps.Marker({
        position: myLatlng,
        map: map
      })
      // 给定位的位置添加文本标注
      // var marker = new qq.maps.Label({
      //   position: myLatlng,
      //   map: map,
      // });
    },

    goBack () {
      this.$router.go(-1)
    },
    async tosign () {
      const params = {
        lat: this.lat,
        lng: this.lng,
        class_id: this.lessonid,
        // class_id: this.classid,
        lesson_id: this.id,
        type: 3,
        train_class_id: this.trainid
      }
      const res = await this.api.recordvideo(params)
      if (res.code === 1) {
        console.log(res)
        this.$toast({ message: '已签到', duration: 1000 })
        this.goBack()
      } else {
        this.$toast(res.msg)
      }
    },
    getTime () {
      const time = new Date()
      const minute = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
      const hour = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
      this.time = hour + ':' + minute
      // navigator.geolocation.getCurrentPosition(function(position){
      //   console.log('latitude'+position.coords.latitude)
      // },function(){
      //   console.log('error')
      // })
    }

  },

  mounted () {
    this.getTime()
    const orderHight = document.body.clientHeight
    this.height = orderHight - 88
    console.log(this.$route.query)
    this.classid = this.$route.query.class_id
    this.id = this.$route.query.id
    this.lessonid = this.$route.query.lesson_id
    this.trainid = this.$route.query.train_id
    this.sign = this.$route.query.isSign

    this.getMyLocation()
  }
}
</script>
<style scoped>
    .map {
        width: 100%;
        height: 14.48rem;
    }
    .position{
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        opacity: 1;
        background: #257CFF;
        border: 0.25rem solid #fff;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #fff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .position_title{
        font-size: 0.32rem;
        margin-bottom: 0.1rem;
    }
    .position_time{
        font-size: 0.48rem;
    }
</style>
