<template>
    <div>
        <img src="../../assets/img/begin.png" alt="" class="bg"/>
        <div class="begin_logo">
            <img src="../../assets/img/logo3.png" alt="">
            <!-- <div>智安安全培训</div> -->
        </div>
        <div class="token" @click="cardToken">登录</div>
    </div>
</template>
<script>
export default {
  methods: {
    cardToken () {
      this.$router.push('/cardToken')
    }
  }
}
</script>
<style scoped lang="scss">
    .bg{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
    }
    .begin_logo{
        width: 100%;
        height: auto;
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-100%);
        color: rgba(255,255,255,1);
        font-size: 0.48rem;
        letter-spacing: 0.02rem;
    }
    .begin_logo img{
        width:2rem;
        height: 2rem;
        margin-bottom: 0.4rem;
    }
    .token{
        width: 6.22rem;
        height: 0.88rem;
        opacity: 1;
        background: rgba(255,255,255,1);
        color: rgba(37.19,124.31,255,1);
        font-size: 0.32rem;
        font-weight: 600;
        text-align: center;
        line-height: 0.88rem;
        position: fixed;
        left: 50%;
        bottom: 15%;
        transform: translate(-50%,0);
    }
</style>
