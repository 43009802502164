import axios from 'axios'
import config from '@/assets/js/config.js'

axios.interceptors.request.use((req) => {
  try {
    req.url = config.baseURL + req.url
    if (localStorage.getItem('token')) {
      req.headers.token = localStorage.getItem('token')
    }
    return req
  } catch (error) {
    console.log(error)
  }
}, (error) => {
  return Promise.reject(error)
})

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        // token 过期本地存储删除，然后重新登录
        // store.commit('removeToken')
        // store.commit('removeName')
        // //Vue.prototype.toast('token过去请重新登录');
        window.location.href = '/cardToken'
      }
    }
    return Promise.reject(error.response.data) // 返回接口返回的错误信息
  })

// 默认请求成功状态 axios 封装(get,post)
/*
   url  : 后台路由地址  /index/carousels
   type:  get , post
   params 传递参数

   axios.get('/index/carousels',{params}).then(res => {}).catch(err => {})
   axios.post('/index/carousels',params).then(res => {}).catch(err => {})
   axios({
       method:'post'
       ...
   })
*/
export default (url, type = 'post', params) => {
  return axios[type](url, type === 'get' ? { params } : params, { withCredentials: true }).then(res => {
    const { status, data } = res
    // 正确状态处理
    if (status === 200) {
      return data
    }
  }).catch(err => {
    return err
    // return Promise.reject(err) // 返回接口返回的错误信息
  })
}
