var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"bg3",attrs:{"src":require("../../assets/img/bg3.png"),"alt":""}}),_c('nav-bar',{staticClass:"examnavbar"},[_c('div',{staticClass:"backnav",attrs:{"slot":"left"},on:{"click":_vm.goBack},slot:"left"},[_c('img',{attrs:{"src":require("../../assets/img/back.png"),"alt":""}})]),(_vm.type == 4 || _vm.practicetype == 1 || _vm.practicetype == 2)?_c('div',{staticClass:"center display-flex",attrs:{"slot":"center"},slot:"center"},[_c('div',{staticClass:"center_title"},[_vm._v("倒计时")]),_c('van-count-down',{attrs:{"time":_vm.time},on:{"finish":_vm.finish,"change":_vm.change}})],1):_vm._e(),(_vm.type == '3' || _vm.type == '4')?_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"right"},slot:"right"},[_c('div',{staticClass:"picker display-flex",on:{"click":_vm.tocard}},[_c('div',[_vm._v("答题卡")]),_c('img',{attrs:{"src":require("../../assets/img/card.png"),"alt":""}})])]):_vm._e()]),_c('div',{staticClass:"examcont"},[_c('div',{staticClass:"examone"},[_c('div',{staticClass:"exam_title"},[(Number(_vm.type) != 2)?_c('span',[_vm._v("("+_vm._s(_vm.num + 1)+"/"+_vm._s(_vm.alltest.length)+")")]):_vm._e(),_vm._v(" "+_vm._s(_vm.subjectList.title)+" "),(_vm.subjectList.type == 2)?_c('span',[_vm._v("(多选)")]):_vm._e(),(_vm.subjectList.type == 3)?_c('span',[_vm._v("(判断)")]):_vm._e(),(_vm.subjectList.type == 1)?_c('span',[_vm._v("(单选)")]):_vm._e()]),(
          Number(_vm.subjectList.type) == 2 &&
          (_vm.type == '2' || _vm.type == '3' || _vm.type == '4' || _vm.type == '1')
        )?_c('div',{staticClass:"title_bottom"},_vm._l((this.subjectList.options_arr),function(item,index){return _c('div',{key:index,staticClass:"key_one",class:{
            active: _vm.chooseone.indexOf(item.option_name) !== -1,
            red: _vm.isDRedHandler(item) && _vm.$route.query.isShowAnswer == 'success'
          },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.option(item, index)}}},[_c('div',[_vm._v(_vm._s(item.option_name)+" . "+_vm._s(item.value))]),(item.pic)?_c('div',{staticClass:"img_list"},[_vm._l((item.pic_arr),function(item_pic,index_pic){return [_c('img',{key:index_pic,attrs:{"src":item_pic,"alt":""}})]})],2):_vm._e()])}),0):_vm._e(),(
          _vm.subjectList.type != 2 &&
          (_vm.type == '2' || _vm.type == '3' || _vm.type == '4' || _vm.type == '1')
        )?_c('div',{staticClass:"title_bottom"},_vm._l((_vm.subjectList.options_arr),function(item,index){return _c('div',{key:index},[(item.value)?_c('div',{staticClass:"key_one",class:{
                active: _vm.chooseone === item.option_name,
                red: _vm.isRedHandler(item) && _vm.$route.query.isShowAnswer == 'success'
                },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.option(item, index)}}},[_c('div',[_vm._v(_vm._s(item.option_name)+" . "+_vm._s(item.value))]),(item.pic)?_c('div',{staticClass:"img_list"},[_vm._l((item.pic_arr),function(item_pic,index_pic){return [_c('img',{key:index_pic,attrs:{"src":item_pic,"alt":""}})]})],2):_vm._e()]):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"footer display-flex-between"},[(_vm.num != 0 && _vm.type != 2)?_c('div',{staticClass:"nexttest display-flex"},[_c('div',{staticClass:"common_handle",on:{"click":_vm.upClick}},[_c('img',{attrs:{"src":require("../../assets/img/next.png"),"alt":""}}),_c('span',[_vm._v("上一题")])])]):_vm._e(),((_vm.num != _vm.alltest.length - 1 && _vm.type != 2) || _vm.type == 2)?_c('div',{staticClass:"nexttest display-flex"},[_c('div',{staticClass:"common_handle",on:{"click":_vm.nextClick}},[_c('span',[_vm._v("下一题")]),_c('img',{attrs:{"src":require("../../assets/img/last.png"),"alt":""}})])]):_vm._e(),(
            ((_vm.type == 3 || _vm.type == 4) && _vm.num + 1 == _vm.alltest.length) ||
            _vm.type == 1
          )?_c('div',{staticClass:"footer_button",on:{"click":_vm.submitTopic}},[_vm._v(" 交卷 ")]):_vm._e()]),(_vm.type =='3' && _vm.$route.query.isShowAnswer == 'success')?_c('div',{staticClass:"footer_button2 display-flex-between",on:{"click":_vm.resetTestHandler}},[_vm._v(" 重做 ")]):_vm._e()]),_c('div',{staticClass:"line"}),(_vm.answerList.chooseone && _vm.type == '3' && _vm.$route.query.isShowAnswer == 'success')?_c('div',{staticClass:"correct"},[_vm._v("正确答案："+_vm._s(_vm.answerList.correct))]):_vm._e(),(_vm.answerList.chooseone && _vm.type == '3' && _vm.$route.query.isShowAnswer == 'success')?_c('div',{staticClass:"analy",style:({'margin-bottom': _vm.content_bottom+'px'})},[_vm._v("解析: "+_vm._s(_vm.answerList.remark))]):_vm._e(),_c('model',{attrs:{"ifShow":_vm.ifsubmit3,"confirmText":"确定","title":"您还有题未答完，请答完再提交"},on:{"confirmFun":_vm.confirmFun3}}),(_vm.type == 2)?_c('model',{attrs:{"ifShow":_vm.iftime,"confirmText":"提交","title":"答题时间已到，请提交试卷","showClose":_vm.showClose},on:{"confirmFun":_vm.challengetop}}):_vm._e(),_c('model',{attrs:{"ifShow":_vm.ifsubmit2,"confirmText":"继续答题","title":"继续答题","content":"剩余时间","showClose":_vm.showClose1}}),_c('model',{attrs:{"ifchallenge":"true","ifShow":_vm.ifover,"confirmText":"完成","cancelText":_vm.cancelText,"title":_vm.truenumber,"content":_vm.content,"icon":_vm.overicon},on:{"confirmFun":_vm.goBack,"cancelFun":_vm.tocard1}})],1),_c('model',{attrs:{"scroll":false,"ifphoto":true,"ifShow":_vm.showyan,"confirmText":"确定","cancelText":"取消","title":"身份信息验证","content":"设置人脸识别照片为本人学习"},on:{"yanpeoplepic":_vm.sureyan,"cancelFun":_vm.cancelyan}}),_c('model',{attrs:{"ifShow":_vm.showhavepic,"confirmText":"确定","cancelText":"取消","title":"您当前还未设置人脸识别的照片，请前去设置"},on:{"confirmFun":_vm.confirmFungo,"cancelFun":_vm.goBack}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }