<template>
    <div style="width: 100%;height: 100%;">
        <nav-bar>
            <div slot="left" class="back">
                <img src="../../assets/img/back.png"  alt="" @click="goBack" />
            </div>
        </nav-bar>
        <div class="token_content">
            <img src="../../assets/img/logo2.png" alt="" class="content_logo"/>
            <div class="content_input display-flex-between">
                <input type="text" placeholder="请输入账号" v-model="idcard" />
            </div>
            <div class="content_input display-flex-between">
                <input :type="islook?'text':'password'" maxlength="20"  placeholder="请输入6-12位数（英文字母/数字）" v-model="password" />
                <div @click="islook=!islook">
                  <img :src="islook?require('../../assets/img/eye2.png'):require('../../assets/img/look.png')" alt="" class="content_input_eye"/>
                </div>
            </div>
            <div class="button_token" :class="isclick?'':'opacity'" @click="token">登录</div>
            <div class="tip display-flex-between">
                <router-link style="color: #A7A6B3;" to="/forget">忘记密码</router-link>
                <router-link style="color: #257CFF;" to="/phone">手机号登录</router-link>
            </div>
        </div>
        <div class="choose_organ" v-if="show_choose_organ">
          <div class="choose_organ_same" @click="show_choose_organ = false"></div>
          <div class="organ_content">
            <div class="organ_title">
              选择机构
            </div>
            <div class="organ_list" v-for="(item,index) in organ_list" :key="index">
              <div class="organ_info" @click="organtoken(item.company.id)">{{item.company.name}}</div>
            </div>
            <div class="organ_close" @click="show_choose_organ = false">
              <img src="../../assets/img/ic-close@2x.png" alt="" class="organ_close_img">
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import '@/assets/style/token.css'

export default {
  name: 'cardToken',
  data () {
    return {
      idcard: '',
      password: '',
      islook: false,
      organ_list: [],
      show_choose_organ: false
    }
  },
  computed: {
    isclick: {
      set: function () {},
      get: function () {
        return this.idcard !== '' && this.password !== ''
      }
    }
  },
  mounted () {
    // localStorage.removeItem('token')
    // localStorage.removeItem('userinfo')
    // localStorage.removeItem('indexCurrent')
    // localStorage.removeItem('postname')
    // localStorage.removeItem('pId')
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async token () {
      const that = this
      if (that.isclick) {
        const params = {
          account: that.idcard,
          password: that.password
        }
        console.log(params)
        const reslist = await that.api.cardorgantoken(params)
        if (reslist.code === 1) {
          if(reslist.data.userinfo) {
            localStorage.setItem('token', reslist.data.userinfo.token)
            const userinfo = JSON.stringify(reslist.data.userinfo)
            localStorage.setItem('postname', userinfo.pos_name)
            localStorage.setItem('pId', userinfo.pos_id)
            localStorage.setItem('userinfo', userinfo)
            localStorage.setItem('indexCurrent', 0)
            that.$router.push('/my')
          } else {
            that.organ_list = reslist.data;
            that.show_choose_organ = true;
          }
        } else {
          that.$toast(reslist.msg)
        }
      }
    },
    async organtoken (id) {
      const that = this
      const params = {
        account: that.idcard,
        password: that.password,
        mechanismId: id,
      }
      const reslist = await that.api.cardorgantoken(params)
      if (reslist.code === 1) {
        that.show_choose_organ = false;
        localStorage.setItem('token', reslist.data.userinfo.token)
        const userinfo = JSON.stringify(reslist.data.userinfo)
        localStorage.setItem('postname', userinfo.pos_name)
        localStorage.setItem('pId', userinfo.pos_id)
        localStorage.setItem('userinfo', userinfo)
        localStorage.setItem('indexCurrent', 0)
        that.$router.push('/my')
      } else {
        that.$toast(reslist.msg)
      }
    }
  }

}
</script>
<style lang="scss" scoped>

</style>
