<template>
    <div>
        <nav-bar class="navbar">
            <div slot="left" class="back">
                <div  @click="goBack">
                    <img src="../../assets/img/back.png" alt="" class="backimg"/>
                </div>
            </div>
            <div slot="center" class="navtitle">
                <div>在线课程</div>
            </div>
            <div slot="right" class="picker display-flex-around" @click="poslist" >
                <div>{{infoData?infoData:postname}}</div>
                <img src="../../assets/img/xia.png" alt="" :class="isShow?'rotate':''">
            </div>
        </nav-bar>
        <fil-ter :show="isShow" :posts="posts" @infoType="infoType"></fil-ter>
        <div class="video_list">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="scrollEnd">
            <div class="noresult" v-if="videoclass.length == 0">暂无在线课程</div>
            <div class="list display-flex-between" v-else>
                <div @click="todetail(item.id,item.is_finish)"  class="list_item" v-for="(item,index) in videoclass" :key="index">
                    <div class="list_item_time">{{item.class_hours}} 课时</div>
                    <div class="list_item_pic">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="cover"
                        :src="item.image"
                      />
                    </div>
                    <div class="list_item_title p-1">{{item.name}}</div>
                    <div class="list_item_user display-flex" v-if="!!item.teacher">
                        <img :src="item.teacher.avatar" alt="" class="list_item_userpic" >
                        <span>{{item.teacher.name}}</span>
                    </div>
                </div>
            </div>
          </van-list>
        </div>
    </div>
</template>
<script>
import '@/assets/style/list1.css'
import '@/assets/style/classlist.css'
import filTer from '@/components/filter.vue'
export default {
  components: {
    filTer
  },
  data () {
    return {
      scrollX: false,
      isShow: false,
      posts: [],
      videoclass: [],
      infoData: '',
      postname: '',
      pId: '',
      page: 1, // 视频课程页码
      pageNum: 20, // 视频课程每页条数
      ifmore: false, // 页码数据(第二页是否有数据)
      loading: false,
      finished: false
    }
  },
  created () {
    this.pId = this.$route.query.id
    this.videoCourse()
  },
  methods: {
    todetail(id,is_finish){
      this.$router.push({
        path:'/onClassDetail',
        query:{id:id,is_finish:is_finish}
      })
      localStorage.removeItem('yansuccess')
    },
    goBack () {
      this.$router.go(-1)
    },
    scrollEnd () {
      if (this.ifmore) {
        this.loading = true;
        this.page++
        this.videoCourse()
      }
    },

    // 视频课程列表
    async videoCourse () {
      const params = {
        pos_id: this.pId,
        page: this.page,
        page_num: this.pageNum
      }
      const reslist = await this.api.videoCourse(params)
      if (reslist.code === 1) {
        const res = reslist.data.list.data
        if (res.length === 0) {
          this.ifmore = false
          this.finished = true;
          this.videoclass = this.page === 1 ? res : this.videoclass.concat(res) // 合并数组
        } else {
          this.ifmore = true
          this.videoclass = this.page === 1 ? res : this.videoclass.concat(res)
        }
        this.loading = false;
        console.log(this.videoclass, '视频课程列表')
      } else {
        this.loading = false;
        this.$toast(reslist.msg)
      }
    },

    // 工种列表接口
    async poslist () {
      this.isShow = !this.isShow
      const reslist = await this.api.pos()
      if (reslist.code === 1) {
        this.posts = reslist.data.list
      } else {
        this.$toast(reslist.msg)
      }
    },

    infoType (infoType, itemId) {
      this.isShow = false
      this.infoData = infoType
      this.pId = itemId
      this.page = 1
      this.videoclass = []
      this.videoCourse()
    }
  },
  mounted () {
    if (localStorage.getItem('postname', this.postname)) {
      this.postname = localStorage.getItem('postname', this.postname)
      this.pId = localStorage.getItem('pId', this.pId)
    }
  }
}
</script>
<style scoped lang="scss">
    .back,.picker{
        width: 1.5rem;
    }
    .picker{
      justify-content: flex-end;
    }
    .list{
        width: 100%;
        height: auto;
    }
    .video_list {
      width: 100%;
      padding: 0 .32rem;
      box-sizing: border-box;
      margin-top: 1rem;
    }
</style>
