<template>
  <div class="">
    <nav-bar class="navbar">
      <div slot="left" class="back" @click="goBack">
        <img src="../../assets/img/back.png" alt="" />
      </div>
      <div slot="center" class="userinfotitle">班级任务</div>
      <div slot="right" class="result">
        <select v-model="type" @change="choosetype">
          <option value="0">全部</option>
          <option value="1">已完成</option>
          <option value="2">未完成</option>
        </select>
      </div>
    </nav-bar>
    <div class="tabs-ren">
      <tabs :tabs="tabs" @choose="choose" :active="current"></tabs>
    </div>
    <div class="line"></div>
    <swiper-list @slideChange="choose" :active="current" ifthird="true">
      <div
        slot="first"
        style="padding: 0.32rem"
      >
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="scrollEnd">
          <div>
            <div class="noresult" v-if="classes.length == 0">暂无课程</div>
            <div v-else>
              <div
                class="wrongs"
                v-for="(item, index) in classes"
                :key="index"
                @click="
                  toDetail(
                    item.id,
                    item.sort_id,
                    item.train_id,
                    item.train_class_id,
                    item.live_status,
                    item.playerUrl,
                    item.status
                  )
                "
              >
                <div class="wrongitem">
                  <div class="wrongitemvideo">
                    <van-image
                      width="100%"
                      height="100%"
                      fit="cover"
                      :src="item.image"
                    />
                  </div>
                  <div class="over">
                    {{ item.status == 0 ? "未完成" : "已完成" }}
                  </div>
                  <div class="wrongitemtitle p-1">
                    班级名称:{{ item.train_name }}
                  </div>
                  <div class="wrongitemtitle p-1">{{ item.name }}</div>
                  <div class="time display-flex-between">
                    <div>{{ item.class_hours }}课时</div>
                    <div>{{ item.create_time_text }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <div slot="second">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="scrollEnd">
          <div>
            <div class="noresult" v-if="practice.length == 0">暂无练习</div>
            <div v-else style="padding: 0.32rem">
              <div
                v-for="(item, index) in practice"
                @click="totest(item.id, item.is_complete, item.count)"
                :key="index"
                class="Specialexercises_item"
              >
                <div class="display-flex-between" style="height: auto">
                  <!-- 暂不开放 是否合格 -->
                  <!-- <img src="../../assets/img/wenben 1.png" v-if="item.is_complete == 0" alt="" class="Specialexercises_img" /> -->
                  <img src="../../assets/img/wenben 1.png" alt="" class="Specialexercises_img" />
                  <div class="Specialexercises_title">{{ item.name }}</div>
                  <!-- 暂不开放 是否合格 -->
                  <!-- <div class="Specialexercises_time" v-if="item.is_complete == 0"> -->
                  <div class="Specialexercises_time">
                    {{ item.create_time }}
                  </div>
                <!-- 暂不开放 是否合格 -->
                  <!-- <div v-else>
                    <img v-if="item.is_pass == 1" src="../../assets/img/good.png" alt="" class="Mockexamination_img" />
                    <img v-else src="../../assets/img/bad.png" alt="" class="Mockexamination_img" />
                  </div> -->
                </div>
                <!-- 暂不开放 是否合格 -->
                <!-- <div class="display-flex-between" v-if="item.is_complete == 0"> -->
                <div class="display-flex-between">
                  <div class="Specialexercises_number">
                    答对{{ item.correct_count }}/{{ item.count }}
                  </div>
                </div>
                <!-- 暂不开放 是否合格 -->
                <!-- <div v-else>
                  <div class="Mockexamination_time display-flex">
                    <div style="color: rgba(150, 151, 153, 1)">提交时间：</div>
                    <div style="font-weight: 600">{{ item.sub_time }}</div>
                  </div>
                  <div class="Mockexamination_time display-flex">
                    <div style="color: rgba(150, 151, 153, 1)">分数：</div>
                    <div style="font-weight: 600">{{ item.exam_score }}分</div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <div
        slot="third"
        style="padding: 0.32rem"
      >
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="scrollEnd">
          <div>
            <div class="noresult" v-if="kao.length == 0">暂无考试</div>
            <div v-else>
              <div
                v-for="(item, index) in kao"
                :key="index"
                @click="toexam(item.id, item.is_complete, item.count)"
                class="Specialexercises_item"
              >
                <div class="display-flex-between" style="height: auto">
                  <img
                    src="../../assets/img/wenben 1.png"
                    v-if="item.is_complete == 0"
                    alt=""
                    class="Specialexercises_img"
                  />
                  <div class="Specialexercises_title">{{ item.name }}</div>
                  <div
                    class="Specialexercises_time"
                    v-if="item.is_complete == 0"
                  >
                    {{ item.exam_start_time }}
                  </div>
                  <div v-if="item.is_complete == '1'">
                    <img
                      v-if="item.is_pass == 1"
                      src="../../assets/img/good.png"
                      alt=""
                      class="Mockexamination_img"
                    />
                    <!-- <img v-if="item.exam_score>=60&&item.exam_score<79" src="../../assets/img/hao.png" alt="" class="Mockexamination_img"> -->
                    <img
                      v-else
                      src="../../assets/img/bad.png"
                      alt=""
                      class="Mockexamination_img"
                    />
                  </div>
                </div>
                <div class="display-flex-between" v-if="item.is_complete == 0">
                  <div class="Specialexercises_number">
                    答对{{ item.correct_count }}/{{ item.count }}
                  </div>
                </div>
                <div v-else>
                  <div class="Mockexamination_time display-flex">
                    <div style="color: rgba(150, 151, 153, 1)">提交时间：</div>
                    <div style="font-weight: 600">{{ item.sub_time }}</div>
                  </div>
                  <div class="Mockexamination_time display-flex">
                    <div style="color: rgba(150, 151, 153, 1)">分数：</div>
                    <div style="font-weight: 600">{{ item.exam_score }}分</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </swiper-list>
    <div class="progress display-flex-between" v-if="current == 0">
      <progress :value="classprogressing" :max="classprogress"></progress>
      <div>已学课时：{{ classprogressing }}/{{ classprogress }}</div>
    </div>
    <model
      :scroll="false"
      :ifphoto="true"
      :ifShow="showyan"
      confirmText="确定"
      cancelText="取消"
      title="身份信息验证"
      content="设置人脸识别照片为本人学习"
      @yanpeoplepic="sureyan"
      @cancelFun="cancelyan"
    ></model>
    <model
      :ifShow="showhavepic"
      confirmText="确定"
      cancelText="取消"
      title="您当前还未设置人脸识别的照片，请前去设置"
      @confirmFun="confirmFungo"
      @cancelFun="goCancel"
    ></model>
  </div>
</template>
<script>
import "@/assets/style/list4.css";
import tabs from "@/components/tabs.vue";
import swiperList from "@/components/swiper.vue";
import model from "@/components/model";
import config from "@/assets/js/config.js";
export default {
  components: {
    tabs,
    swiperList,
    model
  },
  name: "classtext",
  data() {
    return {
      showhavepic: false,
      showyan: false,
      height: "",
      type: 0,
      scrollX: false,
      pulldown: false,
      tabs: ["课程", "模拟", "考试"],
      classes: [],
      practice: [],
      kao: [],
      pagenum: 10,
      page: 1,
      current: 0,
      classprogress: "",
      classprogressing: "",
      ifmore: false,
      playerurl: config.playerurl,
      toexamid: "",
      toexamcomplete: "",
      liveUrl: "", // 直播的地址
      playback: 0,
      curId: "",
      loading: false,
      finished: false,
      train_class_id: ''
    };
  },
  watch: {
    current() {
      this.page = 1;
      this.ifmore = false;
      if (this.current === 0) {
        this.getlistclass();
      } else if (this.current === 1) {
        this.getlistprac();
      } else if (this.current === 2) {
        this.getlistkao();
      }
    },
  },
  created() {
    this.train_class_id = this.$route.query.id;
    this.loading = true;
    this.getlistclass();
  },
  mounted() {
    const tabsheight =
      document.documentElement.querySelector(".tabs-ren").height;
    this.height = document.documentElement.clientHeight - 140;
  },
  methods: {
    confirmFungo() {
      this.$router.push("userinfo");
    },
    async checkplayer(info) {
      // 不是直播回放
      this.playback = 0;
      const playerUrlinfo = "".split.call(info, "?")[1].split("&");
      const params = {
        roomId: parseInt(playerUrlinfo[0].split("=")[1]),
        roomName: decodeURI(playerUrlinfo[1].split("=")[1]),
        userId: parseInt(playerUrlinfo[4].split("=")[1]),
        userName: decodeURI(playerUrlinfo[5].split("=")[1]),
        teacherUserId: parseInt(playerUrlinfo[2].split("=")[1]),
        teacherUserName: decodeURI(playerUrlinfo[3].split("=")[1]),
        signToken: playerUrlinfo[6].split("=")[1],
      };
      const reslist = await this.api.checkplayer(params);
      if (reslist.code == 1) {
        const infoarr = "".split.call(info, "?");
        // window.open(infoarr[0] + '?' + encodeURIComponent(infoarr[1]), '_self')
        // console.log(infoarr)
        // window.open(encodeURIComponent(info),'_blank')
        // location.href = encodeURIComponent(info)
        // -----分割线
        this.liveUrl = infoarr[0] + "?" + encodeURIComponent(infoarr[1]);

        const result = await this.api.userinfo();
        if (result.code === 1) {
          const info = result.data.info;
          if (Number(info.company_check_face) === 0) {
            this.showyan = false;
            this.showhavepic = false;
            window.open(
              infoarr[0] + "?" + encodeURIComponent(infoarr[1]),
              "_self"
            );
          } else if (
            Number(info.company_check_face) === 1 &&
            Number(info.is_check_face) === 0
          ) {
            this.showhavepic = true;
            this.showyan = false;
          } else if (
            Number(info.company_check_face) === 1 &&
            Number(info.is_check_face) === 1
          ) {
            this.showhavepic = false;
            this.showyan = true;
          }
        }
      } else {
        this.$toast(reslist.msg);
      }
    },
    // 1=视频课,2=直播课,3=线下培训,4=文本课
    toDetail(id, type, trainid, trainclassid, status, playerUrl, liveStatus) {
      if (type === "1") {
        localStorage.removeItem("yansuccess");
        this.$router.push({
          path: "onClassDetail",
          query: {
            id: id,
            train_id: trainid,
            train_class_id: trainclassid,
            t: +new Date(),
            ifclass: true,
            is_finish: liveStatus == 1 ? 1 : 0,
          },
        });
      } else if (type === "2") {
        if (status === "1") {
          // 正在直播
          this.checkplayer(playerUrl);
        } else if (status === "2") {
          console.log("直播完");

          if (liveStatus == 1) {
            console.log("直播回放 已完成");
            this.playback = 1;
            this.curId = id;
            // 1 人脸
            // this.checkFace(1)
            this.$router.push({
              // 直播完
              path: "card",
              query: {
                id: id,
              },
            });
          } else {
            //
            console.log("直播回放 未完成 进行校验");
            this.playback = 1;
            this.curId = id;
            // 1 人脸
            this.checkFace(1);
          }

          // 分割线 是直播回放
        } else if (status == "3") {
          this.$toast("还未开播");
        }
      } else if (type === "3") {
        this.$router.push({
          path: "offlinetrain",
          query: {
            id: id,
            train_class_id: trainclassid,
          },
        });
      } else if (type === "4") {
        this.$router.push({
          path: "textClassDetail",
          query: {
            id: id,
            train_class_id: trainclassid,
            ifclass: true,
          },
        });
      }
    },
    async checkFace(status) {
      const result = await this.api.userinfo();
      if (result.code === 1) {
        const info = result.data.info;
        if (Number(info.company_check_face) === 0) {
          this.showyan = false;
          this.showhavepic = false;
          if (status == 1) {
            this.$router.push({
              // 直播完
              path: "card",
              query: {
                id: this.curId,
              },
            });
          }
        } else if (
          Number(info.company_check_face) === 1 &&
          Number(info.is_check_face) === 0
        ) {
          this.showhavepic = true;
          this.showyan = false;
        } else if (
          Number(info.company_check_face) === 1 &&
          Number(info.is_check_face) === 1
        ) {
          this.showhavepic = false;
          this.showyan = true;
        }
      }
    },
    async totest(id, complete, count) {
      const practicetype = 2;
      if(count === 0) {
        this.$toast('该练习暂无题目')
      } else {
        this.$router.push({
          path: "/examDetail",
          query: {
            id: id,
            practice: "practice",
            practicetype: practicetype,
          },
        });
      }
    },
    async getinfo() {
      const result = await this.api.userinfo();
      if (result.code === 1) {
        const info = result.data.info;
        if (Number(info.company_check_face) === 0) {
          this.showyan = false;
          this.showhavepic = false;
          this.yansuccess();
        } else if (
          Number(info.company_check_face) === 1 &&
          Number(info.is_check_face) === 0
        ) {
          this.showhavepic = true;
          this.showyan = false;
        } else if (
          Number(info.company_check_face) === 1 &&
          Number(info.is_check_face) === 1
        ) {
          this.showhavepic = false;
          this.showyan = true;
        }
      }
    },
    sureyan(res) {
      console.log(res);
      if (res.code == 1) {
        this.$toast("验证成功", this.playback);
        // this.yansuccess()
        this.showyan = false;

        if (this.playback == 1 && this.curId) {
          console.log("直播结束看回放");
          this.$router.push({
            // 直播完
            path: "card",
            query: {
              id: this.curId,
            },
          });
        } else if (this.playback == 3) {
          this.yansuccess();
        } else {
          // 看直播
          console.log("看直播");
          window.open(this.liveUrl, "_self");
        }
      } else {
        this.$toast("验证失败");
        this.showyan = false;
      }
    },
    cancelyan() {
      this.showyan = false;
    },
    async toexam(id, complete, count) {
      console.log(id, complete, Number(complete) == 0);
      this.toexamid = id;
      this.toexamcomplete = complete;
      if(Number(complete) === 1) {
          this.$toast('该考试已完成')
      } else {
        if(count === 0) {
          this.$toast('该考试暂无题目')
        } else {
          if (Number(complete) == 0) {
            console.log("人脸");
            this.playback = 3;
            this.getinfo();
          } else {
            this.yansuccess();
          }
        }
      }
    },
    async yansuccess() {
      const params = {
        train_class_id: this.train_class_id,
        train_exam_id: this.toexamid,
      };
      const res = await this.api.kaopractice(params);
      if (res.code === 1) {
        let practicetype;
        if (this.current === 1) {
          practicetype = 2;
        } else {
          practicetype = 1;
        }
        console.log(res.data.over_second);
        if (res.data.over_second <= 0) {
          this.$toast("考试已超时");
        } else {
          const classrextdetail = {
            user_item_id: res.data.user_item_id,
            list: JSON.stringify(res.data.list),
            over_second: res.data.over_second,
          };
          localStorage.removeItem("timelo");
          localStorage.removeItem("alltest");
          localStorage.removeItem("testindex");
          localStorage.removeItem("classrextdetail");
          localStorage.setItem(
            "classrextdetail",
            JSON.stringify(classrextdetail)
          );
          this.$router.push({
            path: "/examDetail",
            query: {
              user_item_id: res.data.user_item_id,
            },
          });
        }
      } else {
        this.$toast(res.msg);
      }
    },
    choosetype() {
      this.type = parseInt(this.type);
      this.page = 1;
      this.ifmore = false;
      this.loading = true;
      if (this.current === 0) {
        this.getlistclass();
      } else if (this.current === 1) {
        this.getlistprac();
      } else {
        this.getlistkao();
      }
    },
    scrollEnd() {
      console.log(this.ifmore);
      if (this.ifmore) {
        this.loading = true;
        ++this.page;
        if (this.current === 0) {
          this.getlistclass();
        } else if (this.current === 1) {
          this.getlistprac();
        } else {
          this.getlistkao();
        }
      }
      // else {
      //   this.$toast({ message: "没有更多了", duration: 1000 });
      // }
    },
    async getlistclass() {
      const params = {
        train_class_id: this.train_class_id,
        type: this.type,
        page: this.page,
        page_num: this.pagenum,
      };
      const res = await this.api.renclass(params);
      if (res.code === 1) {
        const result = res.data.list;
        if (result.length === 0 || result === []) {
          this.ifmore = false;
          this.finished = true;
          this.classes = this.classes.concat(result);
        } else {
          this.ifmore = true;
          this.classes = this.page === 1 ? result : this.classes.concat(result);
        }
        if(!result.length && this.page == 1) {
          this.ifmore = false;
          this.finished = true;
          this.classes.splice(0)
        }
        this.classprogress = res.data.lesson_class_count;
        this.classprogressing = res.data.lesson_class_user;
        this.loading = false;
        // Scroll.refresh()
      }
    },
    async getlistprac() {
      const params = {
        train_class_id: this.train_class_id,
        type: this.type,
        page: this.page,
        page_num: this.pagenum,
      };
      const res = await this.api.pracclass(params);
      if (res.code === 1) {
        const result = res.data.list;
        if (result.length === 0 && this.page !== 1) {
          this.ifmore = false;
          this.finished = true;
          this.practice = this.practice.concat(result);
        } else {
          this.ifmore = true;
          this.practice =
            this.page === 1 ? result : this.practice.concat(result);
        }
        this.loading = false;
      }
    },
    async getlistkao() {
      const params = {
        train_class_id: this.train_class_id,
        type: this.type,
        page: this.page,
        page_num: this.pagenum,
      };
      const res = await this.api.kaoclass(params);
      if (res.code === 1) {
        const result = res.data.list;
        if (result.length === 0 && this.page !== 1) {
          this.ifmore = false;
          this.kao = this.kao.concat(result);
        } else {
          this.ifmore = true;
          this.kao = this.page === 1 ? result : this.kao.concat(result);
        }
        this.loading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    goCancel() {
      this.showhavepic = false;
      this.showyan = false;
    },
    choose(e) {
      this.current = e;
    },
  },
};
</script>
<style scoped lang="scss">
.result,
.back {
  width: 1.3rem;
  font-size: 0.48rem;
}
.tabs-ren {
  height: 0.83rem;
  background-color: #fff;
  margin-top: 0.88rem;
}
select {
  height: auto;
}
.wrongs {
  width: 100%;
  height: auto;
  text-align: center;
}
.wrongitem {
  width: 100%;
  height: auto;
  border-radius: 0.08rem;
  opacity: 1;
  background: rgba(239, 242, 246, 1);
  padding: 0.24rem;
  box-sizing: border-box;
  margin: 0.24rem auto;
  position: relative;
}
.time {
  padding: 0.08rem 0 !important;
  margin: 0;
}
.wrongitemvideo {
  height: 3.52rem;
  width: 100%;
  /* border: 0.01rem solid red; */
}
.over {
  position: absolute;
  top: 0.24rem;
  left: 0.24rem;
  width: 1.04rem;
  height: 0.4rem;
  color: rgba(255, 255, 255, 1);
  font-size: 0.24rem;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: center;
  line-height: 0.4rem;
  border-radius: 0.02rem;
  opacity: 1;
  background: linear-gradient(
    180.25deg,
    rgba(37, 202.68, 255, 1) 0%,
    rgba(67.52, 37, 255, 1) 100%
  );
}
.wrongitemtitle {
  color: rgba(50, 50, 51, 1);
  font-size: 0.28rem;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.36rem;
  margin-top: 0.07rem;
}
.progress {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  background-color: #fff;
  padding: 0.32rem;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 1);
  font-size: 0.28rem;
  border-top: 0.01rem solid #eee;
}
.progress progress {
  flex: 1;
  height: 0.6rem;
  margin-right: 0.32rem;
}
.grade {
  color: rgba(37.19, 124.31, 255, 1);
  font-size: 0.28rem;
  font-weight: 600;
}
.Specialexercise {
  padding: 0.32rem;
  box-sizing: border-box;
}
</style>
