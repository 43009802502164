<template>
    <div>
        <nav-bar class="detailnav">
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="userinfotitle">
                线下培训
            </div>
            <div slot="right" class="result"></div>
        </nav-bar>
        <div class="display-flex-between tip" v-if="tipshow">
          <div>课程还有{{end_day}}天就结束了，请尽快学习</div>
          <img src="../../assets/img/close_fill.png" @click="tipshow=false" alt="">
        </div>
        <div class="swiper-container" style="margin-top: 0.88rem;">
            <div class="swiper-wrapper ">
              <div class="swiper-slide swiper-slide1">
                <img :src="images" class="swiper_img"/>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination1"></div>
        </div>

        <tabs :tabs="tabs" :active="current" @choose="choose" class="tabs"></tabs>
        <swiper-list @slideChange="choose" :active="current">
            <div slot="first" class="classlist" :style="{height:+height+'px'}">
              <Scroll
                style="height: 100%;"
                ref="scroll"
                :listenScroll="true"
                :probeType="3"
                :scrollX="scrollX"
                :pullup="true"
                >
                <div>
              <!-- <img src="../../assets/img/classbg.png" alt="" class="classbg"/> -->
              <!-- <div class="class_tip">
                  <div class="train display-flex">
                      <img src="../../assets/img/tou.png" alt="">
                      <div class="train_title">培训对象</div>
                  </div>
                  <div class="train_tip">适用于:{{pos_names}}等</div>
              </div> -->
              <div>
                  <div class="class_detail_title">{{title}}</div>
                  <div class="class_detail_content" v-html="content"></div>
              </div>
              </div>
              </Scroll>
            </div>
            <div slot="second"   :style="{height:+height+'px'}">
              <Scroll
                style="height: 100%;"
                ref="scroll"
                :listenScroll="true"
                :probeType="3"
                :scrollX="scrollX"
                :pullup="true"
                >
                <div>
                  <div class="noresult" v-if="classlist.length==0">暂无课程</div>
                <div v-else>
                  <div class="classlist" v-for="(item,index) in classlist" :key="index">
                  <div @click="showlistClick(item,index)">
                      <img src="../../assets/img/listbg.png" alt="" class="classlist_bg">
                      <div class="classlist_biaoti display-flex">
                          <img src="../../assets/img/video.png" alt="" class="classlist_img">
                          <div class="classlist_title">{{item.name}}</div>
                      </div>
                  </div>
                  <div v-if="showlist === index">
                      <div v-for="(itm,ind) in item.lessonofflineclass" :key="ind" class="classlist_one" @click="classSign(item,itm)">
                          <div class="display-flex-between" style="align-items: flex-start;">
                              <div style="color: #C8C9CC;">1.1</div>
                              <div class="display-flex-between" style="flex: 1;">
                                  <div class="classlist_one_title">{{itm.name}}</div>
                                  <div style="color: #969799;">{{itm.start_time}}</div>
                              </div>
                          </div>
                          <div class="classlist_one_status">{{itm.is_sign==1?'已签到':'未签到'}}</div>
                      </div>
                  </div>
                  </div>
                </div>

              </div>
              </Scroll>
            </div>
        </swiper-list>
        <model
      :ifShow="showmodel"
      confirmText="确定"
      cancelText="取消"
      title="当前未在开班时间内，学习将不会记录课时 ，是否继续学习？ "
      @confirmFun="showmodel=false"
      @cancelFun="goBack"
      ></model>
    </div>
</template>
<script>
import model from '@/components/model'

import tabs from '@/components/tabs.vue'
import '@/assets/style/list5.css'
import Scroll from '@/components/scroll.vue'
import swiperList from '@/components/swiper.vue'
export default {
  name: 'offlinetrain',
  components: {
    tabs,
    swiperList,
    Scroll,
    model
  },
  data () {
    return {
      scrollX: false,
      tabs: ['课程详情', '课程列表'],
      current: 1,
      classlist: [],
      showlist: false,
      title: '', // 详情页标题
      images: '',
      page: 1,
      pageNum: 20,
      train_id: '', // 培训计划id
      class_id: '', // 线下培训班级id
      id: '', // 线下培训id
      lesson_id: '', // 课节id
      tipshow: false,
      pos_names: '', // 培训对象
      end_day: '', // 剩余天数
      content: '', // 详情内容
      height: '',
      showmodel: false

    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    choose (e) {
      this.current = e
    },
    // 点击小课节签到
    classSign (item, itm) {
      if (itm.is_sign === 1) {
        this.$toast('已打卡签到，快去线下学习吧')
      } else {
        this.$router.push({ path: '/sign', query: { train_id: this.train_id, class_id: this.class_id, isSign: itm.is_sign, lesson_id: itm.id, id: item.id } })
      }
    },

    // 线下培训列表接口
    async train () {
      const params = {
        id: this.id,
        train_class_id: this.train_class_id
      }
      const reslist = await this.api.train(params)
      if (reslist.code === 1) {
        if (reslist.data.end_day <= 3) {
          this.tipshow = true
        }
        this.classlist = reslist.data.list
        this.images = reslist.data.image
        this.title = reslist.data.class_name
        this.train_id = reslist.data.train_class_id
        this.class_id = reslist.data.train_class.class_id
        this.pos_names = reslist.data.pos_names // 培训对象
        this.end_day = reslist.data.end_day <= 0 ? 0 : reslist.data.end_day // 剩余天数
        this.content = reslist.data.content // 详情内容
        if (Number(reslist.data.is_settle) === 0) {
          this.showmodel = true
        }
      } else {
        this.$toast(reslist.msg)
      }
    },

    // 控制大课节是否展开
    showlistClick (itm, ind) {
      if (this.showlist === ind) { // 当 showlist 等于点击课节的下标
        this.showlist = false
      } else { // 不等于时, 赋值当前点击课节的下标, 让它成立
        this.showlist = ind
      }
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.train_class_id = this.$route.query.train_class_id
    const time = new Date()
    const year = time.getFullYear()
    const month = time.getMonth() + 1
    const day = time.getDay()
    const newtime = time.getTime()
    const threeime = 24 * 60 * 60 * 1000 * 3
    console.log(year, month, day, time, newtime, threeime)
    this.train()
    this.height = document.documentElement.clientHeight - 280
  }
}
</script>
<style scoped lang="scss">
  .detailnav{
        position: fixed;
        top: 0;
        background: #fff ;
        z-index: 8;
    }
    .tabs{
        width: 100%;
        height: 0.88rem;
        padding: 0;
        box-shadow: 0px 5px 12px 0px rgba(113, 121, 129, 0.2);
    }
    .tip{
      width: 100%;
      height: 0.62rem;
      background: rgba(0, 0, 0, 0.2);
      font-size: 0.28rem;
      position: fixed;
      top: 0.88rem;
      left: 0;
      z-index: 8;
      color: #fff;
      padding: 0 0.32rem;
      box-sizing: border-box;

    }
    .tip img{
        width: 0.32rem;
        height:0.32rem;
      }
    .classlist{
        width: 100%;
        height: auto;
        padding: 0.32rem;
        box-sizing: border-box;
    }

    .classbg{
        width: 100%;
        height: 100%;
    }
    .class_tip{
        margin-top: -1.64rem;
        padding: 0.16rem;
        box-sizing: border-box;
        /* border: 0.01rem solid red; */
        height: 1.44rem;
    }
    .train img{
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.1rem;
    }
    .train_title{
        color: rgba(15.85,190.19,137.89,1);
        font-size: 0.28rem;
        font-weight: 500;
    }
    .train_tip{
        color: rgba(75.49,208.25,168.57,1);
        font-size: 0.24rem;
        font-weight: 400;
        margin: 0.16rem 0 0 0.5rem;
    }
    .class_detail_title{
        color: rgba(50,50,51,1);
        font-size: 0.34rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.52rem;
    }
    .class_detail_content{
        color: rgba(50,50,51,1);
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.48rem;
        margin-top: 0.32rem;
    }
    ::v-deep img{
        max-width:100%;
    }
</style>
