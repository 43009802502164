<template>
    <div>
        <nav-bar>
            <div slot="left" class="back" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
        </nav-bar>
        <div class="edit">
            <div class="edittitle">修改手机号</div>
            <div class="user_line display-flex-between">
                <input type="text" placeholder="请输入要修改的手机号" v-model="phone"/>
            </div>
            <div class="user_line display-flex-between" >
                <input type="text" placeholder="输入验证码" v-model="code"/>
                <div class="code_line">|</div>
                <div style="color: #257CFF;" @click="getCode">{{codeValue}}</div>
            </div>

        </div>
        <div class="over" @click="over">完成</div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      codeValue: '获取验证码',
      code: '',
      number: 300,
      phone: ''
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async getcodenum () {
      const that = this
      const params = {
        mobile: that.phone,
        event: 'changemobile'
      }
      const result = await that.api.phonetoken(params)
      if (result.code !== 1) {
        that.$toast(result.msg)
      } else {
        that.timer = setInterval(() => {
          that.number--
          that.codeValue = that.number + 's后重新获取'
          if (that.number === 0) {
            clearInterval(that.timer)
            that.codeValue = '重新获取'
          } else {
          }
        }, 1000)
      }
    },
    getCode () {
      if (this.codeValue === '获取验证码' || this.codeValue === '重新获取') {
        this.getcodenum()
      }
    },
    async over () {
      const that = this
      if (that.code === '') {
        that.$toast('请填写完整')
      } else {
        const params = {
          mobile: that.phone,
          captcha: that.code
        }
        const result = await that.api.edituserphone(params)
        console.log(result)
        if (result.code === 1) {
          that.$router.go(-1)
          that.$toast('修改成功')
        } else {
          that.$toast(result.msg)
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
    .edit input{
        text-align: left;
    }
    .user_line{
        border-top: 0.01rem solid #eee;
    }
    .user_line:nth-child(5){
        border-top: none;
    }
    .user_line:nth-child(2){
        border-top: none;
    }
    .edittitle{
        color: rgba(50,50,51,1);
        font-size: 0.36rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 0.48rem;
        text-align: left;
        padding: 0 0.32rem;
        box-sizing: border-box;
        margin: 0.68rem 0;
    }
    .code_line{
        color: #257CFF;
        margin: 0 0.32rem;
    }
    .over{
        width: 6.86rem;
        height: 0.88rem;
        opacity: 1;
        background: rgba(37.19,124.31,255,1);
        color: rgba(255,255,255,1);
        font-size: 0.32rem;
        font-weight: 500;
        text-align: center;
        line-height: 0.88rem;
        border-radius: 0.1rem;
        margin: 0.68rem auto;
    }
</style>
