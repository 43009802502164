<template>
  <!-- 测试-->
  <div class="lineclass">
    <nav-bar class="lineclassnavbar">
      <div slot="left" class="lineclassback" @click="goBack">
          <img src="../../assets/img/back3.png" alt=""/>
      </div>
      <div slot="center" class="lineclasscenter">
          {{detail.name}}
      </div>
      <div slot="right"></div>
    </nav-bar>
    <div id="palyback">
      <div id="id_test_video" style="width:100%; height:auto;"></div>
    </div>
  </div>
</template>

<script>
import '@/assets/style/lineClassDetail.css'
export default {
  data () {
    return {
      id: '',
      detail: ''
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async getDetail () {
      const params = {
        id: this.id
      }
      const reslist = await this.api.lineclassdetail(params)
      if (reslist.code == 1) {
        console.log(reslist, '11112222')
        this.detail = reslist.data.detail
        this.playVideo(this.detail.playback_url)
      }
    },
    playVideo (url) {
      var player = new TcPlayer('id_test_video', {
        m3u8: url, // 请替换成实际可用的播放地址
        autoplay: true, // iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        width: '480', // 视频的显示宽度，请尽量使用视频分辨率宽度
        height: '320'// 视频的显示高度，请尽量使用视频分辨率高度
      })
    }
  }
}
</script>

<style scoped>
  #palyback {
    width: 100%;
    height: 100%;
    /* background-color: #1c302e;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 59.375rem;
    position: relative;
    border: 1px solid red; */
  }
  .else {
    width: 100%;
    position: absolute;
    top: 3%;
    right: 2%;
    z-index: 100;
  }

  .box-title {
    width: 50px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: pink;
    position: absolute;
    bottom: 3%;
    right: 2%;
  }
</style>
