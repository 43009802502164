<template>
  <div class="">
    <nav-bar class="recordnavbar">
      <div slot="left" class="recordback" @click="goBack">
        <img src="../../assets/img/back.png" alt="" />
      </div>
      <div slot="center" class="userinfotitle">答题记录</div>
      <div slot="right" class="recordresult"></div>
    </nav-bar>
    <div class="recordtabs">
      <tabs :tabs="tabs" @choose="choose" :active="active"></tabs>
    </div>
    <div class="line"></div>
    <div>
      <swiper-list
        @slideChange="choose"
        :active="active"
        ifthird="true"
        ifforth="true"
      >
        <div slot="first" class="record_list" style="margin-top: 0.2rem">
          <van-list
            @load="scrollEnd">
            <div class="noresult" v-if="Specialexercises.length == 0">
              暂无答题记录
            </div>
            <div class="Specialexercise" v-else>
              <div
                @click="todetail(item)"
                v-for="(item, index) in Specialexercises"
                :key="index"
                class="Specialexercises_item"
              >
                <div class="display-flex-between" style="width: 100%">
                  <img
                    src="../../assets/img/wenben 1.png"
                    alt=""
                    class="Specialexercises_img"
                  />
                  <div class="Specialexercises_title">
                    {{ item.title }}（{{ item.pos_name }}）
                  </div>
                  <div class="Specialexercises_time">
                    {{ item.create_time_text }}
                  </div>
                </div>
                <div class="Specialexercises_number">
                  答对：{{ item.correct }}/{{ item.count }}
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div slot="second" class="record_list" style="margin-top: 0.2rem">
          <van-list
            @load="scrollEnd">
            <div class="noresult" v-if="Specialexercises.length == 0">
              暂无答题记录
            </div>
            <div class="Specialexercise" v-else>
              <div
                @click="todetail(item)"
                v-for="(item, index) in Specialexercises"
                :key="index"
                class="Specialexercises_item"
              >
                <div class="display-flex-between" style="width: 100%">
                  <img
                    src="../../assets/img/wenben 1.png"
                    alt=""
                    class="Specialexercises_img"
                  />
                  <div class="Specialexercises_title" v-if="!!item.pos">
                    {{ item.title }}（{{ item.pos_name }}）
                  </div>
                  <div class="Specialexercises_time">
                    {{ item.create_time_text }}
                  </div>
                </div>
                <div class="Specialexercises_number">
                  答对：{{ item.correct }}/{{ item.count }}
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div slot="third" class="record_list" style="margin-top: 0.2rem">
          <van-list
            @load="scrollEnd">
            <div class="noresult" v-if="Specialexercises.length == 0">
              暂无答题记录
            </div>
            <div class="Specialexercise" v-else>
              <div
                @click="todetail(item)"
                v-for="(item, index) in Specialexercises"
                :key="index"
                class="Specialexercises_item"
              >
                <div class="display-flex-between" style="width: 100%">
                  <img
                    src="../../assets/img/wenben 1.png"
                    alt=""
                    class="Specialexercises_img"
                  />
                  <div class="Specialexercises_title" v-if="!!item.pos">
                    {{ item.title }}（{{ item.pos_name }}）
                  </div>
                  <div class="Specialexercises_time">
                    {{ item.create_time_text }}
                  </div>
                </div>
                <div class="Specialexercises_number">
                  答对：{{ item.correct }}/{{ item.count }}
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div slot="forth" class="record_list" style="margin-top: 0.2rem">
          <van-list
            @load="scrollEnd">
            <div class="noresult" v-if="Specialexercises.length == 0">
              暂无答题记录
            </div>
            <div class="Specialexercise" v-else>
              <div
                @click="todetail(item)"
                v-for="(item, index) in Specialexercises"
                :key="index"
                class="Specialexercises_item"
              >
                <div class="display-flex-between" style="width: 100%">
                  <img
                    src="../../assets/img/wenben 1.png"
                    alt=""
                    class="Specialexercises_img"
                  />
                  <div class="Specialexercises_title" v-if="!!item.pos">
                    {{ item.title }}（{{ item.pos_name }}）
                  </div>
                  <div class="Specialexercises_time">
                    {{ item.create_time_text }}
                  </div>
                </div>
                <div class="Specialexercises_number">
                  答对：{{ item.correct }}/{{ item.count }}
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </swiper-list>
    </div>
  </div>
</template>
<script>
import swiperList from "@/components/swiper.vue";
import "@/assets/style/list4.css";
import tabs from "@/components/tabs.vue";
export default {
  components: {
    tabs,
    swiperList
  },
  data() {
    return {
      scrollX: false,
      tabs: ["专项练习", "培训计划考试", "培训计划练习"],
      Specialexercises: [],
      active: 0,
      ifmore: false,
      page: 1,
      pagenum: 10,
    };
  },
  mounted() {
    this.getlist();
  },
  watch: {
    active() {
      this.page = 1;
      this.Specialexercises = [];
      this.getlist();
    },
  },
  methods: {
    scrollEnd(e) {
      console.log(e);
      if (this.ifmore) {
        ++this.page;
        this.getlist();
      }
      // else {
      //   this.$toast({ message: "没有更多了", duration: 1000 });
      // }
    },
    goBack() {
      this.$router.push("my");
    },
    choose(e) {
      this.active = e;
    },
    async getlist() {
      const params = {
        type: Number(this.active) + 3,
        page: this.page,
        page_num: this.pagenum,
      };
      const res = await this.api.getrecord(params);
      if (res.code === 1) {
        const result = res.data.list.data;
        result.forEach(element => {
          let pos = element.pos;
          let pos_name = [];
          pos.forEach(item => {
            pos_name.push(item.name)
          });
          element.pos_name = pos_name.join('、');
        });
        if (result.length === 0) {
          this.ifmore = false;
          this.Specialexercises = this.Specialexercises.concat(result);
        } else {
          this.ifmore = true;
          this.Specialexercises =
            this.page === 1 ? result : this.Specialexercises.concat(result);
        }
      } else {
        this.$toast(res.msg);
      }
    },
    async todetail(item) {
      // this.$toast('优化中')
      localStorage.removeItem("testindex");
      localStorage.removeItem("alltest");
      this.$router.push({ path: "/recorddetail", query: { id: item.id } });
    },
  },
};
</script>
<style lang="scss">
.body {
  height: 100vh;
  overflow: hidden;
  background: #ffffff;
}
.recordback,
.recordresult {
  width: 1rem;
}
.recordback img {
  width: 0.48rem;
  height: 0.48rem;
}
.recordnavbar {
  background-color: #ffffff;
}
.Specialexercises_item {
  width: 100%;
}
.scroll .Specialexercises_item:last-child {
    margin-bottom: 4rem;
}
.Mockexamination_item {
  width: 6.86rem;
  height: 2.72rem;
  border-radius: 0.16rem;
  opacity: 1;
  background: rgba(239, 242, 246, 1);
  padding: 0 0.32rem;
  box-sizing: border-box;
  margin-bottom: 0.25rem;
  position: relative;
}
.Mockexamination_img {
  position: absolute;
  width: 1.28rem;
  height: 1.28rem;
  top: 0.24rem;
  right: 0.24rem;
}
.Mockexamination_title {
  color: rgba(50, 50, 51, 1);
  font-size: 0.28rem;
  font-weight: 600;
  padding: 0.32rem 0;
  box-sizing: border-box;
  border-bottom: 0.01rem solid rgb(212, 212, 212);
}
.Mockexamination_time {
  color: rgba(50, 50, 51, 1);
  font-size: 0.28rem;
  font-weight: 500;
  margin-top: 0.3rem;
}
.record_list {
  width: 100%;
  padding: 0 .32rem;
  box-sizing: border-box;
}
</style>
