<template>
    <div>
        <nav-bar class="daylistnavbar">
            <div slot="left" class="picker" @click="goBack">
                <img src="../../assets/img/back.png" alt="">
            </div>
            <div slot="center" class="center">
                每日一练
            </div>
            <div slot="right" class="picker display-flex-around" >
                <div>{{postname}}</div>
                <!-- <img src="../../assets/img/xia.png" alt="" :class="isShow?'rotate':''">@click="pos" -->
            </div>
        </nav-bar>
        <fil-ter :show="isShow" @infoType="infoType"></fil-ter>
        <div  class="Specialexercise">
            <div class="noresult" v-if="Specialexercises.length==0">暂无每日一练</div>
            <div v-else>
                <div v-for="(item,index) in Specialexercises" :key="index" class="Specialexercises_item">
                    <div @click="todetail(item.id,item.is_complete)">
                        <div class="display-flex-between">
                            <img src="../../assets/img/wenben 1.png" alt="" class="Specialexercises_img">
                            <div class="Specialexercises_title">{{item.title}}</div>
                            <div :class="Number(item.is_complete)==0?'daystatus':'daystatus1'">{{Number(item.is_complete)==0?'未完成':'已完成'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '@/assets/style/list4.css'
import filTer from '@/components/filter'
export default {
  components: {
    filTer
  },
  data () {
    return {
      Specialexercises: [],
      pagenum: 10,
      postname: '',
      pId: '',
      page: 1,
      isShow: false // 工种弹窗的显示
    }
  },
  mounted () {
    if (localStorage.getItem('postname', this.postname)) {
      this.postname = localStorage.getItem('postname', this.postname)
      this.pId = localStorage.getItem('pId', this.pId)
    }
    this.getlistkao()
  },
  methods: {
    todetail (id, com) {
      if (Number(com) === 1) {
        this.$toast('该试题已完成')
      } else {
        localStorage.removeItem('alltest')
        localStorage.removeItem('testindex')
        localStorage.removeItem('answerId')
        localStorage.removeItem('timelo')
        this.$router.push({
          path: '/examDetail', query: { specialId: id, id: this.pId, type: 1 }
        })
      }
    },
    async getlistkao () {
      const params = {
        pos_id: this.pId,
        page: this.page,
        page_num: this.pagenum
      }
      const res = await this.api.daylist(params)
      if (res.code === 1) {
        const result = res.data.list.data

        this.Specialexercises = result
        console.log(this.Specialexercises)
      }
    },
    // 选择工种
    pos () {
      this.isShow = !this.isShow
    },
    infoType (infoType, itemId) {
      console.log(infoType, itemId)
      this.isShow = false
      this.postname = infoType
      this.pId = itemId
      localStorage.setItem('postname', this.postname)
      localStorage.setItem('pId', this.pId)
      this.page = 1
      this.getlistkao()
    },
    choose (e) {
      this.current = e
      this.page = 1
      this.getlistkao()
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="scss">
  .daylistnavbar{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    background: #fff;
  }
    .daylistnavbar .picker{
        width:1.6rem;
    }
    .daylistnavbar .center{
        flex: 1;
        text-align: center;
    }
    .daylistnavbar .picker{
        justify-content: flex-end;
    }
    .center{
        font-size: 0.34rem;
        font-weight: 700;
        text-align: center;
    }
    .exercisetabs{
        width: 100%;
        height:0.88rem;
        margin-top: 0.8rem;
        padding: 0;
    }
    .grade{
        color: rgba(37.19,124.31,255,1);
        font-size: 0.28rem;
        font-weight: 600;
    }
    .Specialexercise{
      margin-top: 0.88rem;
      padding:0.32rem;
      box-sizing: border-box;
    }
    .daystatus{
        color: rgba(37,124,255,1);
        font-size: 0.32rem;
        font-weight: 600;
    }
    .daystatus1{
        color: #FA700C;
        font-size: 0.32rem;
        font-weight: 600;
    }
</style>
