<template>
    <div>
        <nav-bar class="navbar">
            <div slot="left" @click="goBack" class="back">
                <img src="../../assets/img/back.png" alt="" />
            </div>
            <div slot="right" class="back" @click="faved">
                <img v-if="detail.is_favorite==1" src="../../assets/img/faved1.png" alt="" />
                <img v-else src="../../assets/img/iconly-线性轮廓化Light-outline-Star@2x.png" alt="" />
            </div>
        </nav-bar>
        <div class="classDetail">
            <div class="classDetail_title">{{ detail.name }}</div>
            <video v-if="detail.video" controls
            id="videodetail"
            :src="detail.video"
            autoplay  ref="video"
            ></video>
            <div class="classDetail_content" v-html="detail.content"></div>
        </div>
        <div class="fileone" v-for="(item,index) in detail.new_file" :key="index">
            <div class="display-flex">
                <img v-if="item.type=='xlsx'||item.type=='xls'" src="../../assets/img/xlsx.png" alt="">
                <img v-if="item.type=='pdf'" src="../../assets/img/Frame@2x (13).png" alt="">
                <img v-if="item.type=='docx'||item.type=='doc'" src="../../assets/img/word.png" alt="">
                <img v-if="item.type=='ppt'||item.type=='pptx'" src="../../assets/img/ppt.png" alt="">
                <div>{{item.name}}</div>
            </div>
            <!-- <div @click="tourl1(item.url)">预览office</div> -->
            <div class="looker" @click="tourl(item.url,item.type)">预览</div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      ifdo: true,
      string: '',
      name: '',
      images: '',
      id: '',
      detail: ''

    }
  },
  created () {
    this.id = this.$route.query.id
    this.details()
  },

  methods: {
    tourl (href, type) {
      if (type === 'pdf') {
        this.$router.push({
          path: '/openPdf',
          query: {
            src: href
          }
        })
        // window.open(href)
      } else {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(href))
      }
    },
    tourl1 (url) {
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url))
    },
    goBack () {
      this.$router.go(-1)
    },
    async faved () {
      const params = {
        type: 1,
        favorite_id: this.id
      }
      const res = await this.api.deletelist(params)
      if (res.code === 1) {
        this.$toast(res.msg)
        this.details()
      }
    },
    // 文本课程详情
    async details () {
      const params = {
        id: this.id
      }
      const reslist = await this.api.datadetail(params)
      if (reslist.code === 1) {
        const result = reslist.data.detail
        result.new_file.forEach(item => {
          const index = item.url.lastIndexOf('.')
          const ext = item.url.substr(index + 1)
          item.type = ext
        })
        this.detail = result

        this.$refs.video.play()
      } else {
        this.$toast(reslist.msg)
      }
    }

  },
  mounted () {
    setTimeout(() => {
      this.ifdo = false
    }, this.time)
  }
}
</script>
<style lang="scss" scoped>
  .navbar{
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 9;
  }
  .classDetail video{
      width: 100%;
      height: 4.2rem;
  }
    .back{
        width: 0.5rem;
        font-size: 0.48rem;
    }
    .back img{
        width: 0.48rem;
        height: 0.48rem;
    }
    .classDetail{
        width: 100%;
        height: auto;
        padding: 0 0.32rem;
        box-sizing: border-box;
        margin:1rem 0 0.48rem;
    }
    .classDetail_title{
        color: rgba(50,50,51,1);
        font-size: 0.34rem;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.52rem;
        letter-spacing: 0.01rem;
        margin-bottom: 0.32rem;
    }
    .classDetail_content{
        color: rgba(50,50,51,1);
        font-size: 0.28rem;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 0.48rem;
    }
    ::v-deep img {
      width: 100% !important;
    }
    .classDetail_button{
        position: fixed;
        bottom: 0.32rem;
        left: 50%;
        transform: translate(-50%,0);
    }
    .classDetail_button{
        width: 6.86rem;
        height: 0.88rem;
        opacity: 1;
        background: rgba(37.19,124.31,255,1);
        line-height: 0.88rem;
        color: #fff;
        font-size:0.32rem;
        text-align: center;
    }
    .opacity{
        opacity: 0.5;
    }
    .fileone{
        width: 90%;
        margin: 0 auto 0.32rem;
        padding: 0.32rem;
        box-sizing: border-box;
        height: 1.8rem;
        border-radius: 0.08rem;
        background: rgba(255,255,255,1);
        border: 0.01rem solid #eee;
        color: rgba(50,50,51,1);
        font-size: 0.28rem;
        font-weight: 400;
    }
    .fileone img{
        width: 0.48rem !important;
        height: 0.48rem;
        margin-right: 0.16rem;
    }
    .looker{
        width: 1.18rem;
        height: 0.56rem;
        border-radius: 0.08rem;
        background: rgb(205, 223, 248);
        color:#257CFF;
        text-align: center;
        line-height: 0.56rem;
        font-size: 0.24rem;
        font-weight: 500;
        float: right;
    }
</style>
